import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { MOBILE, MOBILE_PADDING_HORIZ } from '../../lib/constants';
import { TextAnswer } from './TextAnswer';
import { ViewportContext } from '../../viewportContext';

const styles = StyleSheet.create({
  mobile: {
    paddingLeft: MOBILE_PADDING_HORIZ,
    paddingRight: MOBILE_PADDING_HORIZ,
  },
});

const QuestionInputWrapper = ({ children, answer, isCurrent }) => {
  const viewport = useContext(ViewportContext);

  return (
    <div className={css(viewport === MOBILE && styles.mobile)}>
      {viewport === MOBILE || isCurrent ? (
        <div>{children}</div>
      ) : (
        <TextAnswer>{answer}</TextAnswer>
      )}
    </div>
  );
};

QuestionInputWrapper.propTypes = {
  children: PropTypes.node,
  answer: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

export { QuestionInputWrapper };
