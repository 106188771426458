import { combineReducers } from "redux";
import { appStateReducer } from "./appReducer";
import { pollStateReducer } from "./poll/reducer";
import { accountEditStateReducer } from "./accountEdit/reducer";
import { mobilizerStateReducer } from "./mobilizer/reducer";
import { modalStateReducer } from "./modal/reducer";

export const rootReducer = combineReducers({
  app: appStateReducer,
  accountEdit: accountEditStateReducer,
  modal: modalStateReducer,
  poll: pollStateReducer,
  viewport: mobilizerStateReducer,
});
