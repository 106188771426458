import _ from 'lodash';
import { camelCaseKeys } from './camelCaseKeys';

export const initializeQuestions = (
  questions,
  shuffleFrom = 0,
  shuffleTo = 0
) => {
  questions = prepareQuestionProps(questions);
  questions = shuffleQuestions(questions, shuffleFrom, shuffleTo);

  return questions;
};

const prepareQuestionProps = (questions) => {
  return questions.map((q, index) => {
    q = camelCaseQuestionData(q);
    q = handleDynamicOptionsRenderTypes(q);
    q = renameBackendOnlyRenderTypes(q);
    q.index = index;
    q.isAnswered = false;
    q.isCurrent = index === 0;

    return q;
  });
};

const camelCaseQuestionData = (q) => {
  q = camelCaseKeys(q);
  if (q.choices) q.choices = q.choices.map((c) => camelCaseKeys(c));

  return q;
};

const handleDynamicOptionsRenderTypes = (q) => {
  switch (q.renderType) {
    case 'congressional_district':
    case 'state_level_district':
      q.choices = q.choices.map((c, idx) => {
        return { ...c, id: idx, isDynamic: true };
      });
      return q;
    default:
      if (q.choices) {
        q.choices = q.choices.map((c) => {
          return { ...c, isDynamic: false };
        });
      }

      return q;
  }
};

const renameBackendOnlyRenderTypes = (q) => {
  switch (q.renderType) {
    case 'congressional_district':
    case 'state_level_district':
    case 'options_with_other':
      return { ...q, renderType: 'options' };
    case 'large_text':
      return { ...q, renderType: 'text', isLargeText: true };
    case 'email_optional':
      return { ...q, renderType: 'email', responseIsOptional: true };
    default:
      return q;
  }
};

const shuffleQuestions = (questions, shuffleFrom, shuffleTo) => {
  if (shuffleFrom <= 0 || shuffleTo <= 0) return questions;

  const realShuffleFrom = shuffleFrom - 1;
  const realShuffleTo = shuffleTo - 1;
  const preShuffleQuestions = [];
  let questionsToShuffle = [];
  const postShuffleQuestions = [];

  questions.forEach((q, index) => {
    if (index < realShuffleFrom) {
      preShuffleQuestions.push(q);
    } else if (index > realShuffleTo) {
      postShuffleQuestions.push(q);
    } else {
      questionsToShuffle.push(q);
    }
  });

  questions = preShuffleQuestions
    .concat(_.shuffle(questionsToShuffle))
    .concat(postShuffleQuestions);

  questions.forEach((q, index) => (q.index = index));

  return questions;
};

export const getQuestionsBucketedByRenderTypes = (questions) =>
  _.groupBy(questions, 'renderType');

export const getQuestionsOrderedByIndex = (questionsBucketedType) => {
  const result = [];
  for (let questionType in questionsBucketedType) {
    if (questionsBucketedType.hasOwnProperty(questionType)) {
      questionsBucketedType[questionType].forEach((q) => {
        result.push(q);
      });
    }
  }

  return _.sortBy(result, (o) => o.index);
};

export const shuffleChoices = (choices, shuffleTo, shuffler = _.shuffle) => {
  const raw = shuffler(choices.slice(0, shuffleTo)).concat(
    choices.slice(shuffleTo)
  );

  return raw.map((c, idx) => {
    return { ...c, order: idx + 1 };
  });
};

export const reverseChoices = (choices, reverseTo) => {
  var randomDraw = Math.floor(Math.random() * Math.floor(5));
  if (!reverseTo > 0 || randomDraw < 2) return choices;

  const raw = choices
    .slice(0, reverseTo)
    .reverse()
    .concat(choices.slice(reverseTo));

  return raw.map((c, idx) => {
    return { ...c, order: idx + 1 };
  });
};
