import { connect } from 'react-redux';
import * as actions from './actions';
import { Modal } from './component';

const mapStateToProps = ({ modal, viewport }) => {
  return {
    children: modal.content,
    closeable: modal.closeable,
    showing: modal.showing,
    viewport: viewport
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(actions.hideModal());
    }
  }
};

export const modalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
