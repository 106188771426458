import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Validator from 'email-validator';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailConfirmation: '',
      emailConfirmationMatches: true,
      emailIsValid: true,
      name: '',
      comment: '',
      showEmailModal: false,
      showEmailConfirmationModal: false,
      triedUnsuccessfulValidation: false,
    };
  }

  emailIsValid = (string) => {
    if (string === '') return true;
    return Validator.validate(string);
  };

  handleTextFieldChange = (e) => {
    const whichState = e.target.dataset.stateObjectKey;
    const value = e.target.value;
    const state = this.state;

    const newEmailValidState =
      whichState === 'email' ? this.emailIsValid(value) : state.emailIsValid;

    const newEmailConfirmationMatchesState = (() => {
      if (whichState === 'email') {
        return value === state.emailConfirmation;
      } else if (whichState === 'emailConfirmation') {
        return value === state.email;
      } else {
        return state.emailConfirmationMatches;
      }
    })();

    this.setState({
      [whichState]: value,
      emailIsValid: newEmailValidState,
      emailConfirmationMatches: newEmailConfirmationMatchesState,
    });
  };

  handleDropdownChange = (stateObjKey, value) => {
    this.setState({ [stateObjKey]: value });
  };

  handleBlur = (e) => {
    const stateObjectKey = e.target.dataset.stateObjectKey;
    const value = e.target.value;

    this.setState({
      showEmailModal:
        stateObjectKey === 'email' ? !this.emailIsValid(value) : false,
      showEmailConfirmationModal:
        stateObjectKey === 'emailConfirmation'
          ? !this.state.emailConfirmationMatches
          : false,
    });
  };

  // this callback  display modals is only called when an unsuccessful form submission is attempted
  showInvalidModals = () => {
    const emailIsValid = this.emailIsValid(this.state.email);

    this.setState({
      showEmailModal: !emailIsValid,
      showEmailConfirmationModal: !this.state.emailConfirmationMatches,
      triedUnsuccessfulValidation: !emailIsValid,
    });
  };

  handleFocus = () => {
    if (this.state.triedUnsuccessfulValidation) {
      this.setState({
        showEmailModal: false,
        showEmailConfirmationModal: false,
        triedUnsuccessfulValidation: false,
      });
    }
  };

  formIsValidForSubmission = () =>
    this.state.name.length > 0 &&
    this.state.email.length > 0 &&
    this.emailIsValid(this.state.email) &&
    this.state.emailConfirmationMatches;

  render() {
    return this.props.renderContent({
      formIsValidForSubmission: this.formIsValidForSubmission,
      formState: this.state,
      onTextFieldChange: this.handleTextFieldChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      showInvalidModals: this.showInvalidModals,
    });
  }
}

Form.propTypes = {
  renderContent: PropTypes.func.isRequired,
};

export default Form;
