import * as actionTypes from './actionTypes';

export const birthYearQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch(action.type) {
      case actionTypes.UPDATE_YEAR:
        return {...q, year: action.year, errorMsg: '', isAnswered: false};
      case actionTypes.VALIDATE_YEAR_FAILURE:
        return {...q, errorMsg: action.errorMsg};
      case actionTypes.SAVE_YEAR:
        return {...q, year: action.year, isAnswered: true, errorMsg: ''};
      default:
        return q;
    }
  });
};
