import { api } from './api';

export const goToSupplementalPoll = async function (
  pollImpressionId,
  optionalLocation = null
) {
  const location = optionalLocation || window.location;
  const response = await api.getSupplementalPoll(pollImpressionId);
  const json = await response.json();

  location.href = `/i/${json.supplemental_impression_hash}`;
};
