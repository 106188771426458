import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { BLUE } from '../../lib/colors';
import { MOBILE, TABLET, DESKTOP } from '../../lib/constants';
import { ViewportContext } from '../../viewportContext';
import { Button } from './Button';
import { ErrorMsg } from './ErrorMsg';

let styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '72%',
    float: 'left',
    marginRight: '0.75em',
  },
  textFieldContainerMobile: {
    width: '100%',
    float: 'none',
  },
  largeTextFieldContainer: {
    width: '100%'
  },
  textInput: {
    width: '100%',
    height: 16 + 20, 
    paddingTop: 10,
    paddingBottom: 10,
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 3,
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderTop: 'none',
    borderBottom: '1px solid #DDD',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
    resize: 'none',
    WebkitAppearance: 'none',
  },
  largeTextInput: {
    height: (16 + 2) * 3 + 20 // 3 lines with spacing and padding
  },
  button: {
    width: '50%',
    float: 'left',
    marginBottom: '0.75em',
    paddingRight: '0.37em',
  },
  submitButtonContainer: {
    width: '24%',
    float: 'left',
    marginBottom: '0.75em',
  },
  submitButtonContainerMobile: {
    width: '100%',
    float: 'none',
  },
  submitLargeTextButtonContainer: {
    clear: 'left',
  },
  textFieldWithIcon: {
    paddingLeft: 40,
  },
  hiddenLabel: {
    position: 'absolute',
    top: -999999,
    right: -999999,
  },
  iconCode: {
    color: BLUE,
    fontSize: 30,
    position: 'absolute',
    left: 6,
    top: 5,
  },
});

const Icon = ({ iconCode }) => {
  return iconCode ? (
    <span
      data-icon={iconCode}
      aria-hidden="true"
      className={css(styles.iconCode)}
    />
  ) : null;
};

let uniqueIdNum = 0;

const TextInput = (props) => {
  const {
    isActive,
    isLargeText,
    errorMsg,
    iconCode,
    isFocus,
    label,
    onBlur,
    onFocus,
    onKeyDown,
    onInputChange,
    onSubmit,
    response,
    screenReaderLabel,
    useAutoCapitalize,
    useAutoComplete,
    useAutoCorrect,
    type,
  } = props;

  const inputRef = useRef(null);
  const submitBtnRef = useRef(null);
  const viewport = useContext(ViewportContext);

  useEffect(() => {
    if ((viewport === TABLET || viewport === DESKTOP) && isFocus) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    inputRef.current.value = response;
  }, [response]);

  useEffect(() => {
    if (viewport === TABLET || viewport === DESKTOP) {
      if (isFocus) inputRef.current.focus();
    }
  }, [isFocus]);

  const handleChange = () => {
    onInputChange(inputRef.current.value);
  };

  const handleInputKeyDown = (event) => {
    const navigationKeys = new Set(['Tab', 'ArrowDown', 'ArrowUp', 'Enter']);

    if (navigationKeys.has(event.key)) {
      event.preventDefault();

      switch (event.key) {
        case 'Tab':
        case 'ArrowDown':
        case 'ArrowUp':
          submitBtnRef.current.focus();
          break;
        case 'Enter':
          if (inputRef.current.value || isActive) onSubmit();
          break;
        default:
          break;
      }
    } else {
      onKeyDown(event);
    }
  };

  const handleSubmitBtnKeyDown = (event) => {
    event.preventDefault();
    switch (event.key) {
      case 'Tab':
      case 'ArrowDown':
      case 'ArrowUp':
        inputRef.current.focus();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        if (isActive) onSubmit();
        break;
      default:
        break;
    }
  };

  const handleTextFieldFocus = () => {
    if (onFocus) onFocus();
  };

  const uniqueId = `text-field-${() => (uniqueIdNum += 1)}`; // use the same unique id on label's htmlFor and input's id

  return (
    <div>
      <div
        className={css(
          styles.textFieldContainer,
          styles && styles,
          viewport === MOBILE && styles.textFieldContainerMobile,
          isLargeText && styles.largeTextFieldContainer
        )}
      >
        <label
          aria-hidden={true}
          className={css(styles.hiddenLabel)}
          htmlFor={uniqueId}
        >
          This hidden label prevents autofill in mobile browsers
        </label>
        <Icon iconCode={iconCode} />
        <textarea 
          aria-label={screenReaderLabel}
          autoCapitalize={useAutoCapitalize ? 'on' : 'off'}
          autoComplete={useAutoComplete ? 'on' : 'off'}
          autoCorrect={useAutoCorrect ? 'on' : 'off'}
          className={css(styles.textInput, iconCode && styles.textFieldWithIcon, isLargeText && styles.largeTextInput)}
          id={uniqueId}
          maxLength={isLargeText ? 1000 : 500}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={handleTextFieldFocus}
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
          size={isLargeText ? 3 : 1}
          spellCheck={'true'}
          tabIndex={0}
          type={type}
        />
        <ErrorMsg errorMsg={errorMsg} />
      </div>
      <div
        className={css(
          styles.submitButtonContainer,
          viewport === MOBILE && styles.submitButtonContainerMobile,
          isLargeText && styles.submitLargeTextButtonContainer
        )}
      >
        <Button
          buttonRef={submitBtnRef}
          isActive={isActive}
          label={label}
          onClick={onSubmit}
          onKeyDown={handleSubmitBtnKeyDown}
          tabIndex={0}
          viewport={viewport}
        />
      </div>
    </div>
  );
};

TextInput.propTypes = {
  errorMsg: PropTypes.string,
  iconCode: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  screenReaderLabel: PropTypes.string,
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uniqueId: PropTypes.string,
  useAutoCapitalize: PropTypes.bool,
  useAutoComplete: PropTypes.bool,
  useAutoCorrect: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

TextInput.defaultProps = {
  isFocus: true,
  onBlur: () => null,
  onFocus: () => null,
  onKeyDown: () => null,
  screenReaderLabel: 'form field',
  useAutoCapitalize: false,
  useAutoComplete: true,
  useAutoCorrect: false,
};

export { TextInput };
