import * as actionTypes from './actionTypes';
import * as pollActions from '../../poll/actions';
import mixpanel from '../../lib/mixpanel';

export const updateNumber = (questionId, number) => {
  return {
    id: questionId,
    number: number,
    type: actionTypes.UPDATE_NUMBER,
  };
};

export const saveNumber = (questionId, questionBody, number) => {
  const response = { answer: number };

  return (dispatch) => {
    dispatch({
      id: questionId,
      number: number,
      type: actionTypes.SAVE_NUMBER,
    });
    dispatch(pollActions.saveResponse(questionId, questionBody, response));

    mixpanel.track('responded', {
      question: questionBody,
      answer: number,
    });
  };
};

export const validateNumberFailure = (questionId, errorMsg) => {
  return {
    id: questionId,
    errorMsg: 'Please enter a number, with no commas or periods.',
    type: actionTypes.VALIDATE_NUMBER_FAILURE,
  };
};

export const submitNumber = (questionId, questionBody, number) => {
  number = number.trim();
  const isValidNumber = (number) => /^\d+$/.test(number);

  return (dispatch) => {
    const valid = isValidNumber(number);
    if (valid) {
      dispatch(saveNumber(questionId, questionBody, number));
      dispatch(pollActions.setCurrentQuestionAutomatically());
    } else {
      dispatch(validateNumberFailure(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};

export const autoSubmitNumber = (questionId, questionBody, number) => {
  number = number.trim();
  const isValidNumber = (number) => /^\d+$/.test(number);

  return (dispatch) => {
    const valid = isValidNumber(number);
    if (valid) {
      dispatch(saveNumber(questionId, questionBody, number));
    } else {
      dispatch(validateNumberFailure(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};
