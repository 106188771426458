import { connect } from 'react-redux';
import { Mobilizer } from './component';
import * as actions from './actions';

const mapDispatchToProps = (dispatch) => {
  return {
    onResize: (windowWidth) => {
      dispatch(actions.updateViewportType(windowWidth));
    }
  }
};

export const mobilizerContainer = connect(
    null,
    mapDispatchToProps
  )(Mobilizer);
