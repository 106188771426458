import * as actionTypes from './appActionTypes';

export const showErrorPage = () => {
  return { type: actionTypes.SHOW_ERROR_PAGE };
};

export const showMaintenancePage = () => {
  return { type: actionTypes.SHOW_MAINTENANCE_PAGE };
};

export const hideLoadingSpinner = () => {
  return { type: actionTypes.HIDE_LOADING_SPINNER };
};

export const allowMainContentOverflow = () => {
  return { type: actionTypes.ALLOW_MAIN_CONTENT_OVERFLOW };
};

export const setBasePath = (path) => {
  return {
    type: actionTypes.SET_BASE_PATH,
    path: path
  }
};
