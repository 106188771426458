import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const ENVIRONMENT = window.civiqs.config.DEPLOY_ENVIRONMENT || 'development';

// export Bugsnag to the window for console debugging
window.civiqs.bugsnag = Bugsnag;
Bugsnag.start({
  // while it's called a "key" this isn't a secret
  apiKey: 'b96b38bff6e232bd642fb050ab381022',
  plugins: [new BugsnagPluginReact()],
  releaseStage: ENVIRONMENT,
  enabledReleaseStages: [ 'production', 'staging' ]
})

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
export default BugsnagErrorBoundary;
