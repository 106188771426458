import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { QuestionInputWrapper } from '../sharedComponents/QuestionInputWrapper';
import { TextInput } from '../sharedComponents/TextInput';

const NumericQuestionInput = (props) => {
  const {
    isAnswered,
    isCurrent,
    errorMsg,
    onInputChange,
    onTextFieldFocus,
    onSubmit,
    viewport,
    number,
  } = props;

  const dispatch = useDispatch();
  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  return (
    <QuestionInputWrapper
      viewport={viewport}
      answer={number.toString()}
      isCurrent={isCurrent}
    >
      <TextInput
        isActive={isCurrent && number.length > 0}
        isAnswered={isAnswered}
        errorMsg={errorMsg}
        label={'submit'}
        onFocus={onTextFieldFocus}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        response={number.toString()}
        screenReaderLabel={'number'}
        viewport={viewport}
        visible={isCurrent}
      />
    </QuestionInputWrapper>
  );
};

NumericQuestionInput.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  viewport: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

NumericQuestionInput.defaultProps = {
  number: '',
};

export { NumericQuestionInput };
