import { findDOMNode } from 'react-dom';

export const getComponentPos = (component) => {
  const node = findDOMNode(component);
  const rect = node.getBoundingClientRect();

  return { top: rect.top, bottom: rect.bottom };
};

export const getComponentOffset = (component) => {
  const node = findDOMNode(component);
  const top = node.offsetTop;
  const bottom = top + node.offsetHeight;

  return { top, bottom };
}