import React, { useContext } from 'react';
import { IndexLink } from 'react-router';
import { StyleSheet, css } from 'aphrodite-jss';

import { NAV_HEIGHT } from './lib/constants';
import { mobileDeviceIsSmall } from './lib/mobileDeviceIsSmall';
import { MONTSERRAT, TISA } from './lib/fonts';
import * as colors from './lib/colors';

import { Flex } from './sharedComponents/layoutHelpers';
import { AppPathContext } from './appPathContext';

const styles = StyleSheet.create({
  errorMsgContainer: {
    position: 'relative',
    maxWidth: 500,
    marginTop: '4em',
    fontFamily: TISA
  },
  errorMsgHeading: {
    fontSize: 40,
    textAlign: 'center'
  },
  errorMsgSubHeading: {
    fontSize: 18,
    textAlign: 'center'
  },
  reloadButton: {
    backgroundColor: colors.BLUE,
    color: colors.WHITE,
    fontSize: 20,
    fontFamily: MONTSERRAT,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    border: `8px solid ${colors.WHITE}`,
    padding: 8,
    marginTop: 24,
    textDecoration: 'none'
  },
  reloadButtonMobile: {
    height: 72,
    fontSize: 18,
    border: `8px solid ${colors.WHITE}`,
    marginTop: (!mobileDeviceIsSmall()) ? -1 * NAV_HEIGHT/2 : 0
  }
});

export const ErrorPage = () => {
  const { appPath } = useContext(AppPathContext);

  return (
    <Flex
      flexFlow='column nowrap'
      justifyContent='center'
      alignItems='center'
      >
      <div className={css(styles.errorMsgContainer)}>
        <h1 className={css(styles.errorMsgHeading)}>We're sorry!</h1>
        <p className={css(styles.errorMsgSubHeading)}>There was a problem loading the poll. Please try reloading the page or checking your internet connection.</p>

        <Flex
          flexFlow='row nowrap'
          justifyContent='center'
          alignItems='center'
          >
            <IndexLink to={appPath} className={css(styles.reloadButton)}>Reload</IndexLink>
        </Flex>

      </div>
    </Flex>
  );
}
