import * as namespace from './namespace';

export const UPDATE_EMAIL = namespace + '/UPDATE_EMAIL';
export const VALIDATE_EMAIL = namespace + '/VALIDATE_EMAIL';
export const SHOW_VALIDATION_FAILURE_MSG =
  namespace + '/SHOW_VALIDATION_FAILURE_MSG';
export const SHOW_EMAIL_SUGGESTION = namespace + '/SHOW_EMAIL_SUGGESTION';
export const HIDE_EMAIL_SUGGESTION = namespace + '/HIDE_EMAIL_SUGGESTION';
export const SAVE_EMAIL_RESPONSE = namespace + '/SAVE_EMAIL_RESPONSE';
export const SKIP_EMAIL_QUESTION = namespace + '/SKIP_EMAIL_QUESTION';
