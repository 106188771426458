import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import { HIGHTLIGHT_YELLOW } from '../../lib/colors';

export const _Highlight = (props) => {
  const top = props.top;
  const bottom = props.bottom;
  const height = bottom - top;

  const styles = {
    position: 'absolute',
    width: '100%',
    height: height,
    backgroundColor: HIGHTLIGHT_YELLOW,
    zIndex: 0,
    transition: 'transform 1s',
    transform: `translate(0px, ${top}px)`,
    padding: 0,
    margin: 0,
  };

  return <div style={styles} />;
};

_Highlight.propTypes = {
  top: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
};

const mapStateToProps = ({ poll, viewport }) => {
  const completed = selectors.viewedAllQuestions(poll) && !poll.submitted;

  return {
    top: completed ? 0 : poll.highlight.top,
    bottom: completed ? 0 : poll.highlight.bottom,
    viewport,
  };
};

export const Highlight = connect(mapStateToProps, null)(_Highlight);

