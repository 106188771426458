import * as actionTypes from './actionTypes';
import { DESKTOP } from '../lib/constants';

export const mobilizerStateReducer = (state = DESKTOP, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEW_PORT_TYPE:
      return action.viewportType;
    default:
      return state;
  }
};
