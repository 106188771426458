import { blockList } from '../../lib/blockList';
import _ from 'lodash';
import * as Validator from '../../lib/emailValidator';
import Mailcheck from 'mailcheck';
import mixpanel from '../../lib/mixpanel';

const isBlocklisted = (email) => {
  return _.includes(blockList, email.split('@')[1]);
};

export function businessLogic(actions, props) {
  const decideEmailProcess = function() {
    const { email } = props;

    if (Validator.validate(email)) {
      processEmailCandidate(
        ({ full: suggestion }) => {
          actions.showEmailSuggestion(suggestion);
        },
        () => {
          actions.dispatchActionsToRecordEmailResponse(email);
          actions.hideEmailSuggestion();
        }
      );
    } else {
      actions.dispatchActionsToHandleInvalidEmail();
    }
  }

  const processEmailCandidate = function(onSuggested, onEmpty) {
    const { email, body, id, responseIsOptional } = props;

    Mailcheck.run({
      email,
      empty: onEmpty,
      suggested: onSuggested,
    });

    mixpanel.track('provided_email', {
      answer: email,
      email,
      question: body,
    });

    if (responseIsOptional) {
      mixpanel.track('email_optional_submit', {
        email,
        id,
        question: body,
      });
    }
  }

  const processEmailSuggestionAccept = function() {
    const { body, email, suggestedEmail } = props;

    actions.dispatchActionsToRecordEmailResponse(suggestedEmail);
    actions.hideEmailSuggestion();

    mixpanel.track('provided_email', {
      answer: email,
      email: email,
      question: body,
    });

    mixpanel.track('Accepted Email Suggestion', {
      submitted_email: email,
      accepted_email_suggestion: suggestedEmail,
    });
  }

  const processEmailSuggestionReject = function(fnIsBlocklisted = null) {
    const { body, email, suggestedEmail } = props;
    const _fnIsBlocklisted = fnIsBlocklisted || isBlocklisted;

    if (_fnIsBlocklisted(email)) {
      actions.dispatchActionsToHandleInvalidEmail();
      actions.hideEmailSuggestion();

      mixpanel.track('Invalid Email Submitted', {
        email: email,
        question: body,
      });
    } else {
      actions.dispatchActionsToRecordEmailResponse(email);
      actions.hideEmailSuggestion();

      mixpanel.track('Rejected Email Suggestion', {
        submitted_email: email,
        rejected_email_suggestion: suggestedEmail,
      });

      mixpanel.track('provided_email', {
        answer: email,
        email: email,
        question: body,
      });
    }
  }

  const processQuestionSkip = function() {
    actions.handleUserSkippedEmailQuestion();
    const { id, body, responseIsOptional } = props;

    if (responseIsOptional) {
      mixpanel.track('email_optional_skip', {
        id,
        question: body,
      });
    }
  }

  return {
    decideEmailProcess,
    processEmailCandidate,
    processEmailSuggestionAccept,
    processEmailSuggestionReject,
    processQuestionSkip,
  };
}

