import { connect } from 'react-redux';
import { find } from 'lodash';
import { OptionsInput } from './component';
import * as actions from './actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCustomOptionChange: (choiceId, optionValue) => {
      if (optionValue.trim() === '') {
        dispatch(actions.unsetOptionPending(ownProps.id, choiceId));
      } else {
        dispatch(actions.setOptionPending(ownProps.id, choiceId));
      }

      dispatch(actions.updateCustomOption(ownProps.id, choiceId, optionValue));
    },

    onOptionClick: (choiceId) => {
      const choice = find(ownProps.choices, (choice) => choice.id === choiceId);

      const answer =
        choice.isCustomOtherChoice && !!choice.customValue
          ? choice.customValue
          : choice.body;

      // choiceId should be null for dynamic render types (congressional_district and state_level_district)
      const responseId = choice.isDynamic ? null : choiceId;
      const apiResponse = { answer: answer, choiceId: responseId };

      dispatch(
        actions.selectOption(ownProps.id, ownProps.body, choiceId, apiResponse)
      );
    },

    setOptionPending: (choiceId) => {
      dispatch(actions.setOptionPending(ownProps.id, choiceId));
    },

    unsetOptionPending: (choiceId) => {
      dispatch(actions.unsetOptionPending(ownProps.id, choiceId));
    },

    initOptionOrder: () => {
      if (ownProps.shuffleChoices > 0) {
        dispatch(actions.shuffleOptions(ownProps.id));
      } else if (ownProps.reverseChoices > 0) {
        dispatch(actions.reverseOptions(ownProps.id));
      }
    },
  };
};

export const OptionsQuestionContainer = connect(null, mapDispatchToProps)(OptionsInput);

