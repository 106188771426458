import * as actionTypes from './actionTypes';

export const emailQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch (action.type) {
      case actionTypes.SKIP_EMAIL_QUESTION:
        return { ...q, isAnswered: true };
      case actionTypes.UPDATE_EMAIL:
        return {
          ...q,
          email: action.email,
          errorMsg: '',
          isAnswered: false,
        };
      case actionTypes.SHOW_VALIDATION_FAILURE_MSG:
        return {
          ...q,
          errorMsg: action.errorMsg,
          suggestedEmail: '',
          isAnswered: false,
        };
      case actionTypes.SAVE_EMAIL_RESPONSE:
        return { ...q, email: action.email, isAnswered: true, errorMsg: '' };
      case actionTypes.SHOW_EMAIL_SUGGESTION:
        return {
          ...q,
          errorMsg: '',
          suggestedEmail: action.suggestedEmail,
          email: action.currentEmail,
          isAnswered: true,
        };
      case actionTypes.HIDE_EMAIL_SUGGESTION:
        return { ...q, suggestedEmail: '' };
      default:
        return q;
    }
  });
};

