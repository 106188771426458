import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Flex } from './sharedComponents/layoutHelpers';
import { FlexList } from './sharedComponents/layoutHelpers';
import * as colors from './lib/colors';
import { MONTSERRAT } from './lib/fonts';
import { NAV_HEIGHT } from './lib/constants';
import { Logo } from './Logo';

const styles = StyleSheet.create({
  container: {
    height: NAV_HEIGHT,
    position: 'fixed',
    backgroundColor: colors.WHITE,
    width: '100%',
    zIndex: 10,
    fontFamily: MONTSERRAT,
    left: 0,
    top: 0,
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12), 0 4px 6px 0 rgba(28,35,51,0.08)',
  },
  menu: {
    flex: '0 0 auto',
    marginRight: '3em',
  },
  itemContainer: {
    marginLeft: '1.25em',
    cursor: 'pointer',
  },
  itemText: {
    fontSize: '0.6875em',
    letterSpacing: '1px',
    lineHeight: '1.45em',
    color: colors.DARK_BLUE,
    textDecoration: 'none',
    textTransform: 'uppercase',
    paddingTop: 5,
  },
  itemTextActive: {
    borderTop: `3px solid ${colors.BLUE}`,
    color: colors.TUNA_GREY,
  },
});

const HorizontalNavLink = ({ to, children }) => {
  return (
    <li className={css(styles.itemContainer)}>
      <a href={to} target={'_blank'} style={styles.itemText.style}>
        {children}
      </a>
    </li>
  );
};

const Menu = () => {
  return (
    <div className={css(styles.menu)}>
      <FlexList justifyContent={'space-between'} alignItems={'center'}>
        <HorizontalNavLink to={'/join-in'}>About this Survey</HorizontalNavLink>
        <HorizontalNavLink to={'/what-we-offer'}>
          Research with Civiqs
        </HorizontalNavLink>
        <HorizontalNavLink to={'/privacy'}>Privacy Policy</HorizontalNavLink>
        <HorizontalNavLink to={'/survey-terms-of-service'}>
          Terms of Use
        </HorizontalNavLink>
      </FlexList>
    </div>
  );
};

export const HorizontalNav = () => {
  return (
    <div className={css(styles.container)}>
      <Flex
        flexFlow={'row nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Logo />
        <Menu />
      </Flex>
    </div>
  );
};

