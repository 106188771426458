import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { TISA } from '../../lib/fonts';
import * as colors from '../../lib/colors';

const styles = StyleSheet.create({
  reassurance: {
    fontSize: '0.9em',
    fontWeight: 100,
    fontFamily: TISA,
    marginTop: '0.6em',
    marginBottom: '1.1em',
  },
  suggestionContainer: {
    marginTop: '0.5em',
  },
  suggestionText: {
    fontSize: '1.1em',
    fontWeight: 500,
    fontFamily: TISA,
    color: colors.TUNA_GREY,
  },
  suggestionButton: {
    color: colors.BLUE,
    fontSize: '1.1em',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  emailAddress: {
    color: colors.TUNDORA_GREY,
    fontSize: '0.9em',
    fontWeight: 600,
  },
});

export const Suggestion = ({ suggestedEmail, currentEmail, onAccept, onReject }) => {
  return suggestedEmail ? (
    <div className={css(styles.suggestionContainer)}>
      <p className={css(styles.suggestionText)}>
        Did you mean:{' '}
        <span className={css(styles.emailAddress)}>{suggestedEmail}</span>
        ?&nbsp;
        <span
          id="accept-suggestion"
          onClick={onAccept}
          className={css(styles.suggestionButton)}
        >
          Yes
        </span>
      </p>
      <p className={css(styles.suggestionText)}>
        <span
          id="reject-suggestion"
          onClick={onReject}
          className={css(styles.suggestionButton)}
        >
          No
        </span>
        , keep <span className={css(styles.emailAddress)}>{currentEmail}</span>
      </p>
    </div>
  ) : null;
};

Suggestion.propTypes = {
  currentEmail: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  suggestedEmail: PropTypes.string,
};

Suggestion.defaultProps = {
  suggestedEmail: '',
};
