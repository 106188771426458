import { namespace } from './namespace';

export const INCREMENT_NUM_QUESTIONS_COMPLETED =
  namespace + '/UPDATE_NUM_QUESTIONS_COMPLETED';
export const MARK_POLL_SUBMITTED = namespace + '/MARK_POLL_SUBMITTED';
export const SUBMIT_SURVEY_ANSWERS = namespace + '/SUBMIT_SURVEY_ANSWERS';
export const SAVE_RESPONSE = namespace + '/SAVE_RESPONSE';
export const SET_POLL_METADATA = namespace + '/SET_POLL_METADATA';
export const ACTIVATE_PREVIEW_MODE = namespace + '/ACTIVATE_PREVIEW_MODE';
export const SET_GEOCOORDINATES = namespace + '/SET_GEOCOORDINATES';
export const UNSET_GEOCOORDINATES = namespace + '/UNSET_GEOCOORDINATES';
export const CLEAR_RESPONSES = namespace + '/CLEAR_RESPONSES';
export const MARK_EMAIL_SUBMITTED = namespace + '/MARK_EMAIL_SUBMITTED';
export const SKIP_QUESTION = namespace + '/SKIP_QUESTION';
export const LOAD_QUESTION_DATA = namespace + '/LOAD_QUESTION_DATA';
export const SET_CURRENT_QUESTION_BY_ID =
  namespace + '/SET_CURRENT_QUESTION_BY_ID';
export const SET_CURRENT_QUESTION_AUTOMATICALLY =
  namespace + '/SET_CURRENT_QUESTION_AUTOMATICALLY';
export const UNSET_CURRENT_QUESTION = namespace + '/UNSET_CURRENT_QUESTION';