import React from 'react';
import { connect } from 'react-redux';
import './lib/iconFonts.css';
import './lib/reset.css';
import { NAV_HEIGHT } from './lib/constants';
import { mobileDeviceIsSmall } from './lib/mobileDeviceIsSmall';
import { Flex } from './sharedComponents/layoutHelpers';

const _MainContentWrapper = ({
  allowMainContentOverflow,
  children,
}) => {

  return (
    <div
      style={{
        paddingTop: !mobileDeviceIsSmall() ? NAV_HEIGHT : 0,
        overflowX: allowMainContentOverflow ? 'visible' : 'hidden',
        width: '100%',
      }}
    >
      <Flex
        alignItems="center"
        flexFlow="column nowrap"
        justifyContent="flex-start"
      >
        {children}
      </Flex>
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    allowMainContentOverflow: app.allowMainContentOverflow,
  };
};

export const MainContentWrapper = connect(mapStateToProps, null)(_MainContentWrapper);
