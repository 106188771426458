import * as actionTypes from './actionTypes';

const initialState = {
  showing: false,
  content: null,
  closeable: true
};

export const modalStateReducer =  (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        closeable: action.closeable,
        content: action.content,
        showing: true,
      };
    case actionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
