import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as colors from '../../lib/colors';
import * as constants from '../../lib/constants';

const getCircleOptionIcon = (isChecked) =>
  isChecked
    ? constants.OPTION_BTN_ICON_CIRCLE_CHECKED
    : constants.OPTION_BTN_ICON_CIRCLE_UNCHECKED;

const getSquareOptionIcon = (isChecked) =>
  isChecked
    ? constants.OPTION_BTN_ICON_SQUARE_CHECKED
    : constants.OPTION_BTN_ICON_SQUARE_UNCHECKED;

const getIcon = (shape, isChecked) => {
  return shape === 'circle'
    ? getCircleOptionIcon(isChecked)
    : getSquareOptionIcon(isChecked);
};

const OptionButton = ({ isActive, isChecked, shape }) => {
  const styles = StyleSheet.create({
    base: {
      cursor: 'pointer',
      color: colors.FRENCH_GREY,
      ':hover': {
        color: colors.BLUE,
      },
    },
    circle: {
      fontSize: `2em`,
    },
    square: {
      fontSize: `1.30em`,
    },
    active: {
      color: colors.BLUE,
    },
    checked: {
      color: colors.BLUE,
    },
  });

  if (shape === 'circle') {
    return (
      <span
        data-icon={getIcon(shape, isChecked)}
        aria-hidden="true"
        className={css(
          styles.base,
          styles.circle,
          isChecked && styles.checked,
          isActive && styles.active
        )}
      />
    );
  }

  if (shape === 'square') {
    return (
      <span
        option-btn-icon={getIcon(shape, isChecked)}
        aria-hidden="true"
        className={css(
          styles.base,
          styles.square,
          isChecked && styles.checked,
          isActive && styles.active
        )}
      />
    );
  }
};

OptionButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'square']),
};

OptionButton.defaultProps = {
  shape: 'circle',
};

export { OptionButton };
