import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Box, Flex as RebassFlex } from '@rebass/emotion';
import * as colors from '../lib/colors';
import { MONTSERRAT, TISA } from '../lib/fonts';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  returnToPollButton: {
    background: colors.RED,
    fontFamily: MONTSERRAT,
    color: colors.WHITE,
    cursor: 'pointer',
    fontWeight: 700,
    padding: '1em',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    margin: '0 auto',
    maxWidth: 900,
    display: 'block',
    boxSizing: 'border-box',
  },
  hero: {
    backgroundColor: colors.DARK_BLUE,
    boxSizing: 'border-box',
    fontFamily: MONTSERRAT,
    margin: '0 auto',
    paddingTop: '3.5em',
    paddingBottom: '3.5em',
    paddingRight: '1.5em',
    paddingLeft: '1.5em',
    textAlign: 'center',
    width: '100%',
  },
  title: {
    color: colors.WHITE,
    fontSize: '2em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '1.125em',
    letterSpacing: '1.78px',
  },
  longTitle: {
    color: colors.WHITE,
    fontSize: '1.4em',
    textTransform: 'uppercase',
    lineHeight: '1.2em',
    letterSpacing: '1.78px',
    fontWeight: 'bold',
  },
  subTitle: {
    color: colors.WHITE,
    fontFamily: TISA,
    fontSize: '1.2em',
    fontWeight: 100,
    fontStyle: 'italic',
    lineHeight: '1.5em',
    width: '75%',
    marginTop: '1em',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  body: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: colors.WHITE,
    lineHeight: '1.5em',
    color: colors.TUNDORA_GREY,
    paddingRight: '2em',
    paddingLeft: '2em',
    paddingTop: '2em',
    paddingBottom: '2em',
    fontFamily: TISA,
    boxSizing: 'border-box',
  },
  sectionHeading: {
    fontFamily: MONTSERRAT,
    fontSize: '1.2em',
    color: '#283141',
    letterSpacing: '1px',
    lineHeight: '1.71em',
  },
  p: {
    paddingBottom: '1.2em',
    fontSize: '1.2em',
  },
  sectionSubHeading: {
    fontWeight: 600,
  },
  icon: {
    fontSize: 50,
    color: colors.BLUE,
  },
  surveryCompletedLink: {
    color: colors.WHITE,
    fontSize: '20px',
  },
  surveryCompletedText: {
    color: colors.WHITE,
    fontSize: '20px',
    display: 'block',
  },
});

export const Container = ({ children }) => (
  <div className={css(styles.container)}>{children}</div>
);

export const Hero = ({ children }) => (
  <div className={css(styles.hero)}>{children}</div>
);

export const Title = ({ style, children }) => (
  <h1 className={css(styles.title, style)}>{children}</h1>
);

export const LongTitle = ({ style, children }) => (
  <h1
    style={{ maxWidth: '50%', margin: '0 auto' }}
    className={css(styles.longTitle, style)}
  >
    {children}
  </h1>
);

export const SectionHeading = ({ style, children }) => (
  <h1 className={css(styles.sectionHeading, style)}>{children}</h1>
);

export const SubTitle = ({ children }) => (
  <p className={css(styles.subTitle)}>{children}</p>
);

export const ThreeStars = () => (
  <span className={css(styles.icon)} data-icon={'s'} aria-hidden="true" />
);

export const Body = ({ children }) => (
  <div className={css(styles.body)}>{children}</div>
);

export const P = ({ children }) => <p className={css(styles.p)}>{children}</p>;

export const Row = ({ children }) => (
  <RebassFlex
    alignItems={'flex-start'}
    flexDirection={'row'}
    flexWrap={'wrap'}
    justifyContent={'space-between'}
    width={1}
  >
    {children}
  </RebassFlex>
);

export const Column = ({ children }) => (
  <Box width={[1, '49%']}>{children}</Box>
);

export const GridContainer = ({ children }) => (
  <Box maxWidth={840} m={[0, 'auto']} p={4}>
    {children}
  </Box>
);

export const SurveyCompletedLink = ({ href, children, onClick }) => {
  return (
    <a
      href={href}
      className={css(styles.surveryCompletedLink)}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export const SurveyCompletedText = ({ children }) => {
  return <span className={css(styles.surveryCompletedText)}>{children}</span>;
};

const getFlexStyles = ({
  flexFlow = 'row nowrap',
  justifyContent ='flex-start',
  alignItems = 'stretch',
  alignContent = 'flex-start',
}) => StyleSheet.create({
  base: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexFlow,
    justifyContent,
    alignItems,
    alignContent,
  }
});

export const Flex = ({
  alignItems,
  children,
  flexFlow,
  justifyContent,
  style,
  ...rest
}) => {
  const styles = getFlexStyles({ flexFlow, justifyContent, alignItems });

  return (
    <div {...rest} className={css(styles.base, style)}>
      {children}
    </div>
  );
};

const getFlexListStyles = ({
  flexFlow = 'row nowrap',
  justifyContent ='flex-start',
  alignItems = 'stretch'
}) => StyleSheet.create({
  base: {
    display: 'flex',
    width: '100%',
    height: '100%',
    listStyle: 'none',
    flexFlow,
    justifyContent,
    alignItems
  }
});

export const FlexList = ({ flexFlow, justifyContent, alignItems, children, style, ...rest}) => {
  const styles = getFlexListStyles({ flexFlow, justifyContent, alignItems });

  return (
    <ul className={css(styles.base, style)}>
      {children}
    </ul>
  );
};
