import { reverseChoices, shuffleChoices } from '../../lib/model';
import * as actionTypes from './actionTypes';

const multipleOptionsQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;

    switch (action.type) {
      case actionTypes.SHUFFLE_OPTIONS:
        return { ...q, choices: shuffleChoices(q.choices, q.shuffleChoices) };
      case actionTypes.REVERSE_OPTIONS:
        return { ...q, choices: reverseChoices(q.choices, q.reverseChoices) };
      case actionTypes.SAVE_RESPONSE:
        return { ...q, response: action.response, isAnswered: true };
      default:
        return { ...q, choices: choiceReducer(q.choices, action) };
    }
  });
};

const choiceReducer = (state = [], action) => {
  return state.map((c) => {
    if (c.id !== action.optionId) return c;
    switch (action.type) {
      case actionTypes.CHECK_OPTION:
        return { ...c, selected: true };
      case actionTypes.UNCHECK_OPTION:
        return { ...c, selected: false };
      default:
        return c;
    }
  });
};

export { multipleOptionsQuestionStateReducer };
