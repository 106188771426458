import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Flex, Text } from '@rebass/emotion';
import { Transition } from 'react-transition-group';
import { MEDIUM_GREY } from '../../lib/colors';
import { MONTSERRAT } from '../../lib/fonts';
import Form from './Form';
import TextInput from '../formHelpers/TextInput';
import SubmitButton from '../formHelpers/SubmitButton';
import {
  Column,
  Container,
  GridContainer,
  Hero,
  LongTitle,
  Row,
  Title,
  ThreeStars,
} from '../layoutHelpers';

export const SuccessPage = ({ text }) => (
  <Container>
    <Hero>
      <Title>Thank you.</Title>
      <ThreeStars />
      <LongTitle>{text}</LongTitle>
    </Hero>
  </Container>
);

export const ReportUnWantedEmailPage = () => (
  <Container>
    <Hero>
      <LongTitle>
        Thank you for letting us know that you did not make this request.
      </LongTitle>
      <ThreeStars />
      <LongTitle>We apologize for the inconvenience.</LongTitle>
    </Hero>
  </Container>
);

const duration = 1500;

const defaultStyle = {
  opacity: 0,
  background: 'none',
};

const transitionStyles = {
  entering: {
    opacity: 1,
    background: '#FDFD96',
    transition: `all ${duration}ms ease-in-out`,
  },
  entered: {
    opacity: 1,
    transition: `background ${duration}ms ease-in-out`,
    background: 'none',
  },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ThankYou = ({ isShowing }) => (
  <Box my={3}>
    <Transition in={isShowing} timeout={1000}>
      {(state) => (
        <Text
          color={MEDIUM_GREY}
          fontFamily={MONTSERRAT}
          fontSize={[1, 2]}
          fontWeight={300}
          key={1}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          Thank you. Please check your email for a confirmation.
        </Text>
      )}
    </Transition>
  </Box>
);

export const RequestPage = ({ mainInstructionText, putRequest, title }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Container>
      <Hero>
        <Title>{title}</Title>
      </Hero>
      <GridContainer>
        <Text
          color={MEDIUM_GREY}
          fontFamily={MONTSERRAT}
          fontSize={[1, 2]}
          fontWeight={300}
          mb={14}
        >
          {mainInstructionText}
        </Text>
        <Box mt={4}>
          <Form
            renderContent={({
              formState,
              formIsValidForSubmission,
              onBlur,
              onTextFieldChange,
              onFocus,
              showInvalidModals,
            }) => {
              return (
                <form name="do-not-sell_form">
                  <Flex
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    <Row>
                      <TextInput
                        data-state-object-key="name"
                        id="do-not-sell-name"
                        isValid={true}
                        label="Full Name"
                        labelIsHidden={true}
                        name="name"
                        onBlur={onBlur}
                        onChange={onTextFieldChange}
                        onFocus={onFocus}
                        type="text"
                        value={formState.name}
                      />
                    </Row>
                    <Row>
                      <Column>
                        <TextInput
                          data-state-object-key="email"
                          id="do-not-sell-email"
                          invalidInputMessage={'Please enter a valid email.'}
                          isValid={formState.emailIsValid}
                          label="Email"
                          labelIsHidden={true}
                          name="do-not-sell-email"
                          onBlur={onBlur}
                          onChange={onTextFieldChange}
                          onFocus={onFocus}
                          showModal={formState.showEmailModal}
                          type="email"
                          value={formState.email}
                        />
                      </Column>
                      <Column>
                        <TextInput
                          data-state-object-key="emailConfirmation"
                          invalidInputMessage={
                            'Your confirmation email does not match.'
                          }
                          isValid={formState.emailConfirmationMatches}
                          label="Confirm Email"
                          labelIsHidden={true}
                          onBlur={onBlur}
                          onChange={onTextFieldChange}
                          onFocus={onFocus}
                          showModal={formState.showEmailConfirmationModal}
                          type="email"
                          value={formState.emailConfirmation}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <TextInput
                        data-state-object-key="comment"
                        id="do-not-sell-comment"
                        isValid={true}
                        label="Comment (Optional)"
                        labelIsHidden={true}
                        name="comment"
                        onBlur={onBlur}
                        onChange={onTextFieldChange}
                        onFocus={onFocus}
                        resizable={true}
                        rows={2}
                        type="text"
                        value={formState.comment}
                      />
                    </Row>
                    <Row>
                      <SubmitButton
                        isActive={formIsValidForSubmission()}
                        onClick={() => {
                          if (formIsValidForSubmission()) {
                            putRequest({
                              name: formState.name,
                              email: formState.email,
                              comment: formState.comment,
                            });
                            setSubmitted(true);
                          } else {
                            showInvalidModals();
                          }
                        }}
                      />
                    </Row>
                    <Row>
                      <ThankYou isShowing={submitted} />
                    </Row>
                  </Flex>
                </form>
              );
            }}
          />
        </Box>
      </GridContainer>
    </Container>
  );
};
