import React, { useContext } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import './lib/iconFonts.css';
import './lib/reset.css';
import { MOBILE, TABLET } from './lib/constants';

import { Flex } from './sharedComponents/layoutHelpers';

import { mobilizerContainer as Mobilizer } from './mobilizer/stateContainer';
import { HorizontalNav } from './HorizontalNav';
import { DropdownNav } from './DropdownNav';
import { MainContentWrapper } from './MainContentWrapper';
import { Footer } from './Footer';
import { modalContainer as Modal } from './modal/stateContainer';
import { ErrorPage } from './ErrorPage';
import { LoadingPage } from './LoadingPage';
import { MaintenancePage } from './MaintanencePage';
import { ViewportContext } from './viewportContext';


const _App = (props) => {
  const {
    children,
    showErrorPage,
    showMaintenancePage,
    showLoadingSpinner,
  } = props;

  const viewport = useContext(ViewportContext);

  let mainContent;

  if (showErrorPage === true) {
    mainContent = <ErrorPage />;
  } else if (showMaintenancePage === true) {
    mainContent = <MaintenancePage />;
  } else if (showLoadingSpinner === true) {
    mainContent = <LoadingPage />;
  } else {
    mainContent = children;
  }

  return (
    <Flex
      flexFlow="column nowrap"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Mobilizer />
      {viewport === MOBILE || viewport === TABLET ? (
        <DropdownNav />
      ) : (
        <HorizontalNav />
      )}
      <MainContentWrapper>{mainContent}</MainContentWrapper>
      <Footer />
      <Modal />
    </Flex>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    showLoadingSpinner: app.showLoadingSpinner,
    showErrorPage: app.showErrorPage,
    showMaintenancePage: app.showMaintenancePage,
  };
};

export const App = connect(mapStateToProps)(_App);
