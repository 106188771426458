import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { api } from './lib/api';
import {
  SuccessPage,
  ReportUnWantedEmailPage,
  RequestPage,
} from './sharedComponents/simpleRequestForm/component';
import * as appActions from './appActions';

const _Ccpa = ({ allowMainContentOverflow, location: { pathname: path } }) => {
  useEffect(() => {
    allowMainContentOverflow();
  }, []);

  if (_.includes(path, 'success'))
    return (
      <SuccessPage text={`Civiqs has deleted your personal information.`} />
    );
  if (_.includes(path, 'unwanted-email')) return <ReportUnWantedEmailPage />;
  return (
    <RequestPage
      title="Erase"
      mainInstructionText={`
         If you are a California resident, you may request that Civiqs delete
         the personal information that we maintain about you. If you wish for
         Civiqs to delete your personal information, please complete this form:
      `}
      putRequest={({ name, email, comment }) => {
        api.putCcpaRequest({ name, email, comment });
      }}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allowMainContentOverflow: () => {
      dispatch(appActions.allowMainContentOverflow());
    },
  };
};

const Ccpa = connect(null, mapDispatchToProps)(_Ccpa);

export { Ccpa };
