import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import * as appActions from '../appActions';

const useMainContentOverflow = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appActions.allowMainContentOverflow());
  })
}

export { useMainContentOverflow };