import { connect } from 'react-redux';
import { TextQuestionInput } from './component';
import * as actions from './actions';
import * as pollActions from '../../poll/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInputChange: (response) => {
      dispatch(actions.updateResponse(ownProps.id, response));
    },
    onSubmit: () => {
      dispatch(
        actions.saveResponse(ownProps.id, ownProps.body, ownProps.response)
      );
      dispatch(pollActions.setCurrentQuestionAutomatically());
    },
    onAutoSubmit: () => {
      dispatch(
        actions.saveResponse(ownProps.id, ownProps.body, ownProps.response)
      );
    },
    onTextFieldFocus: () => {
      if (ownProps.isCurrent) return;
      dispatch(pollActions.setCurrentQuestionById(ownProps.id));
    },
  };
};

export const TextQuestionContainer = connect(null, mapDispatchToProps)(TextQuestionInput);
