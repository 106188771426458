import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { BLUE } from '../../lib/colors';
import { tabbableElements } from './tabbableElements';

const { TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN } = tabbableElements;

let styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '72%',
    float: 'left',
    marginRight: '0.75em',
  },
  textInput: {
    height: 16 + 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 3,
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderTop: 'none',
    borderBottom: '1px solid #DDD',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
    WebkitAppearance: 'none',
  },
  textFieldContainerMobile: {
    width: '100%',
    float: 'none',
  },
  textFieldWithIcon: {
    paddingLeft: 40,
  },
  hiddenLabel: {
    position: 'absolute',
    top: -999999,
    right: -999999,
  },
  iconCode: {
    color: BLUE,
    fontSize: 30,
    position: 'absolute',
    left: 6,
    top: 5,
  },
});

const stylesWithSkipBtn = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '50%',
    float: 'left',
    paddingRight: '0.75em',
  },
  textFieldContainerMobile: {
    width: '100%',
    float: 'none',
    paddingRight: 0,
  },
});

const Icon = ({ iconCode }) => {
  return iconCode ? (
    <span
      data-icon={iconCode}
      aria-hidden="true"
      className={css(styles.iconCode)}
    />
  ) : null;
};

let uniqueIdNum = 0;

export const DesktopTextField = (props) => {
  const {
    elementInFocus,
    focusOnPrevElem,
    focusOnNextElem,
    iconCode,
    inputValue,
    isChildOfCurrentQuestion,
    onBlur,
    onFocus,
    onSubmit,
    response,
    responseIsOptional,
    screenReaderLabel,
    setInputValue,
    setUserIsActive,
    userIsActive,
    type,
  } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    switch (elementInFocus) {
      case TEXT_INPUT:
        inputRef.current.focus();
        break;
      default:
        break;
    }
  }, [elementInFocus]);

  useEffect(() => {
    setInputValue(response);
  }, [response]);

  const getUniqueIdNum = () => {
    return (uniqueIdNum += 1);
  };

  const inputClassName = (styles) => {
    return css(styles.textInput, iconCode && styles.textFieldWithIcon);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value) {
      if (!userIsActive) setUserIsActive(true);
    } else {
      if (userIsActive) setUserIsActive(false);
    }
  };

  const handleTextFieldKeyDown = (event) => {
    const navigationKeys = new Set(['Tab', 'ArrowDown', 'ArrowUp', 'Enter']);

    if (navigationKeys.has(event.key)) {
      event.preventDefault();

      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            focusOnPrevElem();
          } else {
            focusOnNextElem();
          }
          break;
        case 'ArrowDown':
          focusOnNextElem();
          break;
        case 'ArrowUp':
          focusOnPrevElem();
          break;
        case 'Enter':
          if (inputValue && isChildOfCurrentQuestion) {
            onSubmit();
          }
          break;
        default:
          break;
      }
    }
  };

  const calculatedStyles = Object.assign(
    {},
    styles,
    responseIsOptional ? stylesWithSkipBtn : {},
  );

  const uniqueId = `text-field-${getUniqueIdNum()}`; // use the same unique id on label's htmlFor and input's id

  return (
    <div>
      <div className={css(calculatedStyles.textFieldContainer)}>
        <label
          aria-hidden={true}
          className={css(calculatedStyles.hiddenLabel)}
          htmlFor={uniqueId}
        >
          This hidden label prevents autofill in mobile browsers
        </label>
        <Icon iconCode={iconCode} />
        <input
          aria-label={screenReaderLabel}
          autoCapitalize={'off'}
          autoCorrect={'on'}
          autoComplete={'on'}
          className={inputClassName(calculatedStyles)}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          onKeyDown={handleTextFieldKeyDown}
          ref={inputRef}
          id={uniqueId}
          spellCheck={'true'}
          tabIndex={0}
          type={ type}
          value={inputValue}
        />
      </div>
    </div>
  );
};

DesktopTextField.propTypes = {
  elementInFocus: PropTypes.oneOf([TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN]),
  focusOnPrevElem: PropTypes.func.isRequired,
  focusOnNextElem: PropTypes.func.isRequired,
  iconCode: PropTypes.string,
  inputValue: PropTypes.string.isRequired,
  isChildOfCurrentQuestion: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  screenReaderLabel: PropTypes.string,
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responseIsOptional: PropTypes.bool,
  setInputValue: PropTypes.func.isRequired,
  setUserIsActive: PropTypes.func.isRequired,
  showCcpaDisclosure: PropTypes.bool,
  uniqueId: PropTypes.string,
  userIsActive: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
};

DesktopTextField.defaultProps = {
  elementInFocus: TEXT_INPUT,
  inputValue: '',
  onBlur: () => null,
  onFocus: () => null,
  onSkip: () => null,
  responseIsOptional: false,
  screenReaderLabel: 'form field',
  showCcpaDisclosure: false,
};
