import { connect } from 'react-redux';
import { NumericQuestionInput } from './component';
import * as actions from './actions';
import * as pollActions from '../../poll/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInputChange: (number) => {
      dispatch(actions.updateNumber(ownProps.id, number));
    },
    onSubmit: () => {
      dispatch(
        actions.submitNumber(
          ownProps.id,
          ownProps.body,
          ownProps.number.toString()
        )
      );
    },
    onAutoSubmit: () => {
      dispatch(
        actions.autoSubmitNumber(
          ownProps.id,
          ownProps.body,
          ownProps.number.toString()
        )
      );
    },
    onTextFieldFocus: () => {
      if (ownProps.isCurrent) return;
      dispatch(pollActions.setCurrentQuestionById(ownProps.id));
    },
  };
};

export const NumericQuestionContainer = connect(null, mapDispatchToProps)(NumericQuestionInput);
