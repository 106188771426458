const mockAccountEditPoll = {
	"redirect": false,
	"id": "k_rWO3M2",
	"poll_id": "aJDNaB",
	"email_variation_id": 111,
	"poll": {
		"redirect": false,
		"id": "aJDNaB",
		"name": "profile_edit",
		"poll_type": "system",
		"questions": [{
			"id": 1649,
			"name": "profile_edit_address",
			"body": "SYSTEM QUESTION",
			"question_type": "geographical",
			"render_type": "structured_location",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": []
		}, {
			"id": 10,
			"name": "recruit_email",
			"body": "Can we follow up with you for future surveys? Please enter your email address.",
			"question_type": "email",
			"render_type": "email",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": []
		}, {
			"id": 335,
			"name": "name",
			"body": "To ensure accurate results, we would like to check if you are a registered voter. Can you tell us your first and last name?",
			"question_type": "profile",
			"render_type": "text",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": []
		}, {
			"id": 49,
			"name": "birth_year",
			"body": "What year were you born?",
			"question_type": "biographical",
			"render_type": "birth_year",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": []
		}, {
			"id": 60,
			"name": "education",
			"body": "What is the highest level of school you have completed?",
			"question_type": "profile",
			"render_type": "options",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 114,
				"body": "Some high school",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 115,
				"body": "High school graduate",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 116,
				"body": "Some college",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 117,
				"body": "College graduate",
				"order": 4,
				"is_custom_other_choice": false
			}, {
				"id": 118,
				"body": "Post-graduate degree",
				"order": 5,
				"is_custom_other_choice": false
			}, {
				"id": 119,
				"body": "Rather not say/Unsure",
				"order": 6,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 48,
			"name": "gender",
			"body": "So we can best represent you, do you identify as:",
			"question_type": "biographical",
			"render_type": "options",
			"shuffle_choices": 2,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 73,
				"body": "Male",
				"order": 0,
				"is_custom_other_choice": false
			}, {
				"id": 74,
				"body": "Female",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 4361,
				"body": "Non-binary/third gender",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 4362,
				"body": "Other",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 4363,
				"body": "Prefer not to say",
				"order": 4,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 56,
			"name": "income",
			"body": "What category describes your yearly income?",
			"question_type": "profile",
			"render_type": "options",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 102,
				"body": "$0 \u0026ndash; $24,999",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 103,
				"body": "$25,000 \u0026ndash; $49,999",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 104,
				"body": "$50,000 \u0026ndash; $74,999",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 105,
				"body": "$75,000 \u0026ndash; $99,999",
				"order": 4,
				"is_custom_other_choice": false
			}, {
				"id": 106,
				"body": "$100,000 \u0026ndash; $149,999",
				"order": 5,
				"is_custom_other_choice": false
			}, {
				"id": 107,
				"body": "$150,000 +",
				"order": 6,
				"is_custom_other_choice": false
			}, {
				"id": 108,
				"body": "Rather not say/Unsure",
				"order": 7,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 55,
			"name": "party_id",
			"body": "Do you usually think of yourself as a:",
			"question_type": "profile",
			"render_type": "options",
			"shuffle_choices": 3,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 98,
				"body": "Democrat",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 99,
				"body": "Republican",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 100,
				"body": "Independent",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 101,
				"body": "Other or none",
				"order": 4,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 50,
			"name": "race",
			"body": "Which of these best describes your race?",
			"question_type": "biographical",
			"render_type": "options",
			"shuffle_choices": 7,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 75,
				"body": "White",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 76,
				"body": "Black or African-American",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 77,
				"body": "Asian or Asian-American",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 78,
				"body": "Native American/American Indian",
				"order": 4,
				"is_custom_other_choice": false
			}, {
				"id": 79,
				"body": "Pacific Islander",
				"order": 5,
				"is_custom_other_choice": false
			}, {
				"id": 80,
				"body": "Hispanic/Latino",
				"order": 6,
				"is_custom_other_choice": false
			}, {
				"id": 81,
				"body": "Mixed race",
				"order": 7,
				"is_custom_other_choice": false
			}, {
				"id": 82,
				"body": "Some other race",
				"order": 8,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 103,
			"name": "religion",
			"body": "Would you describe yourself as:",
			"question_type": "profile",
			"render_type": "options",
			"shuffle_choices": 8,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 293,
				"body": "Protestant",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 294,
				"body": "Catholic",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 295,
				"body": "Mormon/LDS",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 296,
				"body": "Jewish",
				"order": 4,
				"is_custom_other_choice": false
			}, {
				"id": 297,
				"body": "Muslim",
				"order": 5,
				"is_custom_other_choice": false
			}, {
				"id": 298,
				"body": "Hindu",
				"order": 6,
				"is_custom_other_choice": false
			}, {
				"id": 299,
				"body": "Another religion",
				"order": 7,
				"is_custom_other_choice": false
			}, {
				"id": 300,
				"body": "Atheist or agnostic",
				"order": 8,
				"is_custom_other_choice": false
			}, {
				"id": 301,
				"body": "No religion",
				"order": 9,
				"is_custom_other_choice": false
			}, {
				"id": 302,
				"body": "Unsure",
				"order": 10,
				"is_custom_other_choice": false
			}]
		}, {
			"id": 438,
			"name": "marital_status",
			"body": "Are you currently married?",
			"question_type": "profile",
			"render_type": "options",
			"shuffle_choices": 0,
			"reverse_choices": null,
			"max_choice_count": null,
			"image_url_sm": null,
			"image_url_lg": null,
			"image_alt_tag": null,
			"choices": [{
				"id": 1725,
				"body": "Married",
				"order": 0,
				"is_custom_other_choice": false
			}, {
				"id": 2999,
				"body": "Living with a partner",
				"order": 1,
				"is_custom_other_choice": false
			}, {
				"id": 3000,
				"body": "Never been married",
				"order": 2,
				"is_custom_other_choice": false
			}, {
				"id": 1727,
				"body": "Divorced",
				"order": 3,
				"is_custom_other_choice": false
			}, {
				"id": 3001,
				"body": "Separated",
				"order": 4,
				"is_custom_other_choice": false
			}, {
				"id": 1728,
				"body": "Widowed",
				"order": 5,
				"is_custom_other_choice": false
			}, {
				"id": 1737,
				"body": "Rather not say",
				"order": 6,
				"is_custom_other_choice": false
			}]
		}]
	}
};

export default mockAccountEditPoll;