import _ from 'lodash';
import { mustAnswerToSubmitPollRenderTypes } from './mustAnswerToSubmitPollRenderTypes';

export const viewedAllQuestions = (poll) => {
  if (poll.questions.length <= 0) return false;

  const combinedResponses =
    (poll.responses || []).length + (poll.skips || []).length;
  return combinedResponses >= poll.questions.length;
};

export const pollIsReadyToSubmit = (poll) => {
  if (poll.submitted) return true;

  const allMustAnswerQuestionsAreAnswered = _.every(poll.questions, ({ renderType, isAnswered }) => {
    const isMustAnswer = _.includes(mustAnswerToSubmitPollRenderTypes, renderType)
    if (isMustAnswer && !isAnswered) return false;

    return true;
  });

  if (allMustAnswerQuestionsAreAnswered) return true;

  return false;
};
