import * as actionTypes from './actionTypes';
import { getComponentOffset } from '../../lib/getComponentPos';

export const highlightQuestion = (component) => (dispatch, getState) => {
  const componentOffset = getComponentOffset(component);
  if (componentOffset.bottom === getState().poll.highlight.bottom) return;

  dispatch({
    top: componentOffset.top,
    bottom: componentOffset.bottom,
    type: actionTypes.HIGHLIGHT_QUESTION,
  });
};

export const hideHighlight = () => (dispatch, _) => {
  dispatch({
    type: actionTypes.HIDE_HIGHLIGHT,
  })
};