import React from 'react';
import spinner from '../../../spinner-150px.svg';

export const DeterminingYourLocationModal = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p >Determining your location...</p>
      <embed src={spinner} />
    </div>
  );
};

export const ErrorMsgModal = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <p>We were unable to determine your location.</p>
    </div>
  );
};
