import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as fonts from '../../lib/fonts';
import * as colors from '../../lib/colors';
import { OptionButton } from './OptionButton';
import { OptionBody } from './OptionBody';
import { MobileOptionWrapper } from './MobileOptionWrapper';

const styles = StyleSheet.create({
  optionBody: {
    fontFamily: fonts.TISA,
    color: colors.DARK_BLUE,
    fontSize: '1.1em',
    lineHeight: '1.1em',
  },
  radioButtonContainer: {
    marginBottom: -9,
  },
  textInput: {
    borderColor: colors.LIGHT_GREY,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    fontFamily: fonts.MONTSERRAT,
    fontSize: '1em',
    fontWeight: 200,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

class MobileCustomOption extends Component {
  state = { active: false };

  handleTouchEnd = () => {
    this.setState({ active: false });
  };
  handleTouchStart = () => {
    this.setState({ active: true });
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick(this.props.id);
  };

  render() {
    const {
      body,
      isGreyedOut,
      onChange,
      pending,
      selected,
      shape,
      value,
    } = this.props;

    return (
      <MobileOptionWrapper
        onTouchEnd={this.handleTouchEnd}
        onTouchStart={this.handleTouchStart}
      >
        <div onClick={this.handleClick}>
          <OptionBody
            className={css(styles.optionBody)}
            isGreyedOut={isGreyedOut}
            text={body + ':'}
          />
        </div>
        <input
          className={css(styles.textInput)}
          onChange={onChange}
          placeholder={'ex: ' + body.toLowerCase()}
          value={value}
        />
        <div
          className={css(styles.radioButtonContainer)}
          onClick={this.handleClick}
        >
          <OptionButton
            isActive={this.state.active || pending}
            isChecked={selected}
            shape={shape}
          />
        </div>
      </MobileOptionWrapper>
    );
  }
}

MobileCustomOption.propTypes = {
  body: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isGreyedOut: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  selected: PropTypes.bool,
  shape: PropTypes.string,
  value: PropTypes.string,
};

MobileCustomOption.defaultProps = {
  pending: false,
  selected: false,
  value: '',
};

export { MobileCustomOption };
