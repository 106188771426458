import PropTypes from 'prop-types';
import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { BLUE } from '../../lib/colors';
import { ViewportContext } from '../../viewportContext';
import { Button } from '../sharedComponents/Button';
import { ErrorMsg } from '../sharedComponents/ErrorMsg';
import { CcpaDisclosure } from './CcpaDisclosure';
import { useUniqueId } from '../../hooks/useUniqueId';

let styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '72%',
    float: 'left',
    marginRight: '0.75em',
  },
  textInput: {
    height: 16 + 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 3,
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderTop: 'none',
    borderBottom: '1px solid #DDD',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
    WebkitAppearance: 'none',
  },
  button: {
    width: '50%',
    float: 'left',
    marginBottom: '0.75em',
    paddingRight: '0.37em',
  },
  submitButtonContainer: {
    width: '24%',
    float: 'left',
    marginBottom: '0.75em',
  },
  textFieldWithIcon: {
    paddingLeft: 40,
  },
  hiddenLabel: {
    position: 'absolute',
    top: -999999,
    right: -999999,
  },
  iconCode: {
    color: BLUE,
    fontSize: 30,
    position: 'absolute',
    left: 6,
    top: 5,
  },
});

const stylesWithSkipBtn = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '50%',
    float: 'left',
    paddingRight: '0.75em',
  },
  skipButton: {
    paddingRight: 0,
    paddingLeft: '0.37em',
  },
  buttonsContainer: {
    width: '50%',
    margin: 0,
    padding: 0,
    float: 'left',
  },
});

const Icon = ({ iconCode }) => {
  return iconCode ? (
    <span
      data-icon={iconCode}
      aria-hidden="true"
      className={css(styles.iconCode)}
    />
  ) : null;
};

const DesktopEmailTextInput = (props) => {
  const {
    isAnswered,
    isLargeText,
    errorMsg,
    iconCode,
    isFocus,
    label,
    onFocus,
    onKeyDown,
    onInputChange,
    onSkip,
    onSubmit,
    renderSuggestion,
    response,
    responseIsOptional,
    screenReaderLabel,
    showCcpaDisclosure,
    useAutoCapitalize,
    useAutoCorrect,
    type,
  } = props;

  const inputRef = useRef(null);
  const submitBtnRef = useRef(null);
  const skipBtnRef = useRef(null);
  const viewport = useContext(ViewportContext);
  const [submitBtnIsActive, setSubmitBtnIsActive] = useState(false);
  const [skipBtnIsActive, setSkipBtnIsActive] = useState(false);
  const [autoSubmitIsAllowed, setAutoSubmitIsAllowed] = useState(errorMsg === '');

  useEffect(() => {
    if (isFocus) inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (isFocus) inputRef.current.focus();
  }, [isFocus]);

  useEffect(() => {
    inputRef.current.value = response;
  }, [response]);

  useEffect(() => {
    if (inputRef.current.value === '') {
      setSubmitBtnIsActive(false);
    } else {
      setSubmitBtnIsActive(true);
    }
  });

  useEffect(() => {
    if (isAnswered) {
      removeFalseClickEventListener();
    } else {
      addFalseClickEventListener();
    }

    return () => {
      removeFalseClickEventListener();
    }
  }, [isAnswered]);

  const handleBlur = () => {
    // if autosub is allowed, // the question is not already answered and the
    // user typed something in the text field let them click away
    if (autoSubmitIsAllowed && !isAnswered && inputRef.current.value !== '') {
      onSubmit();
      inputRef.current.focus();
      setAutoSubmitIsAllowed(false);
    // else remove the false click handler
    } else {
      removeFalseClickEventListener();
    }
  };

  // prevents user from clicking away from text input
  const addFalseClickEventListener = () => {
    document.body.addEventListener('click', preventAllClickHandlersFromFiring, true);
  };

  // reseting click events to non-hijacked state effectively allows the users click away to function as expected
  const removeFalseClickEventListener = () => {
    document.body.removeEventListener('click', preventAllClickHandlersFromFiring, true);
  };

  const preventAllClickHandlersFromFiring = useCallback((e) => {
    if (inputRef.current.value !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const handleSubmit = () => {
    onSubmit();
    setAutoSubmitIsAllowed(false);
  };

  const handleChange = () => {
    onInputChange(inputRef.current.value);
  };

  const handleInputKeyDown = (event) => {
    const navigationKeys = new Set(['Tab', 'ArrowDown', 'ArrowUp', 'Enter']);

    if (navigationKeys.has(event.key)) {
      event.preventDefault();
      setAutoSubmitIsAllowed(false);

      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            if (responseIsOptional) {
              skipBtnRef.current.focus();
            } else {
              submitBtnRef.current.focus();
            }
          } else {
            submitBtnRef.current.focus();
          }
          break;
        case 'ArrowDown':
          submitBtnRef.current.focus();
          break;
        case 'ArrowUp':
          if (responseIsOptional) {
            skipBtnRef.current.focus();
          } else {
            submitBtnRef.current.focus();
          }
          break;
        case 'Enter':
          if (inputRef.current.value) handleSubmit();
          break;
        default:
          break;
      }
    } else {
      onKeyDown(event);
    }
  };

  const handleMouseEnterContainer = () => {
    setAutoSubmitIsAllowed(false);
  };

  const handleMouseLeaveContainer = () => {
    setAutoSubmitIsAllowed(true);
  };

  const handleSubmitBtnKeyDown = (event) => {
    event.preventDefault();

    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          inputRef.current.focus();
          break;
        }
      /* falls through */
      case 'ArrowDown':
        if (responseIsOptional) {
          skipBtnRef.current.focus();
        } else {
          inputRef.current.focus();
        }
        break;
      case 'ArrowUp':
        inputRef.current.focus();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        handleSubmit();
        break;

      default:
        break;
    }
  };

  const handleSkipBtnKeyDown = (event) => {
    event.preventDefault();
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          submitBtnRef.current.focus();
          break;
        }
      /* falls through */
      case 'ArrowDown':
        inputRef.current.focus();
        break;
      case 'ArrowUp':
        submitBtnRef.current.focus();
        break;
      case 'Enter':
        onSkip();
        break;
      default:
        break;
    }
  };

  const uniqueTextFieldId = `text-field-${useUniqueId()}`;

  const calculatedStyles = {
    ...styles,
    ...(responseIsOptional? stylesWithSkipBtn: {})
  };

  const inputClassName = css(calculatedStyles.textInput, iconCode && calculatedStyles.textFieldWithIcon);

  const defaultTextFieldProps = {
    'aria-label': screenReaderLabel,
    autoCapitalize: useAutoCapitalize ? 'on' : 'off',
    autoComplete: 'on',
    autoCorrect: useAutoCorrect ? 'on' : 'off',
    className: inputClassName,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: onFocus,
    onKeyDown: handleInputKeyDown,
    ref: inputRef,
    id: uniqueTextFieldId,
    spellCheck: 'true',
    tabIndex: 0,
    type,
  };

  return (
    <div
      onMouseEnter={handleMouseEnterContainer}
      onMouseLeave={handleMouseLeaveContainer}
    >
      <div
        className={css(
          calculatedStyles.textFieldContainer
        )}
      >
        <label
          aria-hidden={true}
          className={css(calculatedStyles.hiddenLabel)}
          htmlFor={uniqueTextFieldId}
        >
          This hidden label prevents autofill in mobile browsers
        </label>
        <Icon iconCode={iconCode} />
        {isLargeText ? (
          <textarea
            {...defaultTextFieldProps}
            size={3}
            maxLength={1000}
          />
        ) : (
          <input {...defaultTextFieldProps} />
        )}
        {renderSuggestion()}
        <ErrorMsg errorMsg={errorMsg} />
        {showCcpaDisclosure ? <CcpaDisclosure viewport={viewport} /> : null}
      </div>
      {
        responseIsOptional === true ? (
          <div
            className={css(
              calculatedStyles.buttonsContainer
            )}
          >
            <div
              className={css(
                calculatedStyles.button
              )}
            >
              <Button
                buttonRef={submitBtnRef}
                isActive={submitBtnIsActive}
                label={label}
                onClick={handleSubmit}
                onKeyDown={handleSubmitBtnKeyDown}
                tabIndex={0}
                viewport={viewport}
              />
            </div>
            <div
              className={css(
                calculatedStyles.button,
                calculatedStyles.skipButton
              )}
              onBlur={() => {
                setSkipBtnIsActive(false);
              }}
              onFocus={() => {
                setSkipBtnIsActive(true);
              }}
              onMouseEnter={() => {
                if (document.activeElement === skipBtnRef.current) return;
                setSkipBtnIsActive(true);
              }}
              onMouseLeave={() => {
                if (document.activeElement === skipBtnRef.current) return;
                setSkipBtnIsActive(false);
              }}
            >
              <Button
                buttonRef={skipBtnRef}
                isActive={skipBtnIsActive}
                label={'Skip Question'}
                onClick={onSkip}
                onKeyDown={handleSkipBtnKeyDown}
                tabIndex={0}
                viewport={viewport}
              />
            </div>
          </div>
        ) : (
          <div
            className={css(
              calculatedStyles.submitButtonContainer,
            )}
          >
            <Button
              buttonRef={submitBtnRef}
              isActive={submitBtnIsActive}
              label={label}
              onClick={handleSubmit}
              onKeyDown={handleSubmitBtnKeyDown}
              tabIndex={0}
              viewport={viewport}
            />
          </div>
        )
      }
    </div>
  );
};

DesktopEmailTextInput.propTypes = {
  errorMsg: PropTypes.string,
  isAnswered: PropTypes.bool.isRequired,
  iconCode: PropTypes.string,
  isFocus: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  screenReaderLabel: PropTypes.string,
  renderSuggestion: PropTypes.func,
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responseIsOptional: PropTypes.bool.isRequired,
  showCcpaDisclosure: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

DesktopEmailTextInput.defaultProps = {
  isFocus: true,
  onFocus: () => null,
  onKeyDown: () => null,
  renderSuggestion: () => null,
  responseIsOptional: false,
  screenReaderLabel: 'form field',
  showCcpaDisclosure: false,
};

export { DesktopEmailTextInput };