import { connect } from 'react-redux';
import { find } from 'lodash';
import { MultipleOptionsInput } from './component';
import * as pollActions from '../../poll/actions';
import * as actions from './actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onOptionClick: (id) => {
      const clickedOption = find(ownProps.choices, (c) => c.id === id);
      if (clickedOption.selected) {
        dispatch(actions.uncheckOption(ownProps.id, clickedOption.id));
      } else {
        dispatch(actions.checkOption(ownProps.id, clickedOption.id));
      }
      // Ensure that on mobile, if a user selects an option, the associated
      // question becomes current (the submit buttons gets activated)
      // TODO: This is hacky
      dispatch(pollActions.setCurrentQuestionById(ownProps.id));
    },

    onSubmit: () => {
      dispatch(
        actions.saveMultiOptionResponse(
          ownProps.id,
          ownProps.body,
          ownProps.choices
        )
      );
    },

    initOptionOrder: () => {
      if (ownProps.shuffleChoices > 0) {
        dispatch(actions.shuffleOptions(ownProps.id));
      } else if (ownProps.reverseChoices > 0) {
        dispatch(actions.reverseOptions(ownProps.id));
      }
    },
  };
};

export const MultipleOptionsQuestionContainer = connect(null, mapDispatchToProps)(MultipleOptionsInput);
