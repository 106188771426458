import React from 'react';
import { Title } from './sharedComponents/layoutHelpers';
import LandingPage from './sharedComponents/LandingPage'

const ResubConfirmation = () => {
  return (
      <LandingPage
        bannerContent={
          <div>
          <Title>Thank you!</Title>
          <Title>You are now confirmed to receive future surveys from Civiqs.</Title>
          </div>
        }
    />
  );
};

export { ResubConfirmation };
