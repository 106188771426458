import PropTypes from 'prop-types';
import React from 'react';
import { TISA } from '../../lib/fonts';

const TextAnswer = (props) => {
  return (
    <div
      style={{
      paddingRight: '1em',
      }}
    >
      <span
        style={{
        fontFamily: TISA,
        fontSize: '1em',
        overflowWrap: 'break-word',
        }}
      >
        {props.children}
      </span>
    </div>
    
  );
};

TextAnswer.propTypes = {
  children: PropTypes.node,
};

export { TextAnswer };
