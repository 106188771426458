import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { BLUE } from '../../lib/colors';
import { Button } from '../sharedComponents/Button';
import { MOBILE } from '../../lib/constants';

let styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    marginRight: '0.75em',
    width: '100%',
    float: 'none',
  },
  textInput: {
    height: 16 + 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 3,
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderTop: 'none',
    borderBottom: '1px solid #DDD',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
    WebkitAppearance: 'none',
  },
  button: {
    width: '50%',
    float: 'left',
    marginBottom: '0.75em',
    paddingRight: '0.37em',
  },
  submitButtonContainer: {
    marginBottom: '0.75em',
    width: '100%',
    float: 'none',
  },
  textFieldWithIcon: {
    paddingLeft: 40,
  },
  hiddenLabel: {
    position: 'absolute',
    top: -999999,
    right: -999999,
  },
  iconCode: {
    color: BLUE,
    fontSize: 30,
    position: 'absolute',
    left: 6,
    top: 5,
  },
});

const stylesWithSkipBtn = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    width: '100%',
    float: 'none',
    paddingRight: 0,
  },
  skipButton: {
    paddingRight: 0,
    paddingLeft: '0.37em',
  },
  buttonsContainer: {
    margin: 0,
    padding: 0,
    float: 'left',
    width: '100%',
  },
});

const Icon = ({ iconCode }) => {
  return iconCode ? (
    <span
      data-icon={iconCode}
      aria-hidden="true"
      className={css(styles.iconCode)}
    />
  ) : null;
};

let uniqueIdNum = 0;

export const MobileTextField = (props) => {
  const {
    iconCode,
    inputValue,
    isFocus,
    label,
    onSkip,
    onSubmit,
    response,
    responseIsOptional,
    screenReaderLabel,
    setInputValue,
    type,
  } = props;

  const inputRef = useRef(null);
  const submitBtnRef = useRef(null);
  const skipBtnRef = useRef(null);
  const [skipBtnIsActive, setSkipBtnIsActive] = useState(false);

  useEffect(() => {
    setInputValue(response);
  }, [response]);

  useEffect(() => {
    if (isFocus) inputRef.current.focus();
  }, [isFocus])

  const getUniqueIdNum = () => {
    return (uniqueIdNum += 1);
  };

  const inputClassName = (styles) => {
    return css(styles.textInput, iconCode && styles.textFieldWithIcon);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const calculatedStyles = Object.assign(
    {},
    styles,
    responseIsOptional ? stylesWithSkipBtn : {},
  );

  const uniqueId = `text-field-${getUniqueIdNum()}`; // use the same unique id on label's htmlFor and input's id

  const renderSubmitAndSkipButtons = ({
    calculatedStyles,
    isActive,
    label,
    onSkip,
    onSubmit,
    responseIsOptional,
  }) => {
    return (responseIsOptional) ? (
      <div className={css(calculatedStyles.buttonsContainer )}>
        <div className={css(calculatedStyles.button)}>
          <Button
            buttonRef={submitBtnRef}
            isActive={isActive}
            label={label}
            onClick={() => {
              onSubmit();
            }}
            tabIndex={0}
            viewport={MOBILE}
          />
        </div>
        <div
          className={css(
            calculatedStyles.button,
            calculatedStyles.skipButton,
          )}
        >
          <Button
            buttonRef={skipBtnRef}
            isActive={skipBtnIsActive}
            label={'Skip'}
            onClick={onSkip}
            tabIndex={0}
            viewport={MOBILE}
          />
        </div>
      </div>
    ) : (
      <div className={css(calculatedStyles.submitButtonContainer)}>
        <Button
          buttonRef={submitBtnRef}
          isActive={isActive}
          label={label}
          onClick={() => {
            onSubmit();
          }}
          tabIndex={0}
          viewport={MOBILE}
        />
      </div>
    );
  };

  return (
    <div>
      <div className={css(calculatedStyles.textFieldContainer)}>
        <label
          aria-hidden={true}
          className={css(calculatedStyles.hiddenLabel)}
          htmlFor={uniqueId}
        >
          This hidden label prevents autofill in mobile browsers
        </label>
        <Icon iconCode={iconCode} />
        <input
          aria-label={screenReaderLabel}
          autoCapitalize={'off'}
          autoCorrect={'on'}
          autoComplete={'on'}
          className={inputClassName(calculatedStyles)}
          onChange={handleChange}
          ref={inputRef}
          id={uniqueId}
          spellCheck={'true'}
          type={type}
          value={inputValue}
        />
      </div>
      {renderSubmitAndSkipButtons({
        calculatedStyles,
        isActive: !!inputValue,
        label,
        onSkip,
        onSubmit,
        responseIsOptional,
      })}
    </div>
  );
};

MobileTextField.propTypes = {
  iconCode: PropTypes.string,
  isFocus: PropTypes.bool,
  inputValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  screenReaderLabel: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
  setUserIsActive: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responseIsOptional: PropTypes.bool,
  uniqueId: PropTypes.string,
  userIsActive: PropTypes.bool.isRequired,
};

MobileTextField.defaultProps = {
  inputValue: '',
  isFocus: true,
  onChange: () => null,
  onSkip: () => null,
  renderSuggestion: () => null,
  responseIsOptional: false,
  screenReaderLabel: 'form field',
};
