import { connect } from 'react-redux';
import { BirthYearQuestionInput } from './component';
import * as actions from './actions';
import * as pollActions from '../../poll/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInputChange: (year) => {
      dispatch(actions.updateYear(ownProps.id, year));
    },
    onSubmit: () => {
      dispatch(
        actions.submitYear(ownProps.id, ownProps.body, ownProps.year.toString())
      );
    },
    onAutoSubmit: () => {
      dispatch(
        actions.autoSubmitYear(
          ownProps.id,
          ownProps.body,
          ownProps.year.toString()
        )
      );
    },
    onTextFieldFocus: () => {
      if (ownProps.isCurrent) return;
      dispatch(pollActions.setCurrentQuestionById(ownProps.id));
    },
  };
};

export const BirthYearQuestionContainer = connect(null, mapDispatchToProps)(BirthYearQuestionInput);

