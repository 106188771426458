import { throttle } from './lib/throttle';
import { DESKTOP, MOBILE, TABLET } from './lib/constants';
import React, { createContext, useEffect, useState } from 'react';
import { userDeviceHasTouchScreen } from './lib/userDeviceHasTouchScreen';

const mobileMaxWidth = 500;
const tabletMaxWidth = 1000;

const determineLayoutType = (_window) => {
  if (userDeviceHasTouchScreen(_window) || _window.screenX <= 0) {
    if (_window.innerWidth <= mobileMaxWidth) {
      return MOBILE;
    } else if (_window.innerWidth <= tabletMaxWidth) {
      return TABLET;
    } else {
      return DESKTOP;
    }
  } else {
    return DESKTOP;
  }
};

export const ViewportContext = createContext();

export const ViewportContextProvider = ({ _window, children }) => {
  const layoutTypeOnLoad = determineLayoutType(_window);
  const [layoutType, setLayoutType] = useState(layoutTypeOnLoad);

  const handleResize = () => {
    const updatedLayoutType = determineLayoutType(_window);

    setLayoutType(updatedLayoutType);
  };

  const throttledResizeHandler = throttle(handleResize, 100);

  useEffect(() => {
    _window.addEventListener('resize', throttledResizeHandler);

    return () => {
      _window.removeEventListener('resize', throttledResizeHandler);
    };
  }, []);

  return (
    <ViewportContext.Provider value={layoutType}>
      {children}
    </ViewportContext.Provider>
  );
};
