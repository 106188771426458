import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { BLUE } from '../../lib/colors';
import { ViewportContext } from '../../viewportContext';
import { Button } from '../sharedComponents/Button';
import { ErrorMsg } from '../sharedComponents/ErrorMsg';
import { CcpaDisclosure } from './CcpaDisclosure';

let styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    marginRight: '0.75em',
    width: '100%',
    float: 'none',
  },
  textInput: {
    height: 16 + 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 3,
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderTop: 'none',
    borderBottom: '1px solid #DDD',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
    WebkitAppearance: 'none',
  },
  button: {
    width: '50%',
    float: 'left',
    marginBottom: '0.75em',
    paddingRight: '0.37em',
  },
  submitButtonContainer: {
    marginBottom: '0.75em',
    width: '100%',
    float: 'none',
  },
  textFieldWithIcon: {
    paddingLeft: 40,
  },
  hiddenLabel: {
    position: 'absolute',
    top: -999999,
    right: -999999,
  },
  iconCode: {
    color: BLUE,
    fontSize: 30,
    position: 'absolute',
    left: 6,
    top: 5,
  },
});

const stylesWithSkipBtn = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    marginBottom: '0.75em',
    paddingRight: '0.75em',
    width: '100%',
    float: 'none',
    paddingRight: 0,
  },
  skipButton: {
    paddingRight: 0,
    paddingLeft: '0.37em',
  },
  buttonsContainer: {
    margin: 0,
    padding: 0,
    float: 'left',
    width: '100%',
  },
});

const Icon = ({ iconCode }) => {
  return iconCode ? (
    <span
      data-icon={iconCode}
      aria-hidden="true"
      className={css(styles.iconCode)}
    />
  ) : null;
};

let uniqueIdNum = 0;

const MobileEmailTextInput = (props) => {
  const {
    isLargeText,
    errorMsg,
    iconCode,
    label,
    onInputChange,
    onSkip,
    onSubmit,
    renderSuggestion,
    response,
    responseIsOptional,
    screenReaderLabel,
    showCcpaDisclosure,
    useAutoCapitalize,
    useAutoCorrect,
    type,
  } = props;

  const inputRef = useRef(null);
  const submitBtnRef = useRef(null);
  const skipBtnRef = useRef(null);
  const [submitBtnIsActive, setSubmitBtnIsActive] = useState(false);
  const viewport = useContext(ViewportContext);

  useEffect(() => {
    inputRef.current.value = response;
  }, [response]);

  useEffect(() => {
    if (inputRef.current.value === '') {
      setSubmitBtnIsActive(false);
    } else {
      setSubmitBtnIsActive(true);
    }
  });

  const getUniqueIdNum = () => {
    return (uniqueIdNum += 1);
  };

  const inputClassName = (styles) => {
    return css(styles.textInput, iconCode && styles.textFieldWithIcon);
  };

  const handleChange = () => {
    onInputChange(inputRef.current.value);
  };

  const calculatedStyles = Object.assign(
    {},
    styles,
    responseIsOptional ? stylesWithSkipBtn : {},
  );

  const uniqueId = `text-field-${getUniqueIdNum()}`; // use the same unique id on label's htmlFor and input's id

  const defaultTextFieldProps = {
    'aria-label': screenReaderLabel,
    autoCapitalize: useAutoCapitalize ? 'on' : 'off',
    autoComplete: 'on',
    autoCorrect: useAutoCorrect ? 'on' : 'off',
    className: inputClassName(calculatedStyles),
    onChange: handleChange,
    ref: inputRef,
    id: uniqueId,
    spellCheck: 'true',
    tabIndex: 0,
    type,
  };

  return (
    <div>
      <div
        className={css(
          calculatedStyles.textFieldContainer
        )}
      >
        <label
          aria-hidden={true}
          className={css(calculatedStyles.hiddenLabel)}
          htmlFor={uniqueId}
        >
          This hidden label prevents autofill in mobile browsers
        </label>
        <Icon iconCode={iconCode} />
        {isLargeText ? (
          <textarea
            {...defaultTextFieldProps}
            size={3}
            maxLength={1000}
          />
        ) : (
          <input {...defaultTextFieldProps} />
        )}
        {renderSuggestion()}
        <ErrorMsg errorMsg={errorMsg} />
        {showCcpaDisclosure ? <CcpaDisclosure viewport={viewport} /> : null}
      </div>
      {
        responseIsOptional === true ? (
          <div
            className={css(
              calculatedStyles.buttonsContainer
            )}
          >
            <div
              className={css(
                calculatedStyles.button
              )}
            >
              <Button
                buttonRef={submitBtnRef}
                isActive={submitBtnIsActive}
                label={label}
                onClick={onSubmit}
                tabIndex={0}
                viewport={viewport}
              />
            </div>
            <div
              className={css(
                calculatedStyles.button,
                calculatedStyles.skipButton
              )}
            >
              <Button
                buttonRef={skipBtnRef}
                isActive={true}
                label={'Skip'}
                onClick={onSkip}
                tabIndex={0}
                viewport={viewport}
              />
            </div>
          </div>
        ) : (
          <div
            className={css(
              calculatedStyles.submitButtonContainer
            )}
          >
            <Button
              buttonRef={submitBtnRef}
              isActive={submitBtnIsActive}
              label={label}
              onClick={onSubmit}
              tabIndex={0}
              viewport={viewport}
            />
          </div>
        )
      }
    </div>
  );
};

MobileEmailTextInput.propTypes = {
  errorMsg: PropTypes.string,
  iconCode: PropTypes.string,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  screenReaderLabel: PropTypes.string,
  renderSuggestion: PropTypes.func,
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responseIsOptional: PropTypes.bool.isRequired,
  showCcpaDisclosure: PropTypes.bool,
  uniqueId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

MobileEmailTextInput.defaultProps = {
  renderSuggestion: () => null,
  responseIsOptional: false,
  screenReaderLabel: 'form field',
  showCcpaDisclosure: false,
};

export { MobileEmailTextInput };
