import React, { Component } from 'react';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { Button, Flex, Text } from '@rebass/emotion';
import { BLUE, DARK_BLUE, MEDIUM_GREY, WHITE } from '../lib/colors';
import { TISA, MONTSERRAT } from '../lib/fonts';
import { stateAbbreviations } from '../lib/stateAbbreviations';
import { Form } from './Form';
import { Column, Row } from '../sharedComponents/layoutHelpers';
import TextInput from '../sharedComponents/formHelpers/TextInput';
import Dropdown from '../sharedComponents/formHelpers/Dropdown';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    color: MEDIUM_GREY,
    fontFamily: TISA,
    fontSize: '0.8em',
    fontWeight: 300,
    fontFamily: MONTSERRAT,
    width: '100%',
  },
  banner: {
    backgroundColor: DARK_BLUE, //'rgb(42, 53, 70)',
    minHeight: '220px',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
  },
  pageTitle: {
    color: WHITE,
    fontWeight: 400,
    fontSize: '2em',
  },
  gridContainer: {
    maxWidth: 840,
    margin: '0 auto',
    padding: 25,
  },
  formContainer: {
    paddingTop: 25,
  },
  submitButton: {
    backgroundColor: BLUE,
    borderRadius: 5,
    color: WHITE,
  },
});

class AccountEditForm extends Component {
  componentDidMount() {
    this.props.allowMainContentOverflow();
  }

  render() {
    const {
      onSaveAddress,
      onSaveCity,
      onSaveOptionsQuestion,
      onSaveState,
      onSaveTextAnswer,
      onSaveZipcode,
      onSubmitCompletedPoll,
      questions,
    } = this.props;

    if (questions.length <= 0) return null;

    const getQuestionTypeByName = (name) => {
      const types = {
        birth_year: 'birthYear',
        education: 'education',
        gender: 'gender',
        income: 'income',
        marital_status: 'maritalStatus',
        name: 'name',
        party_id: 'party',
        profile_edit_address: 'address',
        race: 'race',
        recruit_email: 'email',
        religion: 'religion',
      };

      return types[name];
    };

    const questionTypes = {};
    questions.forEach((q) => {
      q.uniqueIdString = `question_input_${q.id}`;
      questionTypes[getQuestionTypeByName(q.name)] = q;
    });

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.banner)}>
          <h3 className={css(styles.pageTitle)}>Account Management</h3>
        </div>
        <div className={css(styles.gridContainer)}>
          <Text fontSize={[1, 2]} mb={14}>
            Thank you for updating your contact information. Civiqs uses this
            information to ensure that our surveys accurately represent all
            Americans. We will never send you advertisements or sales
            solicitations.
          </Text>
          <Text fontSize={[1, 2]}>
            Entering your information is optional and does not affect the polls
            you receive. Civiqs uses your name to check voter registration
            status.
          </Text>
          <div className={css(styles.formContainer)}>
            <Form
              onSaveOptionsQuestion={onSaveOptionsQuestion}
              onSaveAddress={onSaveAddress}
              onSaveTextAnswer={onSaveTextAnswer}
              onSaveCity={onSaveCity}
              onSaveState={onSaveState}
              onSaveZipcode={onSaveZipcode}
              render={({
                formState,
                formIsValidForSubmission,
                onBlur,
                handleTextFieldChange,
                handleDropdownChange,
                onFocus,
                showInvalidModals,
              }) => {
                return (
                  <form name="account_edit_form">
                    <Flex
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                      flexDirection={'row'}
                      flexWrap={'wrap'}
                    >
                      <Row>
                        <TextInput
                          data-state-object-key="name"
                          id={questionTypes['name'].uniqueIdString}
                          isValid={true}
                          label="Name"
                          labelIsHidden={true}
                          name={questionTypes['name'].id}
                          onBlur={onBlur}
                          onChange={handleTextFieldChange}
                          onFocus={onFocus}
                          type="text"
                          value={formState.name}
                        />
                      </Row>
                      <Row>
                        <Column>
                          <TextInput
                            data-state-object-key="email"
                            id={questionTypes['email'].uniqueIdString}
                            invalidInputMessage={'Please enter a valid email.'}
                            isValid={formState.emailIsValid}
                            label="Email"
                            labelIsHidden={true}
                            name={questionTypes['email'].id}
                            onBlur={onBlur}
                            onChange={handleTextFieldChange}
                            onFocus={onFocus}
                            showModal={formState.showEmailModal}
                            type="email"
                            value={formState.email}
                          />
                        </Column>
                        <Column>
                          <TextInput
                            data-state-object-key="emailConfirmation"
                            invalidInputMessage={
                              'Your confirmation email does not match.'
                            }
                            isValid={formState.emailConfirmationMatches}
                            label="Confirm Email"
                            labelIsHidden={true}
                            onBlur={onBlur}
                            onChange={handleTextFieldChange}
                            onFocus={onFocus}
                            showModal={formState.showEmailConfirmationModal}
                            type="email"
                            value={formState.emailConfirmation}
                          />
                        </Column>
                      </Row>
                      <Text fontSize={[1, 2]} mt={20} mb={10}>
                        Home Address
                      </Text>
                      <Row>
                        <TextInput
                          data-state-object-key={'address'}
                          id={`question_input_${questionTypes['address'].id}_address`}
                          isValid={true}
                          label={'Street Address'}
                          labelIsHidden={true}
                          name={questionTypes['address'].id}
                          onBlur={onBlur}
                          onChange={handleTextFieldChange}
                          onFocus={onFocus}
                          type={'text'}
                        />
                      </Row>
                      <Row>
                        <TextInput
                          data-state-object-key={'city'}
                          id={`question_input_${questionTypes['address'].id}_city`}
                          isValid={true}
                          label={'City'}
                          labelIsHidden={true}
                          name={questionTypes['address'].id}
                          onBlur={onBlur}
                          onChange={handleTextFieldChange}
                          onFocus={onFocus}
                          type={'text'}
                        />
                      </Row>
                      <Row>
                        <Column>
                          <Dropdown
                            id={`question_input_${questionTypes['address'].id}_state`}
                            isValid={true}
                            label={'State'}
                            labelIsHidden={true}
                            name={questionTypes['address'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('state', value)
                            }}
                            onFocus={onFocus}
                            options={stateAbbreviations.map((abbreviation) => ({
                              label: abbreviation,
                              value: abbreviation.toLowerCase(),
                            }))}
                            onSave={onSaveState}
                          />
                        </Column>
                        <Column>
                          <TextInput
                            data-state-object-key={'zipcode'}
                            id={`question_input_${questionTypes['address'].id}_zip`}
                            invalidInputMessage={
                              'Please enter a valid zipcode.'
                            }
                            isValid={formState.zipcodeIsValid}
                            label={'Zipcode'}
                            labelIsHidden={true}
                            name={questionTypes['address'].id}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={handleTextFieldChange}
                            showModal={formState.showZipcodeModal}
                            type={'text'}
                          />
                        </Column>
                      </Row>
                      <Text fontSize={[1, 2]} mt={20} mb={10}>
                        About Me
                      </Text>
                      <Row>
                        <Column>
                          <TextInput
                            data-state-object-key={'birthYear'}
                            id={questionTypes['birthYear'].uniqueIdString}
                            invalidInputMessage={
                              "Please enter a valid  4 digit bith year (e.g. '1978')"
                            }
                            isValid={formState.birthYearIsValid}
                            label={'Birth Year'}
                            labelIsHidden={true}
                            name={questionTypes['birthYear'].id}
                            min="1900"
                            max={new Date().getFullYear()}
                            maxLength={4}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={handleTextFieldChange}
                            showModal={formState.showBirthYearModal}
                            type={'number'}
                            value={formState.birthYear}
                          />
                        </Column>
                        <Column>
                          <Dropdown
                            id={questionTypes['gender'].uniqueIdString}
                            label={'Gender'}
                            labelIsHidden={true}
                            name={questionTypes['gender'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('gender', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['gender'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Dropdown
                            id={questionTypes['race'].uniqueIdString}
                            label={'Race'}
                            labelIsHidden={true}
                            name={questionTypes['race'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('race', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['race'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                        <Column>
                          <Dropdown
                            id={questionTypes['education'].uniqueIdString}
                            label={'Education'}
                            labelIsHidden={true}
                            name={questionTypes['education'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('education', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['education'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Dropdown
                            id={questionTypes['income'].uniqueIdString}
                            label={'Income'}
                            labelIsHidden={true}
                            name={questionTypes['income'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('income', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['income'].choices.map(
                              (choice) => ({
                                label: choice.body.replace(/&ndash;/g, '–'),
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                        <Column>
                          <Dropdown
                            id={questionTypes['religion'].uniqueIdString}
                            label={'Religion'}
                            labelIsHidden={true}
                            name={questionTypes['religion'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('religion', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['religion'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Dropdown
                            id={questionTypes['party'].uniqueIdString}
                            label={'Party'}
                            labelIsHidden={true}
                            name={questionTypes['party'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('party', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['party'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                        <Column>
                          <Dropdown
                            id={questionTypes['maritalStatus'].uniqueIdString}
                            label={'Marital Status'}
                            labelIsHidden={true}
                            name={questionTypes['maritalStatus'].id}
                            onBlur={onBlur}
                            onChange={(value) => {
                              handleDropdownChange('maritalStatus', value)
                            }}
                            onFocus={onFocus}
                            options={questionTypes['maritalStatus'].choices.map(
                              (choice) => ({
                                label: choice.body,
                                value: choice.id,
                              })
                            )}
                            onSave={onSaveOptionsQuestion}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Button
                          bg={
                            formIsValidForSubmission()
                              ? 'rgb(13, 130, 223)'
                              : MEDIUM_GREY
                          }
                          borderRadius={4}
                          border={'none'}
                          fontWeight={700}
                          onClick={(e) => {
                            e.preventDefault();
                            if (formIsValidForSubmission()) {
                              onSubmitCompletedPoll();
                            } else {
                              showInvalidModals();
                            }
                          }}
                          width={1}
                        >
                          Submit
                        </Button>
                      </Row>
                    </Flex>
                  </form>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { AccountEditForm };
