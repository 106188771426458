import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Flex } from '../../sharedComponents/layoutHelpers';
import { NAV_HEIGHT } from '../../lib/constants';
import { mobileDeviceIsSmall } from '../../lib/mobileDeviceIsSmall';
import { getComponentPos } from '../../lib/getComponentPos';
import { ULTRALIGHT_GREY } from '../../lib/colors';
import spinner from '../../spinner-150px.svg';
import { Instruction } from '../sharedComponents/Instruction';
import { Button } from '../sharedComponents/Button';
import { googleMaps } from './lib/GoogleMaps';

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  map: {
    height: 220,
    marginBottom: 36,
    backgroundColor: ULTRALIGHT_GREY,
    backgroundImage: `url('${spinner}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
  },
  confirmationCopy: {
    marginTop: 10,
    marginBottom: 10,
  },
  confirmationButtonsContainer: {
    width: '100%',
  },
  confirmationButton: {
    width: '49%',
  },
});

export const MobileMap = (props) => {
  const {
    googleMapsResult,
    mapElemId,
    onAcceptMappedLocation,
    onRejectMappedLocation,
  } = props;

  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const yesBtnForwardedRef = useRef(null);
  const noBtnForwardedRef = useRef(null);

  useEffect(() => {
    showMap(props.googleMapsResult);
    addBottomPadding();
  }, []);

  useEffect(() => {
    addBottomPadding();
  });

  useEffect(() => {
    showMap(googleMapsResult);
  }, [googleMapsResult]);

  const getScrollDistance = () => {
    const navHeight = mobileDeviceIsSmall() ? 0 : NAV_HEIGHT;
    return getComponentPos(mapRef.current).top - navHeight;
  };

  const showMap = (googleMapsResult) => {
    googleMaps.showMap(mapElemId, googleMapsResult);
    window.scrollBy(0, getScrollDistance());
  };

  const addBottomPadding = () => {
    const container = containerRef.current;
    const paddingBottom = window.innerHeight - getComponentPos(mapRef.current).bottom;
    const styleObj = StyleSheet.create({ container: { paddingBottom } });
    const className = css(styleObj.container);
    container.className += className;
  };

  return (
    <div ref={containerRef} className={css(styles.container)}>
      <div id={mapElemId} className={css(styles.map)} ref={mapRef} />
      <div className={css(styles.confirmation)}>
        <Flex
          flexFlow={'column nowrap'}
          justifyContent="space-between"
        >
          <div className={css(styles.confirmationCopy)}>
            <Instruction>
              Does the map show where you live? If not select no to retry.
            </Instruction>
          </div>
          <div className={css(styles.confirmationButtonsContainer)}>
            <Flex justifyContent="space-between">
              <div className={css(styles.confirmationButton)}>
                <Button
                  isActive={true}
                  id="yes-button"
                  label="YES"
                  onClick={onAcceptMappedLocation}
                  buttonRef={yesBtnForwardedRef}
                  style={styles.confirmationButton}
                />
              </div>
              <div className={css(styles.confirmationButton)}>
                <Button
                  isActive={true}
                  id="no-button"
                  label="NO"
                  onClick={onRejectMappedLocation}
                  buttonRef={noBtnForwardedRef}
                  style={styles.confirmationButton}
                />
              </div>
            </Flex>
          </div>
        </Flex>
      </div>
    </div>
  );
};

Map.propTypes = {
  googleMapsResult: PropTypes.array.isRequired,
  mapElemId: PropTypes.string,
  onAcceptMappedLocation: PropTypes.func.isRequired,
  onRejectMappedLocation: PropTypes.func.isRequired,
  setElementInFocus: PropTypes.func.isRequired,
};
