import PropTypes from 'prop-types';
import React from 'react';
import { TISA } from '../../lib/fonts';
import { RED } from '../../lib/colors';

const styles = {
  fontFamily: TISA,
  fontSize: '1em',
  color: RED,
};

const ErrorMsg = ({ errorMsg }) => {
  return errorMsg ? <p style={styles}>{errorMsg}</p> : null;
};

ErrorMsg.propTypes = {
  errorMsg: PropTypes.string,
};

ErrorMsg.defaultProps = {
  errorMsg: '',
};

export { ErrorMsg };
