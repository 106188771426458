import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Flex } from '../../sharedComponents/layoutHelpers';
import { ULTRALIGHT_GREY } from '../../lib/colors';
import spinner from '../../spinner-150px.svg';
import { Instruction } from '../sharedComponents/Instruction';
import { googleMaps } from './lib/GoogleMaps';

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  map: {
    height: 300,
    width: '100%',
    marginBottom: 36,
    backgroundColor: ULTRALIGHT_GREY,
    backgroundImage: `url('${spinner}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  mapMobile: {
    height: 220,
    width: '100%',
  },
  confirmationCopy: {
    marginTop: 10,
    marginBottom: 10,
  },
  confirmationButtonsContainer: {
    width: '50%',
  },
  confirmationButtonsContainerMobile: {
    width: '100%',
  },
});

export const DesktopMap = (props) => {
  const {
    children,
    googleMapsResult,
    mapElemId,
  } = props;

  const containerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    showMap(props.googleMapsResult);
    addBottomPadding();
  }, []);

  useEffect(() => {
    addBottomPadding();
  });

  useEffect(() => {
    showMap(googleMapsResult);
  }, [googleMapsResult]);

  const showMap = (googleMapsResult) => {
    googleMaps.showMap(mapElemId, googleMapsResult);
  };

  const addBottomPadding = () => {
    const container = containerRef.current;
    const paddingBottom = 0;
    const styleObj = StyleSheet.create({ container: { paddingBottom } });
    const className = css(styleObj.container);
    container.className += className;
  };

  return (
    <div ref={containerRef} className={css(styles.container)}>
      <div id={mapElemId} className={css(styles.map)} ref={mapRef} />
      <div className={css(styles.confirmation)}>
        <Flex
          flexFlow={'row nowrap'}
          justifyContent="space-between"
        >
          <div className={css(styles.confirmationCopy)}>
            <Instruction>
              Does the map show where you live? If not select no to retry.
            </Instruction>
          </div>
          <div className={css(styles.confirmationButtonsContainer)}>
            <Flex justifyContent="space-between">
              { children }
            </Flex>
          </div>
        </Flex>
      </div>
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  googleMapsResult: PropTypes.array.isRequired,
  mapElemId: PropTypes.string,
};
