import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Validator from 'email-validator';
import isValidZip from 'is-valid-zip';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      emailConfirmation: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      birthYear: '',
      gender: '',
      race: '',
      education: '',
      income: '',
      religion: '',
      party: '',
      maritalStatus: '',
      birthYearIsValid: true,
      emailIsValid: true,
      emailConfirmationMatches: true,
      zipcodeIsValid: true,
      showbirthYearModal: false,
      showEmailModal: false,
      showEmailConfirmationModal: false,
      showZipcodeModal: false,
      triedUnsuccessfulValidation: false,
    };
  }

  emailIsValid = (string) => {
    if (string === '') return true;
    return Validator.validate(string);
  };

  zipcodeIsValid = (string) => {
    if (string === '') return true;
    return isValidZip(string);
  };

  birthYearIsValid = (year) => {
    return year === '' || (year > 1899 && year < new Date().getFullYear());
  };

  handleTextFieldChange = (e) => {
    const whichState = e.target.dataset.stateObjectKey;
    const value = e.target.value;
    const questionId = e.target.name;
    const state = this.state;
    const props = this.props;

    switch (whichState) {
      case 'name':
      case 'email':
        props.onSaveTextAnswer({
          answer: value,
          questionId: questionId,
        });
        break;

      case 'birthYear':
        props.onSaveTextAnswer({
          answer: value.split('').slice(0, 4).join(''),
          questionId: questionId,
        });
        break;

      case 'address':
        props.onSaveAddress({
          address: value,
          questionId: questionId,
        });
        break;

      case 'city':
        props.onSaveCity({
          city: value,
          questionId: questionId,
        });
        break;

      case 'zipcode':
        props.onSaveZipcode({
          zipcode: value,
          questionId: questionId,
        });
        break;

      default:
        break;
    }

    const newEmailValidState =
      whichState === 'email' ? this.emailIsValid(value) : state.emailIsValid;

    const newEmailConfirmationMatchesState = (() => {
      if (whichState === 'email') {
        return value === state.emailConfirmation;
      } else if (whichState === 'emailConfirmation') {
        return value === state.email;
      } else {
        return state.emailConfirmationMatches;
      }
    })();

    const newZipcodeValidState =
      whichState === 'zipcode'
        ? this.zipcodeIsValid(value)
        : state.zipcodeIsValid;

    const newBirthYearValidState =
      whichState === 'birthYear'
        ? this.birthYearIsValid(value)
        : state.birthYearIsValid;

    this.setState({
      [whichState]: value,
      birthYearIsValid: newBirthYearValidState,
      emailIsValid: newEmailValidState,
      emailConfirmationMatches: newEmailConfirmationMatchesState,
      zipcodeIsValid: newZipcodeValidState,
    });
  };

  handleDropdownChange = (stateObjKey, value) => {
    this.setState({ [stateObjKey]: value });
  };

  handleBlur = (e) => {
    const stateObjectKey = e.target.dataset.stateObjectKey;
    const value = e.target.value;

    this.setState({
      showBirthYearModal:
        stateObjectKey === 'birthYear' ? !this.birthYearIsValid(value) : false,
      showEmailModal:
        stateObjectKey === 'email' ? !this.emailIsValid(value) : false,
      showEmailConfirmationModal:
        stateObjectKey === 'emailConfirmation'
          ? !this.state.emailConfirmationMatches
          : false,
      showZipcodeModal:
        stateObjectKey === 'zipcode' ? !this.zipcodeIsValid(value) : false,
    });
  };

  // this callback  display modals is only called when an unsuccessful form submission is attempted
  showInvalidModals = () => {
    const emailIsValid = this.emailIsValid(this.state.email);
    const zipcodeIsValid = this.zipcodeIsValid(this.state.zipcode);
    const birthYearIsValid = this.birthYearIsValid(this.state.birthYear);

    this.setState({
      showBirthYearModal: !birthYearIsValid,
      showEmailModal: !emailIsValid,
      showEmailConfirmationModal: !this.state.emailConfirmationMatches,
      showZipcodeModal: !zipcodeIsValid,
      triedUnsuccessfulValidation:
        !emailIsValid || !zipcodeIsValid || !birthYearIsValid,
    });
  };

  handleFocus = () => {
    if (this.state.triedUnsuccessfulValidation) {
      this.setState({
        showBirthYearModal: false,
        showEmailModal: false,
        showEmailConfirmationModal: false,
        showZipcodeModal: false,
        triedUnsuccessfulValidation: false,
      });
    }
  };

  formIsValidForSubmission = () => {
    const state = this.state;
    const birthYearIsValid = this.birthYearIsValid(state.birthYear);
    const emailIsValid = this.emailIsValid(state.email);
    const zipcodeIsValid = this.zipcodeIsValid(state.zipcode);
    const formHasSomeInput =
      state.address.length +
        state.name.length +
        state.email.length +
        state.city.length +
        state.state.length +
        state.zipcode.length +
        state.birthYear.length +
        state.gender.length +
        state.race.length +
        state.education.length +
        state.income.length +
        state.religion.length +
        state.party.length +
        state.maritalStatus.length >
      0;

    return (
      formHasSomeInput &&
      birthYearIsValid &&
      emailIsValid &&
      state.emailConfirmationMatches &&
      zipcodeIsValid
    );
  };

  render() {
    return this.props.render({
      formIsValidForSubmission: this.formIsValidForSubmission,
      formState: this.state,
      handleDropdownChange: this.handleDropdownChange,
      handleTextFieldChange: this.handleTextFieldChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      showInvalidModals: this.showInvalidModals,
    });
  }
}

Form.propTypes = {
  onSaveAddress: PropTypes.func.isRequired,
  onSaveCity: PropTypes.func.isRequired,
  onSaveOptionsQuestion: PropTypes.func.isRequired,
  onSaveState: PropTypes.func.isRequired,
  onSaveTextAnswer: PropTypes.func.isRequired,
  onSaveZipcode: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export { Form };
