export function throttle(callback, threshold = 100) {
  const timed = (timeoutId) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback();
    }, threshold);
  };

  return () => {
    let timeoutId = null;
    return timed(timeoutId);
  };
};