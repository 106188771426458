import mixpanel from './mixpanel';
import _ from 'lodash';
import { getAppBasePath } from './getAppBasePath';
import { pollLoadTime, buildApiUrlFromRelativeReference } from './apiHelpers';
import mockImpression from './mockData/mockImpression';
import mockRecruitmentPoll from './mockData/mockRecruitmentPoll';
import mockAccountEditPoll from './mockData/mockAccountEditPoll';

export const basePath = getAppBasePath();

export const baseApiGetRequestParams = {
  method: 'GET',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.questionator.v2+json',
  },
};

export const baseApiPostRequestParams = {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.questionator.v2+json',
  },
};

function getPostParams({ questionId, pollId, response }) {
  return {
    body: JSON.stringify({
      poll_id: pollId,
      response: {
        question_id: questionId,
        answer: response.answer,
        encoded: response.encoded,
        choice_id: response.choiceId,
        multi_choice_ids: response.multiChoiceIds,
        response_time: Date.now() - pollLoadTime,
        is_mobile: response.isMobile,
        layout: 'v3',
      },
    }),
    ...baseApiPostRequestParams,
  };
}

export class api {
  static getMockedResponse(pollLocationDetails) {
    let mockDataSource;

    switch (pollLocationDetails.prefix) {
      case 'p':
        mockDataSource = mockRecruitmentPoll;
        break;
      case 'i':
        mockDataSource = mockImpression;
        break;
      case 'e':
        mockDataSource = mockAccountEditPoll;
        break;
      default:
        mockDataSource = mockRecruitmentPoll;
        break;
    }

    return {
      responseObject: { status: 200 },
      payload: mockDataSource,
    }
  };

  static async issueApiRequest(urlToFetch) {
    try {
      const responseObject = await fetch(urlToFetch, baseApiGetRequestParams);
      const responseBody = await responseObject.json();

      return({
        responseObject: responseObject,
        payload: responseBody
      });
    } catch (error) {
      throw (error);
    }
  }

  static async recursivelyRedirect(wrappedResponse) {
    let currentPath = '';
    let { payload } = wrappedResponse;

    while (payload.redirect === true && payload.path !== currentPath) {
      currentPath = payload.path;
      wrappedResponse = await api.issueApiRequest(buildApiUrlFromRelativeReference(payload.path));
      ({ payload } = wrappedResponse);
    }

    return wrappedResponse;
  }

  static getSupplementalPoll(sourceImpressionHash) {
    return fetch(basePath + '/api/supplemental_impressions', {
      body: JSON.stringify({ source_impression_hash: sourceImpressionHash }),
      ...baseApiPostRequestParams,
    });
  }

  static postResponse({ questionId, pollId, response }) {
    const postParams = getPostParams({ questionId, pollId, response });

    return fetch(basePath + '/api/responses', postParams).catch(function (
      error
    ) {
      mixpanel.track('response_post_failed', { reason: error.message });
    });
  }

  static putCcpaRequest({ email, name, comment }) {
    return fetch(basePath + '/ccpa-request', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.questionator.v2+json',
      },
      body: JSON.stringify({
        email,
        name,
        notes: comment,
        request_type: 'erase',
      }),
    }).catch(function (error) {
      mixpanel.track('personal_data_deletion_failed', {
        reason: error.message,
      });
    });
  }

  static putDoNotSellRequest({ email, name, comment }) {
    return fetch(basePath + '/ccpa-request', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.questionator.v2+json',
      },
      body: JSON.stringify({
        email,
        name,
        notes: comment,
        request_type: 'do_not_sell',
      }),
    }).catch(function (error) {
      mixpanel.track('personal_data_deletion_failed', {
        reason: error.message,
      });
    });
  }

  static unsubscribeByEmail(email) {
    return fetch(basePath + '/api/unsubscribe-by-email', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.questionator.v2+json',
      },
      body: JSON.stringify({ unsubscribe: { email }}),
    });
  }

  static confirmUnsubscribe(secure_token) {
    // Why not just send the token in the body?
    return fetch(basePath + '/api/unsubscribe', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.questionator.v2+json',
      },
      body: JSON.stringify({ unsubscribe: { secure_token }}),
    });
  }
}
