import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as fonts from '../../lib/fonts';
import * as colors from '../../lib/colors';
import { OptionBody } from './OptionBody';
import { OptionButton } from './OptionButton';
import { MobileOptionWrapper } from './MobileOptionWrapper';

const styles = StyleSheet.create({
  optionBody: {
    fontFamily: fonts.TISA,
    color: colors.DARK_BLUE,
    fontSize: '1.1em',
    lineHeight: '1.1em',
  },
  radioButtonContainer: {
    marginBottom: -9,
  },
});

class MobileOption extends Component {
  state = { active: false };

  handleTouchStart = () => {
    this.setState({ active: true });
  };
  handleTouchEnd = () => {
    this.setState({ active: false });
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    const { body, isGreyedOut, selected, shape } = this.props;

    return (
      <MobileOptionWrapper
        onClick={this.handleClick}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        <OptionBody
          className={css(styles.optionBody)}
          isGreyedOut={isGreyedOut}
          text={body}
        />
        <div className={css(styles.radioButtonContainer)}>
          <OptionButton
            isActive={this.state.active && !isGreyedOut}
            isChecked={selected}
            shape={shape}
          />
        </div>
      </MobileOptionWrapper>
    );
  }
}

MobileOption.propTypes = {
  body: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isGreyedOut: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  shape: PropTypes.string,
};

MobileOption.defaultProps = {
  selected: false,
};

export { MobileOption };
