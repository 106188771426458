import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { MOBILE_PADDING_HORIZ, MAP_MARKER } from '../../lib/constants';
import { TextAnswer } from '../sharedComponents/TextAnswer';
import { Instruction } from '../sharedComponents/Instruction';
import { MobileMap } from './MobileMap';
import { MobileTextField } from './MobileTextField';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    paddingLeft: MOBILE_PADDING_HORIZ,
    paddingRight: MOBILE_PADDING_HORIZ,
  },
  homeAddressMsgContainer: {
    position: 'relative',
  },
  homeAddressMsg: {
    paddingBottom: 20,
  },
});

export const MobileLocationInput = (props) => {
  const {
    answer,
    cityState,
    forwardedInputRef,
    geoCoordinates,
    googleMapsResult,
    handleAcceptMappedLocation,
    handleRejectMappedLocation,
    handleInputSubmit,
    isAnswered,
    isCurrent,
    mapElemId,
    onClickToEditLocation,
    onSkip,
    responseIsOptional,
    showHomeAddressMsg,
    showMap,
  } = props;

  const [userIsActive, setUserIsActive] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fnOnSubmitBuilder = () => () => {
    handleInputSubmit(inputValue);
  };

  const fnOnAcceptMappedLocationBuilder = () => () => {
    handleAcceptMappedLocation(inputValue);
  };

  const isShowingGeoloactionRecordedMsg = () => {
    return geoCoordinates && isAnswered;
  };

  return (
    <div className={css(styles.container)} ref={forwardedInputRef}>
      <div className={css(styles.homeAddressMsgContainer)}>
        {showHomeAddressMsg ? (
          <div className={css(styles.homeAddressMsg)}>
            <Instruction>
              For better results, try entering your home address instead.
            </Instruction>
          </div>
        ) : null}
      </div>
      {!showMap && !isShowingGeoloactionRecordedMsg() ? (
        <MobileTextField
          inputValue={inputValue}
          isFocus={!showMap}
          isAnswered={isAnswered}
          iconCode={MAP_MARKER}
          label={'FIND'}
          onSkip={onSkip}
          onSubmit={fnOnSubmitBuilder()}
          response={cityState || ''}
          responseIsOptional={responseIsOptional}
          screenReaderLabel={'city and state'}
          setInputValue={setInputValue}
          setUserIsActive={setUserIsActive}
          userIsActive={userIsActive}
        />
      ) : null}
      {isAnswered && geoCoordinates && !isCurrent ? (
        <div onClick={onClickToEditLocation}>
          <TextAnswer>{answer}</TextAnswer>
        </div>
      ) : null}
      {showMap ? (
        <MobileMap
          googleMapsResult={googleMapsResult}
          mapElemId={mapElemId}
          onAcceptMappedLocation={fnOnAcceptMappedLocationBuilder()}
          onRejectMappedLocation={handleRejectMappedLocation}
        />
      ) : null}
    </div>
  );
};

MobileLocationInput.propTypes = {
  answer: PropTypes.string,
  cityState: PropTypes.string,
  geoCoordinates: PropTypes.string,
  googleMapsResult: PropTypes.array,
  handleAcceptMappedLocation: PropTypes.func.isRequired,
  handleInputSubmit: PropTypes.func.isRequired,
  handleRejectMappedLocation: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  mapElemId: PropTypes.string.isRequired,
  onClickToEditLocation: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  responseIsOptional: PropTypes.bool,
  showHomeAddressMsg: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
};

MobileLocationInput.defaultProps = {
  answer: '',
  isAnswered: false,
  googleMapsResult: [],
  mapElemId: 'gmap',
  responseIsOptional: false,
  showMap: false,
  showHomeAddressMsg: false,
  showMobileAnswer: false,
};
