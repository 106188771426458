import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as colors from '../../lib/colors';
import { MONTSERRAT } from '../../lib/fonts';

const styles = StyleSheet.create({
  base: {
    cursor: 'pointer',
    fontFamily: MONTSERRAT,
    color: colors.WHITE,
    textTransform: 'uppercase',
    letterSpacing: '0.045em',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '3px',
    border: 'none',
    backgroundColor: colors.FRENCH_GREY,
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  active: {
    backgroundColor: colors.BLUE,
    cursor: 'pointer',
    ':active': {
      backgroundColor: colors.TUNA_GREY,
    },
  },
});

const Button = (props) => {
  return (
    <div
      className={css(styles.base, props.isActive && styles.active)}
      style={{
        fontSize: props.fontSize,
        height: props.height + 'px',
        lineHeight: props.height + 'px',
      }}
      onMouseUp={props.onClick}
      onKeyDown={props.onKeyDown}
      ref={props.buttonRef}
      tabIndex={props.tabIndex || 0}
    >
      {props.label}
    </div>
  );
};

Button.propTypes = {
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  fontSize: PropTypes.number,
  height: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onTabKeyPress: PropTypes.func,
  tabIndex: PropTypes.number,
};

Button.defaultProps = {
  fontSize: 16,
  height: 36,
  label: 'SUBMIT',
};

export { Button };
