import React, { useContext } from 'react';
import { MOBILE, DESKTOP, TABLET } from '../../lib/constants';
import { ViewportContext } from '../../viewportContext';
import { MobileMultipleOptions } from './MobileMultipleOptions';
import { DesktopMultipleOptions } from './DesktopMultipleOptions';

const MultipleOptionsInput = (props) => {
  const viewport = useContext(ViewportContext);

  switch (viewport) {
    case MOBILE:
      return <MobileMultipleOptions {...props} />;
    case DESKTOP:
    case TABLET:
      return <DesktopMultipleOptions {...props} />;
    default:
      throw new Error('Unrecognized value for viewport');
  }
};


export { MultipleOptionsInput };