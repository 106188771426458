import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { some, sortBy } from 'lodash';
import { DesktopOption } from '../sharedComponents/DesktopOption';
import { Button } from '../sharedComponents/Button';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  optionsContainer: {
    marginBottom: '0.75em',
  },
  submitBtnContainer: {
    width: '24%',
    float: 'left',
    marginBottom: '0.75em',
  },
});

const DesktopMultipleOptions = (props) => {
  const {
    choices,
    isCurrent,
    isAnswered,
    onOptionClick,
    onSubmit,
    maxChoiceCount,
  } = props;

  const submitBtnForwardedRef = useRef(null);

  const [focusedOptionOrder, setFocusedOptionOrder] = useState(null);

  useEffect(() => {
    props.initOptionOrder();
    if (props.isCurrent) setInitialFocusedOption();
  }, []);

  useEffect(() => {
    if (props.isCurrent) setInitialFocusedOption();
  }, [props.isCurrent]);

  const hasAnyResponse = () => {
    return some(props.choices, (c) => c.selected === true);
  };

  const setInitialFocusedOption = () => {
    if (!hasAnyResponse()) return;
    const firstSelectedOption = props.choices.find((c) => c.selected);
    setFocusedOptionOrder(firstSelectedOption.order);
  };

  const focusOnNextOption = () => {
    const currentOrder = focusedOptionOrder;

    if (currentOrder < props.choices.length) {
      setFocusedOptionOrder(focusedOptionOrder + 1);
    } else {
      setFocusedOptionOrder(null);
      submitBtnForwardedRef.current.focus();
    }
  };

  const focusOnPrevOption = () => {
    const currentOrder = focusedOptionOrder;

    if (currentOrder > 1) {
      setFocusedOptionOrder(focusedOptionOrder - 1);
    } else {
      setFocusedOptionOrder(null);
      submitBtnForwardedRef.current.focus();
    }
  };

  const focusOnFirstOption = () => {
    setFocusedOptionOrder(1);
  };

  const focusOnLastOption = () => {
    setFocusedOptionOrder(props.choices.length);
  };

  const handleDesktopOptionKeyDown = (e) => {
    if (!props.isCurrent) return;
    e.preventDefault();
    const currentOption = props.choices.find(
      (c) => c.order === focusedOptionOrder
    );

    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          focusOnPrevOption();
        } else {
          focusOnNextOption();
        }
        break;
      case 'ArrowDown':
        focusOnNextOption();
        break;
      case 'ArrowUp':
        focusOnPrevOption();
        break;
      case 'Enter':
        if (currentOption.selected) {
          props.onSubmit();
        } else {
          props.onOptionClick(currentOption.id);
        }
        break;
      case 'Spacebar':
      case ' ':
        props.onOptionClick(currentOption.id);
        break;
      default:
        break;
    }
  };

  const handleSubmitBtnKeyDown = (e) => {
    e.preventDefault();
    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          focusOnLastOption();
        } else {
          focusOnFirstOption();
        }
        break;
      case 'ArrowDown':
        focusOnFirstOption();
        break;
      case 'ArrowUp':
        focusOnLastOption();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        if (hasAnyResponse()) props.onSubmit();
        break;
      default:
        break;
    }
  };

  const numSelectedChoices = choices.reduce((acc, curr) => {
    return curr.selected ? acc + 1 : acc;
  }, 0);

  let choicesToDisplay = [];
  if (isCurrent === true) {
    choicesToDisplay = sortBy(props.choices, ['order']);
  } else {
    if (isAnswered) {
      choicesToDisplay = sortBy(
        choices.filter((c) => c.selected),
        ['order']
      );
    }
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.optionsContainer)}>
        {choicesToDisplay.map((choice, index) => {
          const isLastInTheList = index === choicesToDisplay.length - 1;
          const isFocus = choice.order === focusedOptionOrder;
          const maxSelectionsReached = numSelectedChoices >= maxChoiceCount;

          return (
            <DesktopOption
              {...choice}
              displayBottomMargin={!isLastInTheList}
              isGreyedOut={maxSelectionsReached}
              isFocus={isFocus}
              key={index}
              onClick={() => {
                if (maxSelectionsReached && !choice.selected) return;
                onOptionClick(choice.id);
              }}
              onMouseEnter={() => {
                setFocusedOptionOrder(choice.order);
              }}
              onKeyDown={handleDesktopOptionKeyDown}
              shape={'square'}
            />
          );
        })}
      </div>
      {isCurrent ? (
        <div className={css(styles.submitBtnContainer)}>
          <Button
            buttonRef={submitBtnForwardedRef}
            onClick={() => {
              if (hasAnyResponse()) onSubmit();
            }}
            isActive={hasAnyResponse() && isCurrent}
            label={'Submit'}
          />
        </div>
      ) : null}
    </div>
  );
};

DesktopMultipleOptions.propTypes = {
  choices: PropTypes.array.isRequired,
  maxChoiceCount: PropTypes.number,
  initOptionOrder: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { DesktopMultipleOptions };