import React, { createContext, useState } from 'react';

export const AppPathContext = createContext({
  appPath: '/',
  setAppPath: () => {},
});

export const AppPathContextProvider = ({ children, path }) => {
  // This custom provider has internal state that can be updated by any
  // component beneath it in the component tree, when that component
  // calls setAppPath method from the provided context object
  const [appPath, setAppPath] = useState(path);
  const contextValueObject = {
    appPath,
    setAppPath,
  };

  return (
    <AppPathContext.Provider value={contextValueObject}>
      {children}
    </AppPathContext.Provider>
  );
};

AppPathContextProvider.defaultProps = {
  path: '/',
};
