// viewport widths
export const MOBILE = 'MOBILE';
export const TABLET = 'TABLET';
export const DESKTOP = 'DESKTOP';

// icon-font mapped characters
export const EMAIL_ENVELOPE = 'e';
export const MAP_MARKER = 'x';
export const THREE_STARS = 's';
export const CANCEL = 'c';
export const OPTION_BTN_ICON_SQUARE_CHECKED = 'j';
export const OPTION_BTN_ICON_SQUARE_UNCHECKED = 'k';
export const OPTION_BTN_ICON_CIRCLE_CHECKED = 'v';
export const OPTION_BTN_ICON_CIRCLE_UNCHECKED = 'o';

export const NAV_HEIGHT = 56;
export const MOBILE_PADDING_HORIZ = 32;
export const MOBILE_PADDING_VERT = 16;
