import { reverseChoices, shuffleChoices } from '../../lib/model';
import * as actionTypes from './actionTypes';


const optionsQuestionStateReducer = (state = [], action) => {
  let choicesHaveBeenReversedOnce = false;

  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch (action.type) {
      case actionTypes.SELECT_OPTION:
        return {
          ...q,
          choices: choiceReducer(q.choices, action),
          isAnswered: true,
        };

      case actionTypes.SET_OPTION_PENDING:
        return {
          ...q,
          choices: choiceReducer(q.choices, action),
          isAnswered: false,
        };

      case actionTypes.UNSET_OPTION_PENDING:
        return {
          ...q,
          choices: choiceReducer(q.choices, action),
        };

      case actionTypes.SHUFFLE_OPTIONS:
        return { ...q, choices: shuffleChoices(q.choices, q.shuffleChoices) };

      case actionTypes.REVERSE_OPTIONS:
        if (choicesHaveBeenReversedOnce) return { ...q, choices: q.choices };
        choicesHaveBeenReversedOnce = true;
        return { ...q, choices: reverseChoices(q.choices, q.reverseChoices) };

      default:
        return { ...q, choices: choiceReducer(q.choices, action) };
    }
  });
};

const choiceReducer = (state = [], action) => {
  const strategy = (() => {
    switch (action.type) {
      case actionTypes.SELECT_OPTION:
        return {
          changesForHit: { pending: false, selected: true },
          changesForMiss: { pending: false, selected: false },
          hitDetector: (c) => c.id === action.selectedChoiceId,
        };
      case actionTypes.SET_OPTION_PENDING:
        return {
          changesForHit: { pending: true, selected: false },
          changesForMiss: { pending: false, selected: false },
          hitDetector: (c) => c.id === action.pendingChoiceId,
        };
      case actionTypes.UNSET_OPTION_PENDING:
        return {
          changesForHit: { pending: false, selected: false },
          changesForMiss: null,
          hitDetector: (c) => c.id === action.noLongerPendingChoiceId,
        };
      case actionTypes.UPDATE_CUSTOM_OPTION:
        return {
          changesForHit: {
            customValue: action.customOptionValue,
          },
          changesForMiss: { pending: false, selected: false },
          hitDetector: (c) => c.id === action.selectedChoiceId,
        };
      default:
        return {
          changesForHit: null,
          changesForMiss: null,
          hitDetector: (c) => null,
        };
    }
  })();

  return state.map((c) => {
    if (strategy.hitDetector(c)) {
      return { ...c, ...strategy.changesForHit };
    } else {
      return { ...c, ...strategy.changesForMiss };
    }
  });
};

export { optionsQuestionStateReducer };