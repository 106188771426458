import mixpanel from '../../lib/mixpanel';
import { googleMaps } from './lib/GoogleMaps';
import * as geolocation from '../../lib/geolocation';
import { DESKTOP } from '../../lib/constants';
import * as locationQuestionModals from './sharedComponents/modals';

export const geoCoordinateModeName = {
  GET_GEO_COORDINATES: 'get geo coordinates',
  PROCESS_GEO_COORDINATES: 'process geo coordinates',
  NO_OP: 'no op',
}

export function businessLogic(actions, props) {
  const deriveGeoCoordinateStrategy = function (
    viewport,
    geoCoordinates = null,
    hasProcessedGeoCoordinates = null,
  ) {

    if (viewport === DESKTOP && !geoCoordinates) {
      return geoCoordinateModeName.GET_GEO_COORDINATES;
    }

    if (
      viewport === DESKTOP
      && !!geoCoordinates
      && !hasProcessedGeoCoordinates
    ) {
      return geoCoordinateModeName.PROCESS_GEO_COORDINATES;
    }

    return geoCoordinateModeName.NO_OP;
  };

  const processGeoCoordinates = function (geoCoordinates) {
    actions.setHasProcessedGeoCoordinates();
    actions.setGeoCoordinatesOnPollState(geoCoordinates);
    geolocation.stopWatchingPosition();
  };

  const getGeoCoordinates = function () {
    geolocation.tryWatchingPosition({
      onWait: handleGeolocationWait.bind(this),
      onSuccess: handleGeolocationSuccess.bind(this),
      onFailure: handleGeolocationFailure.bind(this),
      onlyIfGranted: false,
    });
  };

  const handleGeolocationWait = function () {
    actions.showModal(
      locationQuestionModals.DeterminingYourLocationModal(),
      { closeable: false }
    );
  };

  const handleGeolocationSuccess = function (pos) {
    actions.setGeoCoordinatesOnPollState(
      `${pos.coords.latitude}, ${pos.coords.longitude}`
    );

    actions.hideModal();
  };

  const handleGeolocationFailure = function () {
    geolocation.stopWatchingPosition();
    actions.showModal(locationQuestionModals.ErrorMsgModal(), {
      closeable: false,
    });

    const timeOutId = window.setTimeout(() => {
      actions.hideModal();
      window.clearTimeout(timeOutId);
    }, 3000);
  };

  const processAcceptanceOfMappedLocation = function (mappedLocation) {
    const { body, geoCoordinates, googleMapsResult } = props;

    const locationToRecordOnBackEnd = mappedLocation
      ? mappedLocation
      : geoCoordinates;
    const answerToDisplayForUser = mappedLocation
      ? mappedLocation
      : 'Thanks! Your location has been recorded.';

    actions.saveLocationToGlobalState(locationToRecordOnBackEnd);
    actions.hideMap();
    actions.fillAnswer(answerToDisplayForUser);
    actions.selectCurrentQuestionAutomatically();
    actions.sendResponseToBackEnd({
      answer: locationToRecordOnBackEnd,
      encoded: googleMapsResult,
    });

    mixpanel.track('provided_location', {
      question: body,
      city_state: locationToRecordOnBackEnd,
      answer: locationToRecordOnBackEnd,
    });
  };

  const processRejectionOfMappedLocation = function () {
    const { answer, body } = props;

    actions.unsetGeoCoordinates();
    actions.hideMap();
    actions.showHomeAddressMsg();
    actions.unfillAnswer();
    actions.setThisQuestionAsTheCurrentQuestion();

    mixpanel.track('map_incorrect', {
      question: body,
      answer: answer,
    });
  };

  const queryGoogleMaps = function (query) {
    return new Promise((resolve, reject) => {
      googleMaps.getMapData(query).then(
        (results) => {
          resolve(results);
        },
        (reasonForFailure) => {
          actions.fillAnswer(query);
          actions.selectCurrentQuestionAutomatically();
          actions.sendResponseToBackEnd({
            answer: query,
            encoded: null,
          });
          mixpanel.track('provided_location', {
            question: props.body,
            city_state: query,
            answer: query,
          });
          reject(reasonForFailure);
        }
      );
    });
  };

  const processClickToEditLocation = function () {
    actions.unsetGeoCoordinates();
    actions.unfillAnswer();
    actions.setThisQuestionAsTheCurrentQuestion();
  };

  const processSkip = function () {
    actions.addThisToSkippedQuestionList();
  };

  return {
    deriveGeoCoordinateStrategy,
    processGeoCoordinates,
    getGeoCoordinates,
    handleGeolocationWait,
    handleGeolocationSuccess,
    handleGeolocationFailure,
    processAcceptanceOfMappedLocation,
    processRejectionOfMappedLocation,
    queryGoogleMaps,
    processClickToEditLocation,
    processSkip,
  };
}
