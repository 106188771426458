
class Mixpanel {
  constructor(properties) {
    this.properties = properties || null;
  }

  registerOnce(properties) {
    this.properties = Object.assign({}, this.properties, properties);
  }

  isResponse(what) {
    return what.match(/provided_/i);
  }

  identify(label) {
    window.mixpanel.identify(label);
    window.mixpanel.name_tag(label);
  }

  track(what, options) {
    const properties = (options) ? Object.assign({}, this.properties, options) : this.properties;

    if (what === 'responded' ||
        what === 'provided_email' ||
        what === 'provided_location' ||
        what === 'provided_birth_year') {
      this.incrementPeople('responses')
    }

    if (what === 'provided_email'){
      window.mixpanel.alias(properties.email);  // this links the mixpanel id with our id
      this.setPeople('email', properties.email);
      window.mixpanel.name_tag(properties.email);
      if (window.ga) window.ga("send", "event", "button", "click", what);
    }

    if (what === 'provided_location') this.setPeople('location', properties.location);
    if (what === 'provided_birth_year') this.setPeople('birth_year', properties.birth_year);
    if (this.isResponse(what)) window.mixpanel.track('responded', properties);
    window.mixpanel.track(what, properties);
  }

  setPeople(properties) {
    window.mixpanel.people.set(properties);
  }

  incrementPeople(what, delta) {
    window.mixpanel.people.increment(what, delta);
  }
}

export default new Mixpanel();
