import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { api } from './lib/api';
import { Box, Flex, Text } from '@rebass/emotion';
import Validator from 'email-validator';
import { Transition } from 'react-transition-group';
import { MEDIUM_GREY, WHITE } from './lib/colors';
import { MONTSERRAT } from './lib/fonts';
import { useMainContentOverflow } from './hooks/useMainContentOverflow';
import TextInput from './sharedComponents/formHelpers/TextInput';
import SubmitButton from './sharedComponents/formHelpers/SubmitButton';
import {
  Container,
  GridContainer,
  Hero,
  LongTitle,
  Row,
  ThreeStars,
} from './sharedComponents/layoutHelpers';

const Form = ({
  email,
  emailIsValid,
  handleBlur,
  handleChange,
  handleFocus,
  handleKeyDown,
  handleSubmit,
  showEmailModal,
}) => {
  return (
    <Container>
      <Hero>
        <LongTitle>Unsubscribe from Civiqs Surveys</LongTitle>
      </Hero>
      <GridContainer>
        <Box mt={4}>
          <form name='unsubscribe'>
            <Flex
              alignItems={'flex-start'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
            >
              <Row>
                <Text
                  color={MEDIUM_GREY}
                  fontFamily={MONTSERRAT}
                  fontSize={[1, 2]}
                  fontWeight={300}
                  mb={14}
                > To no longer receive surveys from Civiqs, please enter your email address.</Text>
                <TextInput
                  id="unsubscribe-email"
                  invalidInputMessage={'Please enter a valid email.'}
                  isValid={emailIsValid}
                  label="Your email address"
                  labelIsHidden={true}
                  maxLength={254}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  showModal={showEmailModal}
                  type="email"
                  value={email}
                />
              </Row>
              <Row>
                <SubmitButton isActive={emailIsValid} onClick={handleSubmit} />
              </Row>
            </Flex>
          </form>
        </Box>
      </GridContainer>
    </Container>
  );
}

const SuccessMessage = ({ email }) => (
  <Container >
    <Hero>
      <LongTitle>Your unsubscribe request has been received. If we found a valid account, a confirmation email will be sent to {email}.</LongTitle>
      <ThreeStars />
      <Text
        color={WHITE}
        fontFamily={MONTSERRAT}
        fontSize={[2, 3]}
        fontWeight={300}
        mb={14}
      >If you have any questions or feedback, feel free to <a href="mailto:inquiries@civiqs.com" style={{ color: 'white', textDecoration: 'none' }}>contact us at inquiries@civiqs.com</a>.</Text>
    </Hero>
  </Container>
);

const defaultStyle = {
  opacity: 0,
  background: 'none',
};

const transitionStyles = {
  entering: {
    opacity: 1,
    background: '#FDFD96',
    transition: `all 1500ms ease-in-out`,
  },
  entered: {
    opacity: 1,
    transition: `background 1500ms ease-in-out`,
    background: 'none',
  },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ErrorView = ({ errorMessage, showReloadLink }) => (
  <Container >
    <Hero>
      <Transition in={true} timeout={1000}>
        {(transitionState) => (
          <div>
            <Text
              color={WHITE}
              fontFamily={MONTSERRAT}
              fontSize={[2, 3]}
              fontWeight={300}
              key={1}
              style={{
                ...defaultStyle,
                ...transitionStyles[transitionState],
              }}
            >{errorMessage}</Text>
          </div>
        )}
      </Transition>
      {showReloadLink && <Box my={3} color={WHITE}>
        Check your network connection and<span> </span>
        <a href='/unsubscribe-by-email' style={{ color: WHITE}}>
          try again.
        </a>
      </Box>}
    </Hero>
  </Container>
);

const UnsubscribeByEmail = ({ allowMainContentOverflow, defaultErrorMessage="Somthing went wrong. Please try again later." }) => {
  const [state, setState] = useState({
    email: '',
    emailIsValid: false,
    errorMessage: '',
    formSubmitted: false,
    showEmailModal: false,
    showReloadLink: false,
  });

  useMainContentOverflow();

  const emailIsValid = (string) => {
    if (string === '') return false;
    return Validator.validate(string);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      email: e.target.value,
      emailIsValid: emailIsValid(e.target.value),
    });
  }

  const handleBlur = (e) => {
    setState({
      ...state,
      emailIsValid: emailIsValid(e.target.value),
      showEmailModal: !emailIsValid(e.target.value),
    })
  }

  const handleFocus = (e) => {
    setState({
      ...state,
      emailIsValid: emailIsValid(e.target.value),
      showEmailModal: false,
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(defaultErrorMessage);
    }
  }

  const parseResponseJsonPromise = (response, defaultErrorMessage) => {
    if (response.json) {
      return response.json()
        .then((data) => Promise.resolve({ response, data }))
        .catch(() => Promise.resolve({ response, data: { error: defaultErrorMessage } }));
    } else {
      return Promise.resolve({ response, data: { error: defaultErrorMessage } });
    }
  }

  const handleSubmit = (defaultErrorMessage) => {
    if (state.emailIsValid) {
      api.unsubscribeByEmail(state.email)
        .then((response) => parseResponseJsonPromise(response, defaultErrorMessage))
        .then(({ response, data }) => {
          if (response.ok) {
            setState({ ...state, formSubmitted: true });
          } else {
            setState({ ...state, errorMessage: data.error });
          }
        })
        .catch((error) => {
          setState({ ...state, errorMessage: error.message, showReloadLink: true });
        });
    } else {
      setState({ ...state, showEmailModal: true });
    }
  }

  if (state.errorMessage) {
    return <ErrorView errorMessage={state.errorMessage} showReloadLink={state.showReloadLink} />
  } else if (state.formSubmitted) {
    return <SuccessMessage email={state.email} />
  } else {
    return <Form
      email={state.email}
      emailIsValid={state.emailIsValid}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleKeyDown={handleKeyDown}
      handleSubmit={() => {handleSubmit(defaultErrorMessage)}}
      showEmailModal={state.showEmailModal}
    />
  }
};

export { UnsubscribeByEmail }
