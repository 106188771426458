import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { QuestionInputWrapper } from '../sharedComponents/QuestionInputWrapper';
import { TextInput } from '../sharedComponents/TextInput';

const TextQuestionInput = (props) => {
  const {
    isAnswered,
    isCurrent,
    isLargeText,
    onInputChange,
    onTextFieldFocus,
    onSubmit,
    response,
    viewport,
  } = props;

  const dispatch = useDispatch();
  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  return (
    <QuestionInputWrapper
      answer={response}
      isCurrent={isCurrent}
      viewport={viewport}
    >
      <TextInput
        isActive={isCurrent && response.length > 0}
        isAnswered={isAnswered}
        isLargeText={isLargeText}
        onFocus={onTextFieldFocus}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        response={response}
        screenReaderLabel={'text field'}
        viewport={viewport}
        visible={isCurrent}
      />
    </QuestionInputWrapper>
  );
};

TextQuestionInput.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  response: PropTypes.string.isRequired,
};

TextQuestionInput.defaultProps = {
  response: '',
};

export { TextQuestionInput };
