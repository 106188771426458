import * as actionTypes from './actionTypes';

export const zipcodeQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch(action.type) {
      case actionTypes.UPDATE_ZIPCODE:
        return {...q, zipcode: action.zipcode, errorMsg: '', isAnswered: false};
      case actionTypes.DISPLAY_ERROR:
        return {...q, errorMsg: action.errorMsg};
      case actionTypes.SAVE_ZIPCODE:
        return {...q, zipcode: action.zipcode, isAnswered: true, errorMsg: ''};
      default:
        return q;
    }
  });
};
