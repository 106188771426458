import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { sortBy } from 'lodash';
import { DesktopOption } from '../sharedComponents/DesktopOption';
import { DesktopCustomOption } from '../sharedComponents/DesktopCustomOption';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

const DesktopOptions = (props) => {
  const [focusedOptionIdx, setFocusedOptionIdx] = useState(-1);

  useEffect(() => {
    props.initOptionOrder();
    props.putFocusOnQuestionBody();
  }, []);

  const setFocusedOptionById = (id) => {
    const focusedOptionIdx = props.choices.findIndex((c) => c.id === id);
    setFocusedOptionIdx(focusedOptionIdx);
  };

  const focusOnNextOption = () => {
    if (focusedOptionIdx < props.choices.length - 1) {
      setFocusedOptionIdx(focusedOptionIdx + 1);
    } else {
      setFocusedOptionIdx(0);
    }
  };

  const focusOnPrevOption = () => {
    if (focusedOptionIdx > 0) {
      setFocusedOptionIdx(focusedOptionIdx - 1);
    } else {
      setFocusedOptionIdx(props.choices.length - 1);
    }
  };

  const handleKeyDown = (e) => {
    if (!props.isCurrent) return;

    e.preventDefault();
    if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'ArrowDown') {
      focusOnNextOption();
      if (props.isCustomOtherChoice) props.unsetOptionPending();
    } else if ((e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowUp') {
      focusOnPrevOption();
      if (props.isCustomOtherChoice) props.unsetOptionPending();
    } else if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      if (focusedOptionIdx >= 0)
        props.onOptionClick(props.choices[focusedOptionIdx].id);
    }
  };

  const {
    choices,
    isAnswered,
    isCurrent,
    onCustomOptionChange,
    onOptionClick,
    setOptionPending,
  } = props;

  let choicesToDisplay = [];
  if (isCurrent === true) {
    choicesToDisplay = sortBy(props.choices, ['order']);
  } else {
    if (isAnswered) {
      choicesToDisplay = sortBy(
        choices.filter((c) => c.selected),
        ['order']
      );
    }
  }

  return (
    <div className={css(styles.container)}>
      {choicesToDisplay.map((choice, index) => {
        const { customValue, isCustomOtherChoice, ...restProps } = choice;
        const { id } = restProps;
        const isLastInTheList = index === choicesToDisplay.length - 1;
        const isFocus = isCurrent
          ? props.selected || index === focusedOptionIdx
          : false;

        return isCustomOtherChoice ? (
          <DesktopCustomOption
            {...restProps}
            key={index}
            displayBottomMargin={!isLastInTheList}
            isFocus={isFocus}
            onChange={(e) => {
              onCustomOptionChange(id, e.target.value);
            }}
            onClick={() => {
              setOptionPending(id);
            }}
            onFocus={() => {
              setFocusedOptionById(id);
            }}
            onKeyDown={handleKeyDown}
            onSubmit={() => {
              onOptionClick(id);
            }}
            shape={'circle'}
            showAnswer={!props.isCurrent && restProps.selected}
            value={customValue}
          />
        ) : (
          <DesktopOption
            {...restProps}
            key={index}
            displayBottomMargin={!isLastInTheList}
            isFocus={isFocus}
            onClick={() => {
              onOptionClick(id);
            }}
            onFocus={() => {
              setFocusedOptionById(id);
            }}
            onKeyDown={handleKeyDown}
            shape={'circle'}
          />
        );
      })}
    </div>
  );
};

DesktopOptions.propTypes = {
  choices: PropTypes.array.isRequired,
  initOptionOrder: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onCustomOptionChange: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  putFocusOnQuestionBody: PropTypes.func,
  setOptionPending: PropTypes.func.isRequired,
};

export { DesktopOptions };