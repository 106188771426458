import { connect } from 'react-redux';
import { Poll } from './component';
import * as actions from './actions';

const mapStateToProps = ({ poll }) => {
  return {
    questions: poll.questions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitCompletedPoll: () => {
      dispatch(actions.showThankYouPage());
    },
    onceBeforeRender: () => {
      dispatch(actions.rewritePath());
    },
  };
};

export const pollContainer = connect(mapStateToProps, mapDispatchToProps)(Poll);
