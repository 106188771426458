import React, { useEffect } from 'react';
import _ from 'lodash';
import { Text } from '@rebass/emotion';
import { WHITE } from './lib/colors';
import { MONTSERRAT } from './lib/fonts';
import { Container, Hero, LongTitle, ThreeStars } from './sharedComponents/layoutHelpers';
import { useMainContentOverflow } from './hooks/useMainContentOverflow';

const UnsubscribeComplete = ({ allowMainContentOverflow }) => {

  useMainContentOverflow();

  return (
    <Container >
      <Hero>
        <LongTitle>You have been successfully unsubribed from Civiqs surveys.</LongTitle>
        <ThreeStars />
        <Text
          color={WHITE}
          fontFamily={MONTSERRAT}
          fontSize={[2, 3]}
          fontWeight={300}
          mb={14}
        >If you have any questions or feedback, feel free to <a href="mailto:inquiries@civiqs.com" style={{ color: 'white', textDecoration: 'none' }}>contact us at inquiries@civiqs.com</a>.</Text>
      </Hero>
    </Container>
  )
};

export { UnsubscribeComplete };

