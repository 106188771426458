import * as pollActions from '../../poll/actions';
import * as actionTypes from './actionTypes';

export const selectOption = (
  questionId,
  questionBody,
  selectedChoiceId,
  apiResponse
) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_OPTION,
      id: questionId,
      selectedChoiceId: selectedChoiceId,
    });

    dispatch(pollActions.saveResponse(questionId, questionBody, apiResponse));
    dispatch(pollActions.dispatchSkipForAllPrevNonStickyQuestions(questionId));
    dispatch(pollActions.setCurrentQuestionAutomatically());
  };
};

export const setOptionPending = (questionId, choiceId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_OPTION_PENDING,
      id: questionId,
      pendingChoiceId: choiceId,
    });
  };
};

export const unsetOptionPending = (questionId, choiceId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UNSET_OPTION_PENDING,
      id: questionId,
      noLongerPendingChoiceId: choiceId,
    });
  };
};

export const updateCustomOption = (questionId, choiceId, optionValue) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOM_OPTION,
      id: questionId,
      selectedChoiceId: choiceId,
      customOptionValue: optionValue,
    });
  };
};

export const reverseOptions = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVERSE_OPTIONS,
      id: questionId,
    });
  };
};

export const shuffleOptions = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHUFFLE_OPTIONS,
      id: questionId,
    });
  };
};
