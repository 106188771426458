const mockRecruitmentPoll = {
   "id":"jlap4R",
   "name":"recruit_obamacare",
   "poll_type":"recruitment",
   "questions":[
      {
         "id":47,
         "name":"opinion_aca",
         "body":"Which comes closest to your view about the Affordable Care Act, also known as Obamacare? Should it be:",
         "question_type":"research",
         "render_type":"options",
         "choices":[
            {
               "id":68,
               "body":"Repealed entirely",
               "order":1
            },
            {
               "id":69,
               "body":"Expanded to do more",
               "order":2
            },
            {
               "id":70,
               "body":"Kept but scaled back",
               "order":3
            },
            {
               "id":71,
               "body":"Kept as is",
               "order":4
            },
            {
               "id":72,
               "body":"Unsure",
               "order":5
            }
         ]
      },
      {
         "id":430,
         "name":"turnout_retro_2016",
         "body":"Did you vote in the 2016 presidential election? ",
         "question_type":"research",
         "render_type":"options",
         "choices":[
            {
               "id":1701,
               "body":"Yes, I voted early",
               "order":0
            },
            {
               "id":1702,
               "body":"Yes, I voted on election day",
               "order":1
            },
            {
               "id":1703,
               "body":"No",
               "order":3
            }
         ]
      },
      {
         "id":55,
         "name":"party_id",
         "body":"Do you usually think of yourself as a:",
         "question_type":"profile",
         "render_type":"options",
         "choices":[
            {
               "id":98,
               "body":"Democrat",
               "order":1
            },
            {
               "id":99,
               "body":"Republican",
               "order":2
            },
            {
               "id":100,
               "body":"Independent",
               "order":3
            },
            {
               "id":101,
               "body":"Other or none",
               "order":4
            }
         ]
      },
      {
         "id":9,
         "name":"recruit_location",
         "body":"To ensure that our survey represents all Americans, can you tell us your city and state?",
         "question_type":"geographical",
         "render_type":"location",
         "choices":[

         ]
      },
      {
         "id":10,
         "name":"recruit_email",
         "body":"Can we follow up with you for future surveys? Please enter your email address.",
         "question_type":"email",
         "render_type":"email",
         "choices":[

         ]
      }
   ]
}

export default mockRecruitmentPoll;