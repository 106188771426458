import { connect } from 'react-redux';
import { ZipcodeQuestionInput } from './component';
import * as actions from './actions';
import * as pollActions from '../../poll/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInputChange: (zipcode) => {
      dispatch(actions.updateZipcode(ownProps.id, zipcode));
    },
    onSubmit: () => {
      dispatch(
        actions.submitZipcode(ownProps.id, ownProps.zipcode, ownProps.body)
      );
    },
    onAutoSubmit: () => {
      dispatch(
        actions.autoSubmitZipcode(ownProps.id, ownProps.zipcode, ownProps.body)
      );
    },
    onTextFieldFocus: () => {
      dispatch(pollActions.setCurrentQuestionById(ownProps.id));
    },
  };
};

export const ZipcodeQuestionContainer = connect(null, mapDispatchToProps)(ZipcodeQuestionInput);
