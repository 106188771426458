import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite-jss';
import ResizeSensor from 'resize-sensor--react';
import Magnifier from 'react-magnifier';
import * as highlightActions  from './highlight/actions';
import * as pollActions from './actions';
import '../lib/resize-sensor.css';
import * as colors from '../lib/colors';
import * as fonts from '../lib/fonts';
import { parseAllowedHtml } from '../lib/parseAllowedHtml';
import * as scroll from '../lib/scroll';
import { Flex } from '../sharedComponents/layoutHelpers';
import mixpanel from '../lib/mixpanel';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.DIVIDER_COLOR,
  },
  main: {
    flex: '1 1 auto',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: colors.DIVIDER_COLOR,
    paddingLeft: 48,
    paddingTop: 36,
    paddingRight: 48,
    paddingBottom: 36,
    width: '100%',
  },
  mainCurrent: {
    paddingBottom: 48,
    paddingTop: 48,
  },
  questionBodyBase: {
    fontFamily: fonts.TISA,
    fontSize: '1.5em',
    lineHeight: '1.3em',
    fontWeight: 100,
    color: colors.DARK_BLUE,
    outline: 'none',
  },
  questionBodyCurrent: {
    fontSize: '2em',
  },
  questionNumber: {
    color: colors.BLUE,
    fontFamily: fonts.TISA,
    fontSize: '1.6em',
    lineHeight: '1.25em',
    textAlign: 'center',
    flex: '0 0 50px',
    alignSelf: 'center',
  },
  questionNumberCurrent: {
    alignSelf: 'flex-start',
    paddingTop: 48,
  },
  inputSectionContainer: {
    width: '100%',
    paddingLeft: 0,
    paddingTop: 48,
    paddingRight: 0,
    paddingBottom: 0,
    margin: 0,
  },
  inputSectionContainerAnswered: {
    paddingTop: 36,
    position: 'relative',
  },
  changeAnswerButton: {
    textTransform: 'capitalize',
    color: colors.MANATEE_GREY,
    fontFamily: fonts.MONTSERRAT,
    fontSize: '0.75em',
    fontWeight: 200,
    letterSpacing: '0.046em',
    cursor: 'pointer',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: 'radial-gradient(#FFFFFF 0%, transparent 100%)',
  },
});

const QuestionInputWrapper = ({
  input,
  isAnswered,
  isCurrent,
  showChangeAnswerButton,
}) => {
  return isCurrent || isAnswered ? (
    <div
      className={css(
        styles.inputSectionContainer,
        isAnswered && styles.inputSectionContainerAnswered
      )}
    >
      <Flex alignItems={'center'} justifyContent={'flex-start'}>
        <div style={{width: "100%"}}>
          {input}
        </div>
        {showChangeAnswerButton ? (
          <div className={css(styles.changeAnswerButton)}>
            <Flex alignItems="center" justifyContent="center">
              Change Answer
            </Flex>
          </div>
        ) : null}
      </Flex>
    </div>
  ) : null;
};

class _DesktopQuestionLayout extends Component {
  constructor(props) {
    super(props);

    this.bodyRef = React.createRef();
  }

  state = {
    hover: false,
    maxImageHeight: Math.round(window.innerHeight * 0.475),
  };

  componentDidMount() {
    this.highlightAndCenter();
    window.addEventListener('resize', this.updateMaxImageHeight);
  }

  componenWillUnmount() {
    window.removeEventListener('resize', this.updateMaxImageHeight);
  }

  componentDidUpdate(prevProps) {
    // If the activeElement is not returning body or null then the resize was
    // triggered by either the a change in the height of the mobile browser's
    // address bar or by the appearance of a virtual keyboard
    if (
      document.activeElement === document.body ||
      document.activeElement === null
    ) {
      this.highlightAndCenter();
    }
  }

  putFocusOnQuestionBody = () => {
    this.bodyRef.current.focus();
  };

  updateMaxImageHeight = () => {
    this.setState({ maxImageHeight: Math.round(window.innerHeight * 0.475) });
  };

  highlightAndCenter = () => {
    if (this.props.isCurrent) {
      this.props.highlightQuestion(this)
      scroll.to(this.bodyRef.current.parentNode)
    }
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick(this.props.id);
    mixpanel.track('jumped_to_question', { question: this.props.body });
  };

  handleMouseEnter = () => {
    if (this.props.isCurrent && this.props.isAnswered) return;
    if (!this.props.isAnswered) return;
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    if (!this.props.isAnswered) return;
    this.setState({ hover: false });
  };

  render() {
    const { children, id, index, isAnswered, isCurrent, ...rest } = this.props;
    const questionNumber = index + 1;
    const showChangeAnswerButton = !isCurrent && isAnswered && this.state.hover;

    return (
      <div
        className={css(styles.container)}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Flex alignItems="flex-start">
          <div
            children={questionNumber}
            className={css(
              styles.questionNumber,
              isAnswered && styles.questionNumberCurrent,
              isCurrent && styles.questionNumberCurrent
            )}
          />
          <div className={css(styles.main, isCurrent && styles.main.isCurrent)}>
            <h2
              children={parseAllowedHtml(rest.body)}
              className={css(
                styles.questionBodyBase,
                isCurrent && styles.questionBodyCurrent
              )}
              tabIndex={-1}
              ref={this.bodyRef}
            />
            <div style={{display: 'inline-block'}}>
              <Magnifier
                alt={rest.alt}
                mgBorderWidth={1}
                mgHeight={175}
                mgShape={'square'}
                mgWidth={175}
                style={{
                  maxHeight: this.state.maxImageHeight,
                  paddingTop: 32,
                  width: 'auto',
                }}
                src={rest.imageUrlLg}
                />
              </div>
            <QuestionInputWrapper
              input={React.cloneElement(children, {
                putFocusOnQuestionBody: this.putFocusOnQuestionBody,
              })}
              isAnswered={isAnswered}
              isCurrent={isCurrent}
              showChangeAnswerButton={showChangeAnswerButton}
            />
          </div>
        </Flex>
        <ResizeSensor onResize={this.highlightAndCenter} />
      </div>
    );
  }
}

_DesktopQuestionLayout.propTypes = {
  isAnswered: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  highlightQuestion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    highlightQuestion: (questionComponent) => {
      dispatch(highlightActions.highlightQuestion(questionComponent));
    },
    onClick: (questionId) => {
      if (ownProps.isCurrent) return;
      dispatch(pollActions.setCurrentQuestionById(questionId));
    },
  };
};

export const DesktopQuestionLayout = connect(null, mapDispatchToProps, null, {
  forwardRef: true,
})(_DesktopQuestionLayout);


