import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as appActions from './appActions';
import { api } from './lib/api';
import {
  SuccessPage,
  ReportUnWantedEmailPage,
  RequestPage,
} from './sharedComponents/simpleRequestForm/component';

const _DoNotSell = ({
  allowMainContentOverflow,
  location: { pathname: path },
}) => {
  useEffect(() => {
    allowMainContentOverflow();
  }, []);

  if (_.includes(path, 'success'))
    return (
      <SuccessPage
        text={`You have opted-out of the sale of your personal information. `}
      />
    );
  if (_.includes(path, 'unwanted-email')) return <ReportUnWantedEmailPage />;
  return (
    <RequestPage
      title="Do Not Sell"
      mainInstructionText={`
          If you are a California resident, you may request that Civiqs not sell
          any of your personal information to any third party. If you wish to
          opt out of the sale of your personal information, please complete this
          form:
      `}
      putRequest={({ name, email, comment }) => {
        api.putDoNotSellRequest({ name, email, comment });
      }}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allowMainContentOverflow: () => {
      dispatch(appActions.allowMainContentOverflow());
    },
  };
};

const DoNotSell = connect(null, mapDispatchToProps)(_DoNotSell);

export { DoNotSell };
