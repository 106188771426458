import queryString from 'query-string';
import { buildApiUrlFromRelativeReference } from './apiHelpers';

const relativeReference = window.location.pathname + window.location.search;
const urlObject = new URL(buildApiUrlFromRelativeReference(relativeReference));
let rootUrl = urlObject.protocol + '//' + urlObject.host
let [prefix, hash] = urlObject.pathname.split('/').slice(1, 3);

if (hash && hash.split('%2Fi%2F').length > 1) {
  prefix = 'i';
  hash = hash.split('%2Fi%2F')[1];
}

const parsedQueryString = queryString.parse(urlObject.search);
const isApiBackedPoll = !parsedQueryString || !parsedQueryString.mock === true;
const utmParamsArray = getUtmParamsArray(urlObject);

function getUtmParamsArray(urlObject) {
  const rawSearchString = urlObject.search.substring(1);
  const regex = /(utm_[^&=]+)=?([^&]*)/g;

  return rawSearchString.match(regex) || [];
}

export {
  hash,
  isApiBackedPoll,
  prefix,
  rootUrl,
  utmParamsArray,
  getUtmParamsArray,
}