import * as actionTypes from './actionTypes';
import * as pollActions from '../../poll/actions';
import * as modalActions from '../../modal/actions';

export function useActions(dispatch, props) {
  const { id, body } = props;

  return {
    setGeoCoordinatesOnPollState: (geoCoordinates) => {
      dispatch(pollActions.setGeoCoordinates(geoCoordinates));
    },

    showMap: (googleMapsResult) => {
      dispatch({
        googleMapsResult,
        id,
        type: actionTypes.SHOW_MAP,
      });
    },

    hideMap: () => {
      dispatch({
        id,
        type: actionTypes.HIDE_MAP,
      });
    },

    fillAnswer: (answer) => {
      dispatch({
        answer,
        id,
        type: actionTypes.SET_ANSWER,
      });
    },

    unfillAnswer: () => {
      dispatch({
        id,
        type: actionTypes.UNSET_ANSWER,
      });
    },

    selectCurrentQuestionAutomatically: () => {
      dispatch(pollActions.setCurrentQuestionAutomatically());
    },

    setThisQuestionAsTheCurrentQuestion: () => {
      dispatch(pollActions.setCurrentQuestionById(id));
    },

    sendResponseToBackEnd: (responseObj) => {
      dispatch(pollActions.saveResponse(id, body, responseObj));
    },

    hideModal: () => {
      dispatch(modalActions.hideModal());
    },

    showModal: (modal, options) => {
      dispatch(modalActions.showModal(modal, options));
    },

    setHasProcessedGeoCoordinates: (bool) => {
      dispatch({
        bool,
        id,
        type: actionTypes.HAS_PROCESSED_GEO_COORDINATES,
      });
    },

    unsetGeoCoordinates: () => {
      dispatch(pollActions.unsetGeoCoordinates());
    },

    showHomeAddressMsg: () => {
      dispatch({
        id,
        type: actionTypes.SHOW_HOME_ADDRESS_MESSAGE,
      });
    },

    addThisToSkippedQuestionList: () => {
      pollActions.addToSkippedQuestionListById(id, body);
    },

    saveLocationToGlobalState: (cityState) => {
      dispatch({
        cityState,
        id,
        type: actionTypes.UPDATE_CITY_STATE,
      });
    },

    showErrorMsg: (errorMsg) => {
      dispatch({
        errorMsg,
        id,
        type: actionTypes.SHOW_ERROR_MESSAGE,
      });
    },

    clearCityState: () => {
      dispatch({
        id,
        type: actionTypes.CLEAR_CITY_STATE,
      });
    },
  };
}
