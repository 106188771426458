import { questionTypes } from './questionTypes';

const accountEditStateReducer = (state = null, action) => {
  if (action.type === 'UPDATE_TEXT_FIELD') {
    state = state || {};
    state[action.questionId] = {
      answer: action.answer,
      type: questionTypes.text,
    };
  }

  if (action.type === 'UPDATE_CHOICE_FIELD') {
    state = state || {};
    state[action.questionId] = {
      answer: action.answer,
      choiceId: action.choiceId,
      type: questionTypes.choice,
    };
  }

  if (action.type === 'UPDATE_ADDRESS') {
    state = state || {};
    state[action.questionId] = state[action.questionId] || {};
    Object.assign(state[action.questionId], action.component);
    state[action.questionId]['type'] = questionTypes.address;
  }

  return state;
};

export { accountEditStateReducer };
