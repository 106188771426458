import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as colors from '../../lib/colors';
import { DESKTOP } from '../../lib/constants';
import { MONTSERRAT } from '../../lib/fonts';
import { Flex } from '../../sharedComponents/layoutHelpers';
import { OptionButton } from './OptionButton';
import { OptionBody } from './OptionBody';
import { Button } from './Button';

const styles = StyleSheet.create({
  radioButtonContainer: {
    marginBottom: -10,
    marginRight: 20,
    outline: 'none',
  },
  buttonContainer: {
    boxSizing: 'border-box',
    marginLeft: 20,
    marginTop: -1,
    minWidth: 100,
  },
  textInputContainer: {
    marginLeft: 20,
  },
  textInput: {
    borderColor: colors.LIGHT_GREY,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    fontFamily: MONTSERRAT,
    fontSize: '1em',
    fontWeight: 200,
    height: 26,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

class DesktopCustomOption extends Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.submitBtnForwardedRef = React.createRef();
  }

  state = { active: false };

  // The check for this.input.current is inexplicable but required.
  // Literally no clue why that ref is not set the first time these methods are called
  // or how it is that these methods consistently get called more than once so that the check does not prevent focus
  componentDidMount() {
    if (this.props.isFocus && this.input.current) this.input.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFocus && !!this.input.current && !prevProps.isFocus) {
      this.input.current.focus();
    }
  }

  handleMouseOver = () => {
    this.props.onFocus();
    this.setState({ active: true });
  };
  handleMouseOut = () => {
    this.setState({ active: false });
  };

  handleTextInputKeyDown = (e) => {
    switch (e.key) {
      case 'Tab':
        e.preventDefault();
        this.props.onKeyDown(e);
        break;

      case 'Spacebar':
      case ' ':
        e.stopPropagation();
        if (!this.props.value) e.preventDefault();
        break;

      case 'Enter':
        e.preventDefault();
        if (this.props.value) this.props.onClick();
        break;

      default:
        break;
    }
  };

  render() {
    const {
      body,
      displayBottomMargin,
      isFocus,
      isGreyedOut,
      onChange,
      onClick,
      onFocus,
      onSubmit,
      pending,
      selected,
      showAnswer,
      shape,
      value,
    } = this.props;

    return (
      <div style={{ marginBottom: displayBottomMargin ? 20 : 0 }}>
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          onMouseOut={this.handleMouseOut}
          onMouseOver={this.handleMouseOver}
        >
          <div
            className={css(styles.radioButtonContainer)}
            onClick={() => {
              onClick();
            }}
            tabIndex="0"
          >
            <OptionButton
              isActive={this.state.active || isFocus}
              isChecked={selected || pending}
              shape={shape}
            />
          </div>
          {showAnswer ? (
            <span>{value}</span>
          ) : (
            <Flex alignItems="center" justifyContent="flex-start">
              <div
                onClick={() => {
                  onClick();
                }}
              >
                <OptionBody isGreyedOut={isGreyedOut} text={body + ':'} />
              </div>
              <div className={css(styles.textInputContainer)}>
                <input
                  className={css(styles.textInput)}
                  ref={this.input}
                  onChange={onChange}
                  onClick={onFocus}
                  onKeyDown={this.handleTextInputKeyDown}
                  value={value}
                />
              </div>
              <div className={css(styles.buttonContainer)}>
                <Button
                  buttonRef={this.submitBtnForwardedRef}
                  fontSize={16}
                  height={30}
                  isActive={pending}
                  label={'SUBMIT'}
                  onClick={() => {
                   onSubmit();
                  }}
                  onKeyDown={() => null}
                  tabIndex={0}
                  viewport={DESKTOP}
                />
              </div>
            </Flex>
          )}
        </Flex>
      </div>
    );
  }
}

DesktopCustomOption.propTypes = {
  body: PropTypes.string.isRequired,
  displayBottomMargin: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  selected: PropTypes.bool,
  shape: PropTypes.string,
  showAnswer: PropTypes.bool,
  value: PropTypes.string,
};

DesktopCustomOption.defaultProps = {
  isFocus: false,
  pending: false,
  selected: false,
  showAnswer: false,
  value: '',
};

export { DesktopCustomOption };
