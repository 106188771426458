import PropTypes from 'prop-types';
import { Component } from 'react';
import { throttle } from '../lib/throttle';

class Mobilizer extends Component {
  constructor(props) {
    super(props);

    this.handleResize = throttle(this.handleResize.bind(this), this.props.refreshRate);
  }
  componentDidMount() {
    this.props.onResize(window.innerWidth);
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if(window.innerWidth === this.windowWidth) return;
    this.windowWidth = window.innerWidth;
    this.props.onResize(window.innerWidth);
  }

  render() {
    return null;
  }
}

Mobilizer.propTypes = {
  refreshRate: PropTypes.number,
  onResize: PropTypes.func.isRequired
};

Mobilizer.defaultProps = {
  refreshRate: 100
};

export { Mobilizer }