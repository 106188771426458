import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { MONTSERRAT } from '../../lib/fonts';
import { BLUE } from '../../lib/colors';
import { MOBILE } from '../../lib/constants';

let styles = StyleSheet.create({
  disclosure: {
    color: BLUE,
    fontFamily: MONTSERRAT,
    fontSize: '0.8em',
    fontWeight: 400,
    lineHeight: '1.57em',
    marginTop: 12,
  },
  disclosureMobile: {
    fontSize: '0.6em',
    paddingBottom: 14,
    marginTop: 11,
  },
});

const CcpaDisclosure = ({ viewport }) => {
  return (
    <p
      className={css(
        styles.disclosure,
        viewport === MOBILE && styles.disclosureMobile
      )}
    >
      Civiqs will send you a new poll every few weeks. You can unsubscribe any
      time.
      <span children={' '} />
      <a
        href="/privacy"
        style={{
          color: BLUE,
        }}
        target="_blank"
      >
        For California residents
      </a>
    </p>
  );
};

CcpaDisclosure.propTypes = {
  renderType: PropTypes.string,
  viewport: PropTypes.string.isRequired,
};

export { CcpaDisclosure };
