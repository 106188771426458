import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite-jss';
import * as selectors from './selectors';
import { MOBILE, NAV_HEIGHT } from '../lib/constants';
import { mobileDeviceIsSmall } from '../lib/mobileDeviceIsSmall';
import { MONTSERRAT } from '../lib/fonts';
import * as colors from '../lib/colors';
import * as scroll from '../lib/scroll';

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.DIVIDER_COLOR,
  },
  containerMobile: {
    height: window.innerHeight,
    lineHeight: window.innerHeight,
    textAlign: 'center',
    position: 'relative',
  },
  button: {
    backgroundColor: colors.FRENCH_GREY,
    color: colors.WHITE,
    fontSize: 28,
    fontFamily: MONTSERRAT,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    border: `12px solid ${colors.WHITE}`,
    width: '100%',
    height: 96,
    lineHeight: '70px',
    textAlign: 'center',
    outline: 'none',
  },
  buttonMobile: {
    height: 72,
    lineHeight: '45px',
    fontSize: 18,
    boxSizing: 'border-box',
    width: '100%',
    borderLeft: '8px solid white',
    borderRight: '8px solid white',
    marginTop: mobileDeviceIsSmall() ? 0 : -1 * (NAV_HEIGHT / 2 + 43),
    position: 'absolute',
    top: '50%',
  },
  buttonActive: {
    backgroundColor: colors.BLUE,
    cursor: 'pointer',
    ':active': {
      backgroundColor: colors.TUNA_GREY,
    },
  }
});

class _SubmitButton extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      Preform smooth scroll when all questions on the poll have been either answered or skipped
      and if new response is submitted on complete poll
    */
    if (this.props.viewedAllQuestions
      && this.props.responses.length > prevProps.responses.length)
    {
      scroll.center(this.buttonRef.current).then(_ => this.buttonRef.current.focus())
    }
  }

  handleClick(e) {
    e.preventDefault();
    if (!this.props.pollIsReadyToSubmit) return;
    this.props.onClick();
  }

  handleKeyDown(e) {
    e.preventDefault();
    if (e.which === 13 || e.keyCode === 13) {
      // Enter key
      if (this.props.pollIsReadyToSubmit) this.props.onClick();
    }
  }

  render() {
    return (
      <div
        className={css(
          styles.container,
          this.props.viewport === MOBILE && styles.containerMobile
        )}
        onClick={this.handleClick}
      >
        <div
          className={css(
            styles.button,
            this.props.viewport === MOBILE && styles.buttonMobile,
            this.props.pollIsReadyToSubmit && styles.buttonActive,
           )}
          onKeyDown={this.handleKeyDown}
          ref={this.buttonRef}
          tabIndex={0}
        >
          SUBMIT YOUR ANSWERS
        </div>
      </div>
    );
  }
}

_SubmitButton.propTypes = {
  viewport: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ poll }) => {
  return {
    viewedAllQuestions: selectors.viewedAllQuestions(poll),
    pollIsReadyToSubmit: selectors.pollIsReadyToSubmit(poll),
    responses: poll.responses,
  }
}

export const SubmitButton = connect(mapStateToProps, null)(_SubmitButton);
