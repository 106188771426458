import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet } from 'aphrodite-jss';
import { Flex } from '../../sharedComponents/layoutHelpers';
import * as colors from '../../lib/colors';
import { MOBILE_PADDING_HORIZ } from '../../lib/constants';

const styles = StyleSheet.create({
  container: {
    minHeight: 42,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.DIVIDER_COLOR,
    paddingRight: MOBILE_PADDING_HORIZ,
    paddingLeft: MOBILE_PADDING_HORIZ,
    paddingTop: 4,
    paddingBottom: 6,
  },
});

const MobileOptionWrapper = ({
  children,
  onClick,
  onTouchEnd,
  onTouchStart,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      style={styles.container}
    >
      {children}
    </Flex>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func.isRequired,
};

export { MobileOptionWrapper };
