import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { some, sortBy } from 'lodash';
import * as colors from '../../lib/colors';
import { MobileOption } from '../sharedComponents/MobileOption';
import { Button } from '../sharedComponents/Button';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    bottom: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.DIVIDER_COLOR,
    paddingBottom: 45,
  },
  optionsContainer: {
    marginBottom: '0.75em',
  },
  submitBtnContainer: {
    marginBottom: '0.75em',
    width: '100%',
    float: 'none',
    paddingLeft: 32,
    paddingRight: 32,
  },
  floatingBottomBorder: {
    height: 1,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.DIVIDER_COLOR,
  },
});

const MobileMultipleOptions = (props) => {
  const {
    choices,
    isCurrent,
    onOptionClick,
    onSubmit,
    maxChoiceCount,
  } = props;

  const submitBtnForwardedRef = useRef(null);

  useEffect(() => {
    props.initOptionOrder();
  }, []);

  const hasAnyResponse = () => {
    return some(props.choices, (c) => c.selected === true);
  };

  const numSelectedChoices = choices.reduce((acc, curr) => {
    return curr.selected ? acc + 1 : acc;
  }, 0);

  const choicesToDisplay = sortBy(choices, ['order']);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.optionsContainer)}>
        {choicesToDisplay.map((choice, index) => {
          const maxSelectionsReached = numSelectedChoices >= maxChoiceCount;

          return (
            <MobileOption
              {...choice}
              isGreyedOut={maxSelectionsReached}
              key={index}
              onClick={() => {
                if (maxSelectionsReached && !choice.selected) return;
                onOptionClick(choice.id);
              }}
              shape={'square'}
            />
          );
        })}
      </div>
      <div className={css(styles.submitBtnContainer)}>
        <Button
          buttonRef={submitBtnForwardedRef}
          onClick={() => {
            if (hasAnyResponse()) onSubmit();
          }}
          isActive={hasAnyResponse() && isCurrent}
          label={'Submit'}
        />
      </div>
      <div className={css(styles.floatingBottomBorder)}></div>
    </div>
  );
};

MobileMultipleOptions.propTypes = {
  choices: PropTypes.array.isRequired,
  maxChoiceCount: PropTypes.number,
  initOptionOrder: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { MobileMultipleOptions };
