import mixpanel from '../../lib/mixpanel';
import * as pollActions from '../../poll/actions';
import * as actionTypes from './actionTypes';

export const checkOption = (questionId, checkedOptionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHECK_OPTION,
      id: questionId,
      optionId: checkedOptionId,
    });
    dispatch(pollActions.dispatchSkipForAllPrevNonStickyQuestions(questionId));
  };
};

export const uncheckOption = (questionId, uncheckedOptionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UNCHECK_OPTION,
      id: questionId,
      optionId: uncheckedOptionId,
    });
  };
};

export const saveMultiOptionResponse = (questionId, questionBody, choices) => {
  const selectedChoices = choices.filter(c => c.selected);
  const selectedChoiceAnswers = selectedChoices.map((c) => c.body).join(', ');
  const selectedChoiceIds = selectedChoices.map((c) => c.id);

  return (dispatch) => {
     dispatch({
      id: questionId,
      response: selectedChoiceIds,
      question: questionBody,
      type: actionTypes.SAVE_RESPONSE,
    });
    dispatch(pollActions.saveResponse(questionId, questionBody, {
      answer: selectedChoiceAnswers,
      multiChoiceIds: selectedChoiceIds,
    }));
    dispatch(pollActions.setCurrentQuestionAutomatically());
    mixpanel.track('responded', {
      question: questionBody,
      multiChoiceIds: selectedChoiceIds,
    });
  };
};

export const reverseOptions = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVERSE_OPTIONS,
      id: questionId,
    });
  };
};

export const shuffleOptions = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHUFFLE_OPTIONS,
      id: questionId,
    });
  };
};
