import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { Button, Flex, Text } from '@rebass/emotion';
import { connect } from 'react-redux';
import * as appActions from './appActions';
import { BLUE, DARK_BLUE, DARK_GREY, MEDIUM_GREY, WHITE } from './lib/colors';
import { TISA, MONTSERRAT } from './lib/fonts';
import { Row } from './sharedComponents/layoutHelpers';
import TextInput from './sharedComponents/formHelpers/TextInput';
import Dropdown from './sharedComponents/formHelpers/Dropdown';
import { useForm } from '@formspree/react';
import { validate } from './lib/emailValidator';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    color: DARK_GREY,
    fontFamily: TISA,
    fontSize: '0.8em',
    fontWeight: 300,
    fontFamily: MONTSERRAT,
    width: '100%',
  },
  banner: {
    backgroundColor: DARK_BLUE, //'rgb(42, 53, 70)',
    minHeight: '220px',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
  },
  pageTitle: {
    color: WHITE,
    fontWeight: 400,
    fontSize: '2em',
  },
  gridContainer: {
    maxWidth: 840,
    margin: '0 auto',
    padding: 25,
  },
  formContainer: {
    paddingTop: 25,
  },
  submitButton: {
    backgroundColor: BLUE,
    borderRadius: 5,
    color: WHITE,
  },
});

const _ReportAnIssueForm = ({ allowMainContentOverflow }) => {
  useEffect(() => {
    allowMainContentOverflow();
  }, []);

  const [formSpreeState, formSpreeHandleSubmit] = useForm(window.civiqs.config.FORMSPREE_HASH_ID);

  const initialState = {
    description: '',
    email: '',
    showDescriptionInvalidModal: false,
    showEmailInvalidModal: false,
  }

  const reducer = (currentState, newState) => {
    return {...currentState, ...newState}
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  }

  const showInvalidModals = () => {
    if (state.description === '') {
      dispatch({ showDescriptionInvalidModal: true })
    } else if (!emailIsValid(state.email)) {
      dispatch({ showEmailInvalidModal: true })
    }
  }

  const formIsValidForSubmission = () => {
    return state.description && emailIsValid(state.email);
  }

  const emailIsValid = (email) => {
    if (email === '') return true;
    return validate(email);
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.banner)}>
        <h3 className={css(styles.pageTitle)}>Report An Issue</h3>
      </div>
      <div className={css(styles.gridContainer)}>
        <Text fontSize={[1, 2]} mb={14}>
          We’re sorry that our system isn’t working as expected. Please answer the questions below to help us fix the problem. Thank you for reporting this issue!
        </Text>
        <div className={css(styles.formContainer)}>
          <form
            action='https://formspree.io/f/mjvjvngd'
            encType='multipart/form-data'
            method='POST'
            name='report_an_issue_form'
            onSubmit={formSpreeHandleSubmit}
          >
            <Flex
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              flexDirection={'row'}
              flexWrap={'wrap'}
            >
              <Text fontSize={[2, 3]} mt={20} mb={10}>
               Description
              </Text>
              <Row>
                <TextInput
                  id='report-an-issue-description'
                  invalidInputMessage={ 'Please enter a description.' }
                  isValid={true}
                  label='What happens when you try to access or take the Civiqs survey? If you can take a screenshot (an image of what you are seeing), please upload it here.'
                  name='description'
                  onChange={handleTextInputChange}
                  onFocus={() => {
                    dispatch({ showDescriptionInvalidModal: false })
                  }}
                  rows={2}
                  showModal={state.showDescriptionInvalidModal}
                  type='text'
                  value={state.description}
                />
                <input type='file' name='attachment' accept='image/png, image/jpeg'></input>
              </Row>
              <Text fontSize={[2, 3]} mt={20} mb={10}>
               Browser
              </Text>
              <Row>
                <Dropdown
                    id='report-an-issue-browser'
                    label={'What browser are you using? Please note that Civiqs no longer supports Internet Explorer.'}
                    name='browser'
                    options={[
                      { label: 'Safari', value: 'safari' },
                      { label: 'Chrome', value: 'chrome' },
                      { label: 'Firefox', value: 'firefox' },
                      { label: 'Edge', value: 'edge' },
                    ]}
                  />
                  <TextInput
                    id='report-an-issue-browser-version'
                    isValid={true}
                    label='What version of the browser are you using? (If you are not sure, you can leave this blank)'
                    name='version'
                    onChange={handleTextInputChange}
                    type='text'
                  />
                  <TextInput
                    id='report-an-issue-browser-extensions'
                    isValid={true}
                    label='Please list any browser extensions you are using?  (If you are not sure, you can leave this blank)'
                    name='extensions'
                    onChange={handleTextInputChange}
                    type='text'
                  />
              </Row>
              <Text fontSize={[2, 3]} mt={20} mb={10}>
               Device
              </Text>
              <Row>
                <Dropdown
                    id='report-an-issue-device'
                    label={'What type of device are you using?'}
                    name='device'
                    options={[
                      { label: 'Phone', value: 'phone' },
                      { label: 'Computer', value: 'phone' },
                      { label: 'Tablet', value: 'phone' },
                      { label: 'Other', value: 'phone' },
                      { label: 'I don\'t know', value: 'I dont\'t know ' },
                    ]}
                  />
                  <TextInput
                    id='report-an-issue-device-model'
                    isValid={true}
                    label='What model is your device? (For example, iPhone X, Pixel 5, MacBook Air, Dell…)'
                    name='model'
                    onChange={handleTextInputChange}
                    type='text'
                  />
                  <TextInput
                    id='report-an-issue-device-os'
                    isValid={true}
                    label='What operating system is your device running?'
                    name='os'
                    onChange={handleTextInputChange}
                    type='text'
                  />
              </Row>
              <Text fontSize={[2, 3]} mt={20} mb={10}>
               Contact
              </Text>
              <Row>
                <TextInput
                  id='report-an-issue-contact-email'
                  invalidInputMessage={'Please enter a valid email.'}
                  isValid={emailIsValid(state.email)}
                  label='Please share your email with us in case we need to follow up with you.'
                  name='email'
                  onChange={handleTextInputChange}
                  onFocus={() => {
                    dispatch({ showEmailInvalidModal: false })
                  }}
                  showModal={state.showEmailInvalidModal}
                  type='text'
                  value={state.email}
                />
              </Row>

              <Row>
                <Button
                  bg={
                    formIsValidForSubmission() && !formSpreeState.submitting
                      ? 'rgb(13, 130, 223)'
                      : MEDIUM_GREY
                  }
                  borderRadius={4}
                  border={'none'}
                  fontWeight={700}
                  onClick={(e) => {
                    e.preventDefault();
                    if (formSpreeState.submitting) return;
                    if (formIsValidForSubmission()) {
                      document.forms[0].submit()
                    } else {
                      showInvalidModals();
                    }
                  }}
                  type='submit'
                  width={1}
                >
                    Submit
                </Button>
              </Row>
            </Flex>
            <input type="hidden" name="_next" value={`${window.location.origin}/report-an-issue/thankyou`} />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    allowMainContentOverflow: () => {
      dispatch(appActions.allowMainContentOverflow());
    },
  }
};

const ReportAnIssueForm = connect(
  null,
  mapDispatchToProps
)(_ReportAnIssueForm);

export { ReportAnIssueForm };
