import { combineReducers } from 'redux';
import * as Model from '../lib/model';
import * as actionTypes from './actionTypes';
import { highlightStateReducer } from './highlight/reducer';
import { optionsQuestionStateReducer } from '../inputs/options/reducer';
import { locationQuestionStateReducer } from '../inputs/location/reducer';
import { emailQuestionStateReducer } from '../inputs/email/reducer';
import { textQuestionStateReducer } from '../inputs/text/reducer';
import { zipcodeQuestionStateReducer } from '../inputs/zipcode/reducer';
import { birthYearQuestionStateReducer } from '../inputs/birthYear/reducer';
import { numericQuestionStateReducer } from '../inputs/numeric/reducer';
import { multipleOptionsQuestionStateReducer } from '../inputs/multipleOptions/reducer';

const emailSubmittedReducer = (state = false, action) => {
  if (action.type === actionTypes.MARK_EMAIL_SUBMITTED) {
    return !!action.body;
  }
  return state;
};

const geoCoordinatesReducer = (state = null, action) => {
  if (action.type === actionTypes.SET_GEOCOORDINATES) return action.coordinates;
  if (action.type === actionTypes.UNSET_GEOCOORDINATES) return null;
  return state;
};

const emailVariationIdReducer = (state = null, action) => {
  if (action.type === actionTypes.SET_POLL_METADATA) {
    return action.emailVariationId;
  }
  return state;
}

const impressionIdReducer = (state = null, action) => {
  if (action.type === actionTypes.SET_POLL_METADATA) {
    return action.impressionId;
  }
  return state;
};

const pollIdReducer = (state = null, action) => {
  if (action.type === actionTypes.SET_POLL_METADATA) {
    return action.pollId;
  }
  return state;
};

const pollTypeReducer = (state = null, action) => {
  if (action.type === actionTypes.SET_POLL_METADATA) {
    return action.pollType;
  }
  return state;
};

const previewModeReducer = (state = false, action) => {
  if (action.type === actionTypes.ACTIVATE_PREVIEW_MODE) return true;
  return state;
};

const questionsReducer = (state = [], action) => {
  if (action.type === actionTypes.LOAD_QUESTION_DATA) {
    return action.questions;
  } else {
    state = Model.getQuestionsBucketedByRenderTypes(state);
    state = questionInputReducer(state, action);
    state = Model.getQuestionsOrderedByIndex(state);
    state = setCurrentQuestionReducer(state, action);

    return state;
  }
};

const noopReducer = (state = [], action) => {
  return state;
};

const questionInputReducer = combineReducers({
  birth_year: birthYearQuestionStateReducer,
  email: emailQuestionStateReducer,
  image: optionsQuestionStateReducer,
  location: locationQuestionStateReducer,
  multi_options: multipleOptionsQuestionStateReducer,
  numeric: numericQuestionStateReducer,
  options: optionsQuestionStateReducer,
  structured_location: noopReducer,
  text: textQuestionStateReducer,
  zipcode: zipcodeQuestionStateReducer,
});

const setCurrentQuestionReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_QUESTION_BY_ID:
      return state.map((q) => {
        return q.id === action.id
          ? { ...q, isCurrent: true }
          : { ...q, isCurrent: false };
      });

    case actionTypes.SET_CURRENT_QUESTION_AUTOMATICALLY:
      const firstUnansweredQuestion = state.find((q) => !q.isAnswered);

      return state.map((q) => {
        if (q === firstUnansweredQuestion) {
          return { ...q, isCurrent: true };
        } else {
          return { ...q, isCurrent: false };
        }
      });

    case actionTypes.UNSET_CURRENT_QUESTION:
      return state.map((q) => ({ ...q, isCurrent: false }));

    default:
      return state;
  }
};

const removeItemWithId = (items, id) => {
  return items.filter((item) => item.id !== id);
};

const responsesReducer = (state = [], action) => {
  if (action.type === actionTypes.SAVE_RESPONSE) {
    state = removeItemWithId(state, action.id);
    state.push({
      id: action.id,
      question: action.question,
      response: action.response,
    });

    return state;
  }

  if (action.type === actionTypes.SKIP_QUESTION) {
    state = removeItemWithId(state, action.id);
    return state;
  }

  if (action.type === actionTypes.CLEAR_RESPONSES) {
    return [];
  }

  return state;
};

const skipsReducer = (state = [], action) => {
  if (action.type === actionTypes.SKIP_QUESTION) {
    state = removeItemWithId(state, action.id);
    state.push({
      id: action.id,
      question: action.question,
    });
    return state;
  }
  if (action.type === actionTypes.SAVE_RESPONSE) {
    state = removeItemWithId(state, action.id);
    return state;
  }
  return state;
};

const submittedReducer = (state = false, action) => {
  if (action.type === actionTypes.MARK_POLL_SUBMITTED) return true;
  if (action.type === actionTypes.CLEAR_RESPONSES) {
    return false;
  }
  return state;
};

export const pollStateReducer = combineReducers({
  emailSubmitted: emailSubmittedReducer,
  emailVariationId: emailVariationIdReducer,
  geoCoordinates: geoCoordinatesReducer,
  highlight: highlightStateReducer,
  id: pollIdReducer,
  impressionId: impressionIdReducer,
  previewMode: previewModeReducer,
  pollType: pollTypeReducer,
  questions: questionsReducer,
  responses: responsesReducer,
  skips: skipsReducer,
  submitted: submittedReducer,
});
