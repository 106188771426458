import React, { useContext } from 'react';
import { MOBILE, DESKTOP, TABLET } from '../../lib/constants';
import { ViewportContext } from '../../viewportContext';
import { MobileOptions } from './MobileOptions';
import { DesktopOptions } from './DesktopOptions';

const OptionsInput = (props) => {
  const viewport = useContext(ViewportContext);

  switch (viewport) {
    case MOBILE:
      return <MobileOptions {...props} />;
    case DESKTOP:
    case TABLET:
      return <DesktopOptions {...props} />;
    default:
      throw new Error('Unrecognized value for viewport');
  }
};

export { OptionsInput };
