import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, css } from 'aphrodite-jss';
import { sortBy } from 'lodash';
import * as colors from '../../lib/colors';
import { MobileOption } from '../sharedComponents/MobileOption';
import { MobileCustomOption } from '../sharedComponents/MobileCustomOption';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    bottom: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.DIVIDER_COLOR,
    paddingBottom: 45,
  },
  floatingBottomBorder: {
    height: 1,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.DIVIDER_COLOR,
  },
});

const MobileOptions = (props) => {
  const {
    choices,
    onCustomOptionChange,
    onOptionClick,
  } = props;

  useEffect(() => {
    props.initOptionOrder();
  }, []);

  const globalState = useSelector((state) => state);

  const choicesToDisplay = sortBy(choices, ['order']);

  return (
    <div className={css(styles.container)}>
      {choicesToDisplay.map((choice, index) => {
        const { customValue, isCustomOtherChoice, ...restProps } = choice;
        const { id } = restProps;

        return isCustomOtherChoice ? (
          <MobileCustomOption
            {...restProps}
            key={index}
            onChange={(e) => {
              onCustomOptionChange(id, e.target.value);
            }}
            onClick={() => {
              onOptionClick(id);
            }}
            shape={'circle'}
            value={customValue}
          />
        ) : (
          <MobileOption
            {...restProps}
            key={index}
            onClick={() => {
              onOptionClick(id);
            }}
            shape={'circle'}
          />
        );
      })}
      <div className={css(styles.floatingBottomBorder)}></div>
    </div>
  );
};

MobileOptions.propTypes = {
  choices: PropTypes.array.isRequired,
  initOptionOrder: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onCustomOptionChange: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  setOptionPending: PropTypes.func.isRequired,
};

export { MobileOptions };