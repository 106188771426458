export const WHITE ='#ffffff';
export const BLUE = '#6178A8'; //RGB: 97, 120, 168 aka Waikawa Blue
export const MEDIUM_BLUE = '#435578';
export const DARK_BLUE = '#435578';
export const RED = '#CC5E5E'; //RGB: 204, 94, 94 aka Chestnut Rose
export const HIGHTLIGHT_YELLOW = '#FFFDEB' // RGB: 255, 253, 235 aka Buttery WHite

// Aliases for blue shades
export const EBONY_CLAY = '#435578';
export const EAST_BAY = '#435578';

// Relative quality aliases for grey shades
export const ULTRALIGHT_GREY = '#EEEEEE'; //RGB: 238, 238, 238
export const LIGHTER_GREY = '#CCCCCC'; //RGB: 202, 202, 204
export const LIGHT_GREY = '#CACACC'; //RGB: 202, 202, 204
export const MEDIUM_LIGHT_GREY = '#AAAAAA'; //RGB: 170,170, 170
export const MEDIUM_GREY = '#8F929B'; //RGB: 143, 146, 155
export const DARK_GREY = '#4D4D4D'; //RGB: 77, 77, 77
export const ULTRADARK_GREY = '#393d44'; //RGB: 57, 61, 68

// Crayola aliases for grey shades (http://chir.ag/projects/name-that-color/)
export const GALLERY_GREY = '#EEEEEE'; //RGB: 238, 238, 238
export const SILVER = '#CCCCCC'; //RGB: 204, 204, 204
export const FRENCH_GREY = '#CACACC'; //RGB: 202, 202, 204
export const SILVER_CHALICE = '#AAAAAA'; //RGB: 170,170, 170
export const MANATEE_GREY = '#8F929B'; //RGB: 143, 146, 155
export const TUNDORA_GREY = '#4D4D4D'; //RGB: 77, 77, 77
export const TUNA_GREY = '#393d44'; //RGB: 57, 61, 68
export const DIVIDER_COLOR = 'rgba(202, 202, 204, .5)'