import { namespace } from './namespace';

export const CLEAR_CITY_STATE = namespace + '/UNSET_CITY_STATE';
export const HAS_PROCESSED_GEO_COORDINATES = namespace + '/HAS_MAPPED_GEOCOORDINATES';
export const HIDE_MAP = namespace + '/HIDE_MAP';
export const SET_ANSWER = namespace + '/SET_ANSWER';
export const SHOW_MAP = namespace + '/SHOW_MAP';
export const SHOW_ERROR_MESSAGE = namespace + '/SHOW_ERROR_MESSAGE';
export const SHOW_HOME_ADDRESS_MESSAGE = namespace + '/SHOW_HOME_ADDRESS_MESSAGE';
export const UNSET_ANSWER = namespace + '/UNSET_ANSWER';
export const UPDATE_CITY_STATE = namespace + '/UPDATE_CITY_STATE';
