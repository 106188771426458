import * as actionTypes from './actionTypes';

export const numericQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch(action.type) {
      case actionTypes.UPDATE_NUMBER:
        return {...q, number: action.number, errorMsg: '', isAnswered: false};
      case actionTypes.VALIDATE_NUMBER_FAILURE:
        return {...q, errorMsg: action.errorMsg};
      case actionTypes.SAVE_NUMBER:
        return {...q, number: action.number, isAnswered: true, errorMsg: ''};
      default:
        return q;
    }
  });
};
