import * as actionTypes from './actionTypes';
import * as pollActions from '../../poll/actions';
import mixpanel from '../../lib/mixpanel';

export const updateYear = (questionId, year) => {
  return {
    id: questionId,
    year: year,
    type: actionTypes.UPDATE_YEAR,
  };
};

export const saveYear = (questionId, questionBody, year) => {
  return (dispatch) => {
    dispatch({
      id: questionId,
      year: year,
      type: actionTypes.SAVE_YEAR,
    });
    dispatch(
      pollActions.saveResponse(questionId, questionBody, { answer: year })
    );

    mixpanel.track('provided_birth_year', {
      question: questionBody,
      birth_year: year,
      answer: year,
    });
  };
};

export const validateYearFailure = (questionId, errorMsg) => {
  return {
    id: questionId,
    errorMsg: 'Please enter a valid four digit birth year (e.g. 1974).',
    type: actionTypes.VALIDATE_YEAR_FAILURE,
  };
};

export const submitYear = (questionId, questionBody, year) => {
  const date = new Date();
  year = year.replace(/\s/g, '');
  const isValidYear = (year) =>
    /^\d{4}$/.test(year) && year < date.getFullYear() && year > 1900;

  return (dispatch) => {
    const valid = isValidYear(year);
    if (valid) {
      dispatch(saveYear(questionId, questionBody, year));
      dispatch(pollActions.setCurrentQuestionAutomatically());
    } else {
      dispatch(validateYearFailure(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};

export const autoSubmitYear = (questionId, questionBody, year) => {
  const date = new Date();
  year = year.replace(/\s/g, '');
  const isValidYear = (year) =>
    /^\d{4}$/.test(year) && year < date.getFullYear() && year > 1900;

  return (dispatch) => {
    const valid = isValidYear(year);
    if (valid) {
      dispatch(saveYear(questionId, questionBody, year));
    } else {
      dispatch(validateYearFailure(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};
