import React from 'react';
import { TISA } from '../../lib/fonts';
import { TUNDORA_GREY } from '../../lib/colors';

const styles = {
  fontFamily: TISA,
  fontSize: '1em',
  color: TUNDORA_GREY,
};

const Instruction = ({ children }) => {
  return <p style={styles}>{children}</p>;
};

export { Instruction };
