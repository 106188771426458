import React from 'react';
import PropTypes from 'prop-types';
import * as colors from '../lib/colors';
import {
  Container,
  Hero,
  ThreeStars,
  SurveyCompletedLink,
  SurveyCompletedText,
} from './layoutHelpers';

const LandingPage = (props) => {
  return (
    <Container>
      <Hero>
        {props.bannerContent}
        <ThreeStars />
        <ul style={{ listStyleType: 'none' }}>
          <li style={{ padding: '25px 0' }}>
            <SurveyCompletedLink href="/">
              <span>Survey Results</span>
            </SurveyCompletedLink>
          </li>
          {(props.instructionalContent)
            ? <li style={{ padding: '25px 0' }}>
                {props.instructionalContent}
              </li>
            : null
          }
          <li style={{ padding: '25px 0' }}>
            <SurveyCompletedText>
              Spread the word! Give more people a way to make their voices
              heard:
            </SurveyCompletedText>
            <div style={{ marginTop: '20px' }}>
              <a
                href="https://twitter.com/intent/tweet?text=Join me on the @Civiqs research panel! Civiqs makes your views heard. You receive periodic emails when a new poll is ready. Most surveys take less than a minute. That’s it!&url=https://civiqs.com/join-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="fab fa-twitter-square"
                  style={{
                    fontSize: '45px',
                    color: colors.ULTRALIGHT_GREY,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                ></span>
              </a>
              <a
                href="mailto:?subject=Civiqs surveys&body=Hi,%0D%0A%0D%0AJoin me on the Civiqs research panel! Civiqs makes your views heard. You receive periodic emails when a new poll is ready. Most surveys take less than a minute. That’s it! https://civiqs.com/join-in%0D%0A%0D%0A Check out the results of Civiqs surveys with new results updated every day: https://civiqs.com/results%0D%0A%0D%0AThanks!"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="fas fa-envelope-square"
                  style={{
                    fontSize: '45px',
                    color: colors.ULTRALIGHT_GREY,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                ></span>
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=civiqs.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="fab fa-facebook-square"
                  style={{
                    fontSize: '45px',
                    color: colors.ULTRALIGHT_GREY,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                ></span>
              </a>
            </div>
          </li>
        </ul>
      </Hero>
    </Container>
  );
};

LandingPage.propTypes = {
  bannerContent: PropTypes.node,
  instructionalContent: PropTypes.node,
};

LandingPage.defaultProps = {
  bannerContent: null,
  instructionalContent: null
}

export default LandingPage;
export { LandingPage };
