import React, {Component} from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { DESKTOP, NAV_HEIGHT } from './lib/constants';
import { TISA } from './lib/fonts';
import * as colors from './lib/colors';
import { Flex } from './sharedComponents/layoutHelpers';
import spinner from './spinner-150px.svg';

export class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.tooLongTimeoutId = null;

    this.state = {
      takingTooLongToLoad: false
    }
  }

  componentDidMount () {
    this.tooLongTimeoutId = window.setTimeout(() => {
      this.setState({ takingTooLongToLoad: true });
    }, 2000);
  }

  componentWillUnmount () {
    window.clearTimeout(this.tooLongTimeoutId);
  }

  render () {
    const {viewport} = this.props;

    const styles = StyleSheet.create({
      loadingPage: {
        width: '100%',
        backgroundColor: colors.WHITE
      },
      loadingPageMobile: {
        height: window.innerHeight
      },
      spinner: {
        visibility: this.state.takingTooLongToLoad ? 'visible' : 'hidden'
      },
      loadingMsg : {
        fontSize: 30,
        fontFamily: TISA,
        textAlign: 'center',
        marginTop: -2 * NAV_HEIGHT,
        visibility: this.state.takingTooLongToLoad ? 'visible' : 'hidden'
      }
    });

    return(
      <div className={css(
        styles.loadingPage,
        viewport !== DESKTOP && styles.loadingPageMobile
        )}>
        <Flex
          flexFlow='column'
          justifyContent='center'
          alignItems='center'
          >
          <p className={css(styles.loadingMsg)}>Your survey is loading.</p>
          <embed
            src={spinner}
            className={css(styles.spinner)}
            />
        </Flex>
      </div>
    );
  }
}
