import React from 'react';
import BugsnagErrorBoundary from './bugsnag';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ViewportContextProvider } from './viewportContext';
import { AppPathContextProvider } from './appPathContext';
import {
  applyRouterMiddleware,
  browserHistory,
  IndexRoute,
  Redirect,
  Route,
  Router,
} from 'react-router';
import { useScroll } from 'react-router-scroll';

import * as appActions from './appActions';
import { App } from './App';
import { Ccpa } from './Ccpa';
import { DoNotSell } from './DoNotSell';
import { ThankYou } from './ThankYou';
import { ReportAnIssueForm } from './ReportAnIssueForm';
import { ReportAnIssueThankYou } from './ReportAnIssueThankYou';
import { Closed }  from './Closed';
import { ResubConfirmation } from './ResubConfirmation';
import { AccountEditForm } from './accountEdit/stateContainer';
import * as pollActions from './poll/actions';
import { pollContainer as Poll } from './poll/stateContainer';

import { api } from './lib/api';
import { buildApiUrlFromPollLocationDetails } from './lib/apiHelpers';
import { initializeStore } from './lib/initializeStore';
import { gaTrackPageView } from './lib/gaTrackPageView';
import * as pollLocationDetails from './lib/pollLocationDetails';
import { UnsubscribeByEmail } from './UnsubscribeByEmail';
import { UnsubscribeComplete } from './UnsubscribeComplete';
import { ConfirmUnsubscribe } from './ConfirmUnsubscribe';

const store = initializeStore();

function updateTheUserInterface(wrappedResponse, _store) {
  let { responseObject, payload } = wrappedResponse;

  if (responseObject.status > 199 && responseObject.status < 300) {
    _store.dispatch(pollActions.renderPoll({ responseObject, payload }));
  } else if (responseObject.status > 499 && responseObject.status < 600) {
    _store.dispatch(appActions.showMaintenancePage());
    _store.dispatch(appActions.hideLoadingSpinner());
  } else {
    _store.dispatch(appActions.showErrorPage());
    _store.dispatch(appActions.hideLoadingSpinner());
  }

  return null;
}

const TestException = function () {
  throw("Test exception thrown in polling-frontend!");
}

ReactDOM.render(
  <BugsnagErrorBoundary>
    <Provider store={store}>
      <ViewportContextProvider _window={window}>
        <AppPathContextProvider>
          <Router
            history={browserHistory}
            render={applyRouterMiddleware(useScroll(() => [0, 0]))}
          >
            <Route path="/throw-from/polling_frontend" component={TestException} />
            <Route path="/throw-from/polling-frontend" component={TestException} />
            <Route path="/" component={App}>
              <IndexRoute
                component={Poll}
                onEnter={async () => {
                  try {
                    let wrappedResponse;

                    if (pollLocationDetails.isApiBackedPoll) {
                      let initialWrappedResponse = await api.issueApiRequest(
                        buildApiUrlFromPollLocationDetails(pollLocationDetails)
                      );
                      wrappedResponse = await api.recursivelyRedirect(
                        initialWrappedResponse
                      );
                    } else {
                      wrappedResponse = api.getMockedResponse(
                        pollLocationDetails
                      );
                    }

                    updateTheUserInterface(wrappedResponse, store);
                  } catch (error) {
                    store.dispatch(appActions.showErrorPage());
                  }
                }}
              />
              <Route path="preview">
                <IndexRoute
                  onEnter={() => {
                    window.location.href = '/';
                  }}
                />
                <Route
                  path="*"
                  component={Poll}
                  onEnter={async () => {
                    try {
                      const initialWrappedResponse = await api.issueApiRequest(
                        buildApiUrlFromPollLocationDetails(pollLocationDetails)
                      );
                      const wrappedResponse = await api.recursivelyRedirect(
                        initialWrappedResponse
                      );
                      updateTheUserInterface(wrappedResponse, store);
                    } catch (error) {
                      window.location.href = '/';
                    }
                  }}
                />
              </Route>
              <Route path="e">
                <IndexRoute
                  onEnter={() => {
                    window.location.href = '/';
                  }}
                />
                <Route
                  path="*"
                  component={AccountEditForm}
                  onEnter={async () => {
                    try {
                      let wrappedResponse;

                      if (pollLocationDetails.isApiBackedPoll) {
                        const initialWrappedResponse = await api.issueApiRequest(
                          buildApiUrlFromPollLocationDetails(
                            pollLocationDetails
                          )
                        );
                        wrappedResponse = await api.recursivelyRedirect(
                          initialWrappedResponse
                        );
                      } else {
                        wrappedResponse = api.getMockedResponse(
                          pollLocationDetails
                        );
                      }

                      updateTheUserInterface(wrappedResponse, store);
                    } catch (error) {
                      window.location.href = '/';
                    }
                  }}
                />
              </Route>
              <Route path="p">
                <IndexRoute
                  onEnter={() => {
                    window.location.href = '/';
                  }}
                />
                <Route
                  path="*"
                  component={Poll}
                  onEnter={async () => {
                    try {
                      let wrappedResponse;

                      if (pollLocationDetails.isApiBackedPoll) {
                        const initialWrappedResponse = await api.issueApiRequest(
                          buildApiUrlFromPollLocationDetails(
                            pollLocationDetails
                          )
                        );
                        wrappedResponse = await api.recursivelyRedirect(
                          initialWrappedResponse
                        );
                      } else {
                        wrappedResponse = api.getMockedResponse(
                          pollLocationDetails
                        );
                      }

                      updateTheUserInterface(wrappedResponse, store);
                    } catch (error) {
                      window.location.href = '/';
                    }
                  }}
                />
              </Route>
              <Route path="i">
                <IndexRoute
                  onEnter={() => {
                    window.location.href = '/';
                  }}
                />
                <Route
                  path="*"
                  component={Poll}
                  onEnter={async () => {
                    try {
                      let initialWrappedResponse;

                      if (pollLocationDetails.isApiBackedPoll) {
                        initialWrappedResponse = await api.issueApiRequest(
                          buildApiUrlFromPollLocationDetails(
                            pollLocationDetails
                          )
                        );
                      } else {
                        initialWrappedResponse = api.getMockedResponse(
                          pollLocationDetails
                        );
                      }

                      const { payload } = initialWrappedResponse;

                      if (payload.closed === true) {
                        window.location.href = '/closed';
                      } else {
                        const wrappedResponse = await api.recursivelyRedirect(
                          initialWrappedResponse
                        );
                        updateTheUserInterface(wrappedResponse, store);
                      }
                    } catch (error) {
                      window.location.href = '/';
                    }
                  }}
                />
              </Route>
              <Route
                path="ccpa"
                onEnter={() => {
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              >
                <IndexRoute component={Ccpa} />
                <Route path="do-not-sell" component={DoNotSell}>
                  <Route path="unwanted-email" />
                  <Route path="success" />
                </Route>
                <Route path="erase" component={Ccpa}>
                  <Route path="unwanted-email-thankyou" />
                  <Route path="success" />
                </Route>
              </Route>
              <Route
                path="closed"
                component={Closed}
                onEnter={() => {
                  gaTrackPageView('closed');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="report-an-issue"
                component={ReportAnIssueForm}
                onEnter={() => {
                  gaTrackPageView('report-an-issue');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="report-an-issue/thankyou"
                component={ReportAnIssueThankYou}
                onEnter={() => {
                  gaTrackPageView('report-an-issue/thankyou');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="confirmation"
                component={ResubConfirmation}
                onEnter={() => {
                  gaTrackPageView('resubscription confirmation');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="thankyou"
                component={ThankYou}
                onEnter={() => {
                  gaTrackPageView('thankyou');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              >
                <IndexRoute />
                <Route path="*" />
              </Route>
              <Route
                path="unsubscribe-by-email"
                component={UnsubscribeByEmail}
                onEnter={() => {
                  gaTrackPageView('unsubscribe-by-email');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="confirm-unsubscribe/:secure_token"
                component={ConfirmUnsubscribe}
                onEnter={() => {
                  gaTrackPageView('confirm-unsubscribe');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Route
                path="unsubscribe-complete"
                component={UnsubscribeComplete}
                onEnter={() => {
                  gaTrackPageView('unsubscribe-complete');
                  store.dispatch(appActions.hideLoadingSpinner());
                }}
              />
              <Redirect from="*" to="/" />
            </Route>
          </Router>
        </AppPathContextProvider>
      </ViewportContextProvider>
    </Provider>
  </BugsnagErrorBoundary>,
  document.getElementById('root')
);
