import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { DESKTOP } from '../../lib/constants';
import { Button } from '../sharedComponents/Button';
import { tabbableElements } from './tabbableElements';

const { TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN } = tabbableElements;

let styles = StyleSheet.create({
  button: {
    width: '50%',
    float: 'left',
    marginBottom: '0.75em',
    paddingRight: '0.37em',
  },
  submitButtonContainer: {
    width: '24%',
    float: 'left',
    marginBottom: '0.75em',
  },
  submitButtonContainerMobile: {
    width: '100%',
    float: 'none',
  },
});

const stylesWithSkipBtn = StyleSheet.create({
  skipButton: {
    paddingRight: 0,
    paddingLeft: '0.37em',
  },
  buttonsContainer: {
    width: '50%',
    margin: 0,
    padding: 0,
    float: 'left',
  },
  buttonsContainerMobile: {
    width: '100%',
  },
});

const stylesWithLargeTextInput = StyleSheet.create({
  submitButtonContainer: {
    width: '24%',
    float: 'left',
    clear: 'left',
    marginBottom: '0.75em',
  },
});

export const DesktopSubmitBtn = (props) => {
  const {
    elementInFocus,
    focusOnPrevElem,
    focusOnNextElem,
    isActive,
    isLargeText,
    label,
    onSkip,
    onSubmit,
    responseIsOptional,
  } = props;

  const submitBtnRef = useRef(null);
  const skipBtnRef = useRef(null);
  const [skipBtnIsActive, setSkipBtnIsActive] = useState(false);

  useEffect(() => {
    switch (elementInFocus) {
      case SUBMIT_BTN:
        submitBtnRef.current.focus();
        break;
      case SKIP_BTN:
        skipBtnRef.current.focus();
        break;
      default:
        break;
    }
  }, [elementInFocus]);

  const handleSubmitBtnKeyDown = (event) => {
    event.preventDefault();
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          focusOnPrevElem();
        } else {
          focusOnNextElem();
        }
        break;
      case 'ArrowDown':
        focusOnNextElem();
        break;
      case 'ArrowUp':
        focusOnPrevElem();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        if (isActive) {
          onSubmit();
        }
        break;
      default:
        break;
    }
  };

  const handleSkipBtnKeyDown = (event) => {
    event.preventDefault();
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          focusOnPrevElem();
        } else {
          focusOnNextElem();
        }
        break;
      case 'ArrowDown':
        focusOnNextElem();
        break;
      case 'ArrowUp':
        focusOnPrevElem();
        break;
      case 'Enter':
        onSkip();
        break;
      default:
        break;
    }
  };

  const calculatedStyles = Object.assign(
    {},
    styles,
    responseIsOptional ? stylesWithSkipBtn : {},
    isLargeText && stylesWithLargeTextInput
  );

  return (responseIsOptional) ? (
    <div className={css(calculatedStyles.buttonsContainer)}>
      <div className={css(calculatedStyles.button)}>
        <Button
          buttonRef={submitBtnRef}
          isActive={isActive}
          label={label}
          onClick={() => {
            if (isActive) onSubmit();
          }}
          onKeyDown={handleSubmitBtnKeyDown}
          tabIndex={0}
          viewport={DESKTOP}
        />
      </div>
      <div
        className={css(
          calculatedStyles.button,
          calculatedStyles.skipButton,
        )}
        onBlur={() => {
          setSkipBtnIsActive(false);
        }}
        onFocus={() => {
          setSkipBtnIsActive(true);
        }}
        onMouseEnter={() => {
          if (elementInFocus !== SKIP_BTN) {
            setSkipBtnIsActive(true);
          }
        }}
        onMouseLeave={() => {
          if (elementInFocus !== SKIP_BTN) {
            setSkipBtnIsActive(false);
          }
        }}
      >
        <Button
          buttonRef={skipBtnRef}
          isActive={skipBtnIsActive}
          label={'Skip Question'}
          onClick={onSkip}
          onKeyDown={handleSkipBtnKeyDown}
          tabIndex={0}
          viewport={DESKTOP}
        />
      </div>
    </div>
  ) : (
    <div className={css(calculatedStyles.submitButtonContainer)}>
      <Button
        buttonRef={submitBtnRef}
        isActive={isActive}
        label={label}
        onClick={() => {
          if (isActive) onSubmit();
        }}
        onKeyDown={handleSubmitBtnKeyDown}
        tabIndex={0}
        viewport={DESKTOP}
      />
    </div>
  );
};

DesktopSubmitBtn.propTypes = {
  elementInFocus: PropTypes.oneOf([TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN]),
  isActive: PropTypes.bool,
  focusOnPrevElem: PropTypes.func.isRequired,
  focusOnNextElem: PropTypes.func.isRequired,
  label: PropTypes.string,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  responseIsOptional: PropTypes.bool,
};

DesktopSubmitBtn.defaultProps = {
  elementInFocus: TEXT_INPUT,
  onFocus: () => null,
  onSkip: () => null,
  responseIsOptional: false,
};
