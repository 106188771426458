import React from 'react';
import {
  Title,
} from './sharedComponents/layoutHelpers';
import LandingPage from './sharedComponents/LandingPage'

const ReportAnIssueThankYou = () => {
  return (
      <LandingPage
        bannerContent={
          <div>
        <Title>
          Thanks for submitting an issue!
        </Title>
          </div>
        }
      />
  );
};

export { ReportAnIssueThankYou };
