import * as pollActions from '../../poll/actions';
import * as actionTypes from './actionTypes';

export function useActions(dispatch, props) {
  const { body, email, id } = props;

  return {
    handleFocus: () => {
      dispatch(pollActions.setCurrentQuestionById(id));
    },

    handleUserSkippedEmailQuestion: () => {
      dispatch({
        id,
        type: actionTypes.SKIP_EMAIL_QUESTION,
      });
      dispatch(pollActions.addToSkippedQuestionListById(id, body));
      dispatch(pollActions.setCurrentQuestionAutomatically());
    },

    handleInputChange: (value) => {
      dispatch({
        id,
        email: value,
        type: actionTypes.UPDATE_EMAIL,
      });
    },

    dispatchActionsToRecordEmailResponse: (email) => {
      dispatch({
        id,
        email: email,
        type: actionTypes.SAVE_EMAIL_RESPONSE,
      });
      dispatch(pollActions.setCurrentQuestionAutomatically());
      dispatch(pollActions.markEmailSubmitted({ answer: email }));
      dispatch(pollActions.saveResponse(id, body, { answer: email }));
    },

    showEmailSuggestion: (suggestedEmail) => {
      dispatch({
        id,
        suggestedEmail,
        currentEmail: email,
        type: actionTypes.SHOW_EMAIL_SUGGESTION,
      });
    },

    hideEmailSuggestion: () => {
      dispatch({
        id,
        type: actionTypes.HIDE_EMAIL_SUGGESTION,
      });
    },

    dispatchActionsToHandleInvalidEmail: () => {
      dispatch({
        id,
        errorMsg: 'Sorry, this does not seem to be a valid email.',
        type: actionTypes.SHOW_VALIDATION_FAILURE_MSG,
      });
      dispatch(pollActions.setCurrentQuestionById(id));
    },
  };
}
