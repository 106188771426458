import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import * as appActions from '../appActions';
import { MOBILE } from '../lib/constants';
import * as geolocation from '../lib/geolocation';
import mixpanel from '../lib/mixpanel';
import * as Model from '../lib/model';
import { stickyFocusRenderTypes } from './stickyFocusRenderTypes';
import { api } from '../lib/api';
import { setPollLoadTime } from '../lib/apiHelpers';
import * as highlightActions from './highlight/actions';

export function renderPoll(response) {
  return (dispatch, getState) => {
    const { payload } = response;

    dispatch(clearResponses());

    const responseIsImpression = !!payload.poll;
    const poll = responseIsImpression ? payload.poll : payload;
    /*
      The poll id for tracking is always `poll.id` where `poll` is either `payload`
      for raw polls, or `payload.poll` for impressions
    */
    const pollId = poll.id;
    const impressionId = responseIsImpression ? payload.id : null;
    const pollType = poll.poll_type;
    const pollName = poll.name;
    const questions = poll.questions;
    const emailVariationId = payload.email_variation_id;

    dispatch(setPollMetadata({
      emailVariationId,
      impressionId,
      pollId,
      pollType,
    }));

    initMixpanel({
      pollId,
      impressionId,
      pollName,
      pollType,
      viewportIsMobile: getState().viewport === MOBILE,
    });

    const geolocationSuccessCallback = (pos) => {
        dispatch(
        setGeoCoordinates(`${pos.coords.latitude}, ${pos.coords.longitude}`)
      );
    }

    if (containsLocationQuestion(questions)) {
      tryGeolocation(geolocationSuccessCallback);
    }

    dispatch(loadQuestions(questions, poll.shuffleFrom, poll.shuffleTo))
    dispatch(appActions.hideLoadingSpinner());
    setPollLoadTime();
  }
};

export const clearResponses = () => {
  return { type: actionTypes.CLEAR_RESPONSES };
}

export const setPollMetadata = ({ pollId, impressionId, pollType, emailVariationId = null }) => {
  return {
    emailVariationId,
    impressionId,
    pollId,
    pollType,
    type: actionTypes.SET_POLL_METADATA,
  };
};

export const initMixpanel = ({
  pollId,
  impressionId,
  pollName,
  pollType,
  viewportIsMobile,
}) => {
  mixpanel.registerOnce({
    poll_id: pollId,
    poll_impression_id: impressionId,
    poll_name: pollName,
    poll_type: pollType,
    mobile: viewportIsMobile,
    layout: 'v3',
  });
  if (impressionId) {
    mixpanel.identify(impressionId);
  }
  mixpanel.track('Viewed Poll');
  mixpanel.incrementPeople('Viewed Poll');
};

const containsLocationQuestion = (questions) => {
  return _.some(questions, (q) => q.render_type === 'location');
};

const tryGeolocation = (onSuccessCallback, onFailureCallback) => {
  geolocation.tryWatchingPosition({
    onWait: null, // DO NOT do anything while waiting for a position such as showing a modal
    onSuccess: (pos) => {
      if (onSuccessCallback) {
        onSuccessCallback(pos);
      }
    },
    onFailure: () => {
      geolocation.stopWatchingPosition();
      if (onFailureCallback) {
        onFailureCallback();
      }
    },
    onlyIfGranted: true,
  });
};

export const loadQuestions = (questions, shuffleFrom = 0, shuffleTo = 0) => {
  return {
    type: actionTypes.LOAD_QUESTION_DATA,
    questions: Model.initializeQuestions(questions, shuffleFrom, shuffleTo),
  };
};

export const setCurrentQuestionById = (questionId) => {
  return (dispatch, _) => {
    dispatch({
      id: questionId,
      type: actionTypes.SET_CURRENT_QUESTION_BY_ID,
    });
  };
};

export const dispatchSkipForAllPrevNonStickyQuestions = (targetQuestionId) => {
  return (dispatch, getState) => {
    const {
      poll: { questions },
    } = getState();

    _.takeWhile(questions, q => q.id !== targetQuestionId)
      .filter(q => !q.isAnswered)
      .filter(q => _.reject(stickyFocusRenderTypes, q.renderType))
      .forEach(q => {
        dispatch(addToSkippedQuestionListById(q.id, q.body));
      });
  };
};

export const addToSkippedQuestionListById = (questionId, questionBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SKIP_QUESTION,
      id: questionId,
      question: questionBody,
    });
  };
};

export const setCurrentQuestionAutomatically = () => {
  return (dispatch, getState) => {
    const allQuestions = getState().poll.questions;
    const questionsToSkip = getState().poll.skips;
    const eligibleQuestions = _.differenceBy(
      allQuestions,
      questionsToSkip,
      'id'
    );
    const nextQuestion = eligibleQuestions.find((q) => !q.isAnswered);

    if (!nextQuestion) {
      dispatch({ type: actionTypes.UNSET_CURRENT_QUESTION });
      dispatch(highlightActions.hideHighlight());
    } else {
      dispatch({
        id: nextQuestion.id,
        type: actionTypes.SET_CURRENT_QUESTION_BY_ID,
      });
    }
  };
};

export const markEmailSubmitted = (email) => {
  return {
    type: actionTypes.MARK_EMAIL_SUBMITTED,
    body: email,
  };
};

export const setGeoCoordinates = (coordinates) => {
  return {
    type: actionTypes.SET_GEOCOORDINATES,
    coordinates: coordinates,
  };
};

export const unsetGeoCoordinates = () => {
  return {
    type: actionTypes.UNSET_GEOCOORDINATES,
  };
};

export const saveResponse = (questionId, questionBody, response) => {
  return (dispatch, getState) => {
    const pollState = getState().poll;
    response.isMobile = getState().viewport === MOBILE;

    if (pollState.previewMode === false) {
      api.postResponse({
        questionId,
        response,
        pollId: pollState.id,
      });
    }

    dispatch({
      type: actionTypes.SAVE_RESPONSE,
      id: questionId,
      question: questionBody,
      response: response,
    });

    trackResponse(questionId, questionBody, response, getState().poll);
  };
};

const trackResponse = (questionId, questionBody, response, pollState) => {
  mixpanel.track('responded', {
    question: questionBody,
    answer: response.answer,
    choice_id: response.choiceId,
    email_variation_id: pollState.emailVariationId,
  });

  if (!selectors.viewedAllQuestions(pollState)) return;

  mixpanel.track('responded_to_all_questions');
  mixpanel.incrementPeople('responded_to_all_questions');

  if (pollState.emailSubmitted) {
    window.fbq('track', 'ProvidedEmail', {
      poll_id: pollState.id,
      campaign: '#{session[:utm_campaign]}',
    });
    window.gtag('event', 'conversion', {
      send_to: `AW-${window.civiqs.config.GOOGLE_CONVERSION_ID}/${window.civiqs.config.GOOGLE_CONVERSION_LABEL}`,
    });
  }
};

export const showThankYouPage = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MARK_POLL_SUBMITTED });
    browserHistory.push('/thankyou');
  };
};

export const rewritePath = () => {
  return (dispatch, getState) => {
    const pathPrefix = window.location.pathname.split('/')[1];
    const poll = getState().poll;
    const pollId = poll.id;
    const impressionId = poll.impressionId;
    const newPath = getNewPath(pathPrefix, pollId, impressionId);

    window.history.replaceState(null, '', newPath);
    dispatch(appActions.setBasePath(newPath));
    if (pathPrefix === 'preview') {
      dispatch(activatePreviewMode());
    }
  };
};

/*  If the api responded with a poll, the user should be at /p/
    but, if the api responds with an impression, they should be at /i/
    Impression data includes a poll property that  references the poll from which it was spawned.
    So, we use that property as a duck type test here.
*/
const getNewPath = (pathPrefix, pollId, impressionId) => {
  switch (pathPrefix) {
    case 'p':
    case 'i':
      return impressionId ? `/i/${impressionId}` : `/p/${pollId}`;

    case 'e':
      return `/e/${pollId}`;
    /*  In order to use impression behaviors, the poll preview data will be provided
        in the form of an impression (including a poll_id property referencing the associated poll)
        However, the user should be presented with a path that reflects the poll that they are creating.
        Thus, we update the path with the id of the poll
    */
    case 'preview':
      return `/preview/${pollId}`;

    case 'mock':
      return window.location.pathname;

    default:
      return `/p/${pollId}`;
  }
}

export const activatePreviewMode = () => {
  return { type: actionTypes.ACTIVATE_PREVIEW_MODE };
};


