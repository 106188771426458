import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, css } from 'aphrodite-jss';
import Magnifier from 'react-magnifier';
import * as scroll from '../lib/scroll';
import * as colors from '../lib/colors';
import * as fonts from '../lib/fonts';
import { parseAllowedHtml } from '../lib/parseAllowedHtml';
import {
  MOBILE_PADDING_HORIZ,
  MOBILE_PADDING_VERT,
  NAV_HEIGHT,
} from '../lib/constants';
import { mobileDeviceIsSmall } from '../lib/mobileDeviceIsSmall';

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.DIVIDER_COLOR,
    marginBottom: '3.5em',
    position: 'relative',
    zIndex: 1,
  },
  image: {
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
    zIndex: 100,
  },
  imageContainer: {
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingLeft: 32,
    paddingRight: 32,
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    zIndex: 10,
  },
  questionBody: {
    color: colors.DARK_BLUE,
    fontFamily: fonts.TISA,
    fontSize: '1.25em',
    fontWeight: 100,
    lineHeight: '1.2em',
    paddingBottom: MOBILE_PADDING_VERT,
    paddingLeft: MOBILE_PADDING_HORIZ,
    paddingRight: MOBILE_PADDING_HORIZ,
    paddingTop: MOBILE_PADDING_VERT,
    zIndex: 1,
  },
  questionNumber: {
    borderBottomColor: colors.DIVIDER_COLOR,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderRight: 'none',
    color: colors.BLUE,
    fontFamily: fonts.TISA,
    fontSize: '1.3em',
    lineHeight: '2em',
    textAlign: 'center',
  },
  questionInputWrapper: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
});

function getWindowInnerHeight() {
  return mobileDeviceIsSmall() ? window.innerHeight : window.innerHeight - NAV_HEIGHT;
}

function MobileQuestionLayout(props) {
  const {
    alt,
    body,
    children,
    imageUrlSm,
    index,
    isCurrent,
  } = props;

  const { questions, responses } = useSelector((state) => state.poll);
  const totalQuestions = questions.length;
  const questionNumber = index + 1;
  const questionComponentRef = useRef(null);
  const numberRef = useRef(null);
  const bodyRef = useRef(null);
  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);
  const [questionHeight, setQuestionHeight] = useState(getWindowInnerHeight());
  let scrollDetectionTimeout = null;

  useEffect(() => {
    updateHeight();
    // On mobile viewports, resize is fired when scroll bars are displayed
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('scroll', onResize);
      clearTimeout(scrollDetectionTimeout);
    }
  }, []);

  useEffect(() => {
    if (isCurrent) {
      scroll.halt();
      scroll.to(questionComponentRef.current);
    }
  }, [responses]);

  useEffect(() => {
    if (isCurrent) {
      scroll.halt();
      updateHeight();
    }
  }, [isCurrent]);

  function onResize() {
    // Every time the scroll event is called, clear this timeout and prevent
    // this event from happening.
    clearTimeout(scrollDetectionTimeout);
    // Set (or reset) a funciton to run at a time out. If scroll event is not
    // called again, the clearTimeout won't fire and this will actually run.
    scrollDetectionTimeout = window.setTimeout((event) => {
      const questionComponentBottom =
        questionComponentRef.current.offsetTop +
        questionComponentRef.current.offsetHeight;
      // If the bottom of the question extends below the visible screen, update
      // the components height.
      if (questionComponentBottom >= window.innerHeight) updateHeight();
    }, 100);
  };

  function updateHeight() {
    // If the document.activeElement is not body or null then the resize was
    // triggered by the user touching a text field and triggering the
    // appearance of a 'soft keyboard' on Android. In that case, ignore.
    if (
      document.activeElement
      !== document.body
      && document.activeElement
      !== null
    ) return;

    const viewportHeight = getWindowInnerHeight();
    const imagePaddingBottom = 20;
    const actualImageHeight = imageRef.current.offsetHeight ? imageRef.current.offsetHeight: 0;
    const nonImageSubComponentsHeight =
      numberRef.current.offsetHeight +
      bodyRef.current.offsetHeight +
      inputRef.current.offsetHeight;

    // Does the question naturally extend off screen
    const questionExtendsOffScreen =
      nonImageSubComponentsHeight + actualImageHeight + imagePaddingBottom >
      viewportHeight;

    // If the question is naturally shorter than the screen, extend it's height to that of the viewport
    const questionHeight = questionExtendsOffScreen
      ? nonImageSubComponentsHeight + actualImageHeight + imagePaddingBottom
      : viewportHeight;

    const calculatedImageHeight =
      questionHeight - nonImageSubComponentsHeight - imagePaddingBottom;

    setImageHeight(calculatedImageHeight);
    setQuestionHeight(questionHeight);
  };

  return (
    <div
      className={css(styles.container)}
      ref={questionComponentRef}
      style={{ minHeight: questionHeight }}
    >
      <div
        children={`${questionNumber} of ${totalQuestions}`}
        className={css(styles.questionNumber)}
        ref={numberRef}
      />
      <h2
        children={parseAllowedHtml(body)}
        className={css(styles.questionBody)}
        ref={bodyRef}
      />
      <div
        className={css(styles.imageContainer)}
        style={{ height: imageHeight }}
      >
        <Magnifier
          alt={alt}
          className={css(styles.image)}
          onLoad={() => {
            updateHeight();
          }}
          ref={imageRef}
          mgBorderWidth={1}
          mgHeight={175}
          mgShape={'square'}
          mgWidth={175}
          src={imageUrlSm}
        />
      </div>
      <div
        className={css(styles.questionInputWrapper)}
        ref={inputRef}
      >
        {children}
      </div>
    </div>
  );
}

MobileQuestionLayout.propTypes = {
  alt: PropTypes.string,
  body: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  imageUrlSm: PropTypes.string,
  index: PropTypes.number.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

export { MobileQuestionLayout };
