const mockImpression = {
   "id":"B4K1eV",
   "poll_id":"3oxVXP",
   "email_variation_id": 192837465,
   "poll":{
      "id":"3oxVXP",
      "name":"reengagement_trump_healthcare",
      "poll_type":"reengagement",
      "questions":[
         {
            "id":47,
            "name":"opinion_aca",
            "body":"Which comes closest to your view about the Affordable Care Act, also known as Obamacare? Should it be:",
            "question_type":"research",
            "render_type":"options",
            "choices":[
               {
                  "id":68,
                  "body":"Repealed entirely",
                  "order":1
               },
               {
                  "id":69,
                  "body":"Expanded to do more",
                  "order":2
               },
               {
                  "id":70,
                  "body":"Kept but scaled back",
                  "order":3
               },
               {
                  "id":71,
                  "body":"Kept as is",
                  "order":4
               },
               {
                  "id":72,
                  "body":"Unsure",
                  "order":5
               }
            ]
         },
         {
            "id":508,
            "name":"aca_repeal_priority",
            "body":"Should President Trump continue to make it a priority to repeal and replace Obamacare, or should he move on to other issues?",
            "question_type":"research",
            "render_type":"options",
            "choices":[
               {
                  "id":1999,
                  "body":"Keep Obamacare a priority",
                  "order":0
               },
               {
                  "id":2002,
                  "body":"Move on to other issues",
                  "order":1
               },
               {
                  "id":2001,
                  "body":"Unsure",
                  "order":3
               }
            ]
         },
         {
            "id":506,
            "name":"trump_healthcare_confident",
            "body":"How confident are you that President Trump will sign a bill that improves healthcare?",
            "question_type":"research",
            "render_type":"options",
            "choices":[
               {
                  "id":1993,
                  "body":"Very confident",
                  "order":0
               },
               {
                  "id":1994,
                  "body":"Somewhat confident",
                  "order":1
               },
               {
                  "id":1995,
                  "body":"Not confident at all",
                  "order":2
               },
               {
                  "id":1992,
                  "body":"Unsure",
                  "order":3
               }
            ]
        },
        {
          "id":111,
          "name":"all_that_apply_test",
          "body":"Choose all words that apply to <a href=\"http://www.google.com/\">google</a> foo bar buzz?",
          "active":true,
          "question_type":"research",
          "render_type":"multi_options",
          "reverse_choices":3,
          "max_choice_count":2,
          "choices":[
            {
              "body":"Bar",
              "id":789,
              "order":1
            },
            {
              "body":"Buzz",
              "id":790,
              "order":2
            },
            {
              "body":"Blip",
              "id":791,
              "order":3
            },
            {
              "body":"Boop",
              "id":792,
              "order":4
            }
          ]
        },
        {
          "id":4733,
          "name":"all_that_apply_test_2",
          "body":"what should biden do",
          "question_type":"research",
          "render_type":"multi_options",
          "shuffle_choices":0,
          "reverse_choices":0,
          "max_choice_count":4,
          "image_url_sm":null,
          "image_url_lg":null,
          "image_alt_tag":null,
          "choices":[
            {
              "id":23180,
              "body":"2000 per month checks",
              "order":0,
              "is_custom_other_choice":false
            },
            {
              "id":23181,
              "body":"start a war with iran",
              "order":1,
              "is_custom_other_choice":false
            },
            {
              "id":23182,
              "body":"war with china",
              "order":2,
              "is_custom_other_choice":false
            },
            {
              "id":23183,
              "body":"guaido becomes gov of NY",
              "order":3,
              "is_custom_other_choice":false
            },
            {
              "id":23184,
              "body":"free all political prisoners",
              "order":4,
              "is_custom_other_choice":false
            },
            {
              "id":23185,
              "body":"dismantle fb",
              "order":5,
              "is_custom_other_choice":false
            }
          ]
        },
        {
          "id":136,
          "name":"whos_on_first",
          "body":"Who is on first base?",
          "active":true,
          "question_type":"research",
          "render_type":"options_with_other",
          "shuffle_choices":2,
          "choices":[
            {
              "body":"What",
              "id":40,
              "order":1
            },
            {
              "body":"How",
              "id":41,
              "order":2
            },
            {
              "body":"Who",
              "id":42,
              "order":3
            },
            {
              "body":"Other",
              "id":43,
              "order":4,
              "is_custom_other_choice":true
            }
          ]
        },
        {
          "id":123,
          "name":"work_clothing",
          "body":"For work, do you usually wear protective clothing that is different from what you wear outside of work; for example, a helmet, eyewear, gloves, aprons, or footwear?",
          "active":true,
          "question_type":"research",
          "render_type":"options",
          "shuffle_choices":2,
          "choices":[
            {
              "body":"Yes",
              "id":11,
              "order":1
            },
            {
              "body":"No",
              "id":12,
              "order":2
            },
            {
              "body":"I don't work",
              "id":13,
              "order":3
            },
            {
              "body":"Unsure",
              "id":14,
              "order":4
            }
          ]
        },
        {
          "id":124,
          "name":"primary_attention_spanish",
          "body":"¿Cuánta atención le ha prestado a las noticias sobre las elecciones primarias presidenciales democráticas?",
          "active":true,
          "question_type":"research",
          "render_type":"options",
          "shuffle_choices":2,
          "choices":[
            {
              "body":"Ninguna",
              "id":15,
              "order":1
            },
            {
              "body":"Poca",
              "id":16,
              "order":2
            },
            {
              "body":"Algo",
              "id":17,
              "order":3
            },
            {
              "body":"Mucha",
              "id":18,
              "order":4
            },
            {
              "body":"No sé",
              "id":19,
              "order":5
            }
          ]
        },
        {
          "id":125,
          "name":"economy_us_now",
          "body":"The p tags around <p>THIS</p> should be stripped out while the strong tags around <strong>THIS</strong> should be rendered.",
          "active":true,
          "question_type":"research",
          "render_type":"options",
          "reverse_choices":3,
          "choices":[
            {
              "body":"Extremely important",
              "id":20,
              "order":1
            },
            {
              "body":"Somewhat important",
              "id":21,
              "order":2
            },
            {
              "body":"Not very important",
              "id":22,
              "order":3
            },
            {
              "body":"Not important at all",
              "id":23,
              "order":4
            },
            {
              "body":"Unsure",
              "id":24,
              "order":5
            }
          ]
        },
        {
          "id":126,
          "name":"clown_vote",
          "body":"Which one of these clowns would you vote for if the election were held today?",
          "active":true,
          "question_type":"research",
          "render_type":"congressional_district",
          "choices":[
            {
              "body":"Bozo",
              "id":25,
              "order":1
            },
            {
              "body":"Ronald McDonald",
              "id":26,
              "order":2
            },
            {
              "body":"It",
              "id":27,
              "order":3
            },
            {
              "body":"Homie",
              "id":28,
              "order":4
            },
            {
              "body":"Unsure",
              "id":29,
              "order":5
            }
          ]
        },
        {
          "id":127,
          "name":"thief_vote",
          "body":"Which one of these thieves would you vote for if the election were held today?",
          "active":true,
          "question_type":"research",
          "render_type":"state_level_district",
          "choices":[
            {
              "body":"Bonnie",
              "id":30,
              "order":1
            },
            {
              "body":"Clyde",
              "id":31,
              "order":2
            },
            {
              "body":"Butch Cassidy",
              "id":32,
              "order":3
            },
            {
              "body":"Hamburgler",
              "id":33,
              "order":4
            },
            {
              "body":"Unsure",
              "id":34,
              "order":5
            }
          ]
        },
        {
          "id":128,
          "name":"rorschach",
          "body":"What do you see?",
          "active":true,
          "question_type":"research",
          "render_type":"image",
          "choices":[
            {
              "body":"A flower",
              "id":35,
              "order":1
            },
            {
              "body":"An airplane",
              "id":36,
              "order":2
            },
            {
              "body":"My dissaproving father",
              "id":37,
              "order":3
            },
            {
              "body":"A dalmation",
              "id":38,
              "order":4
            },
            {
              "body":"Unsure",
              "id":39,
              "order":5
            }
          ]
        },
        {
          "id":129,
          "name":"favorite_ice_cream",
          "body":"What is your favorite flavor of ice cream?",
          "active":true,
          "question_type":"research",
          "render_type":"text"
        },
        {
          "id":130,
          "name":"general_relativity",
          "body":"Explain how General Relativity bound together early 20th c. ideas about gravity and electro-magnetism?",
          "active":true,
          "question_type":"research",
          "render_type":"large_text"
        },
        {
          "id":131,
          "name":"street_address_zip",
          "body":"To ensure that our survey represents all Americans, can you tell us your zip code?",
          "active":true,
          "question_type":"geographical",
          "render_type":"zipcode"
        },
        {
          "id":132,
          "name":"recruit_location",
          "body":"To ensure that our survey represents all Americans, can you tell us your city and state?",
          "active":true,
          "question_type":"geographical",
          "render_type":"location"
        },
        {
          "id":133,
          "name":"recruit_email",
          "body":"Can we follow up with you for future surveys? Please enter your email address.",
          "active":true,
          "question_type":"email",
          "render_type":"email"
        },
        {
          "id":134,
          "name":"birth_year",
          "body":"What year were you born?",
          "active":true,
          "question_type":"biographical",
          "render_type":"birth_year"
        },
        {
          "id":135,
          "name":"how_many_fingers",
          "body":"Guess how many fingers I am holding up",
          "active":true,
          "question_type":"research",
          "render_type":"numeric"
        },
        {
          "id":137,
          "name":"party_id",
          "body":"Do you usually think of yourself as a:",
          "question_type":"profile",
          "render_type":"options",
          "shuffle_choices":3,
          "reverse_choices":null,
          "image_url_sm":null,
          "image_url_lg":null,
          "image_alt_tag":null,
          "choices":[
            {
              "id":98,
              "body":"Democrat",
              "order":1,
              "is_custom_other_choice":false
            },
            {
              "id":99,
              "body":"Republican",
              "order":2,
              "is_custom_other_choice":false
            },
            {
              "id":100,
              "body":"Independent",
              "order":3,
              "is_custom_other_choice":false
            },
            {
              "id":101,
              "body":"Other or none",
              "order":4,
              "is_custom_other_choice":false
            }
          ]
        },
        {
          "id":138,
          "name":"recruit_email_optional",
          "body":"Enter your email address. But...like...only if you feel comfortable with that.",
          "active":true,
          "question_type":"email",
          "render_type":"email_optional"
        }
      ]
   }
}

export default mockImpression;