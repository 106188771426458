import renderHTML from 'react-render-html';
import _ from 'lodash';
import 'child-replace-with-polyfill';
import 'template-polyfill';
import { BLUE } from './colors';

/**
 * @param {String} str Question body text which may include html tags
 * @param {Array} allowedTags Array of strings representing the tagName property of each allowed html tag
 * @return {String}
 */
export function parseAllowedHtml(str, allowedTags = ['A', 'EM', 'I', 'STRONG', 'BR']) {
  if (typeof str === 'undefined') {
    return str;
  }

  let allowedString = '';
  try {
    allowedString = stripDisallowedTagsFromString(str, allowedTags);
  } catch (error) {
    allowedString = str;
  }

  const allowedStringWithTargetAttrsAdded = addTargetAttrToAnchorTags(
    allowedString
  );

  return renderHTML(allowedStringWithTargetAttrsAdded);
};

/**
 * @param {String} str A string that might included html tags
 * @param {Array} allowedTags Array of strings representing the tagName property of each allowed html tag
 * @return {String}
 */
const stripDisallowedTagsFromString = (str, allowedTags) => {
  let parent = document.createElement('div');
  parent.innerHTML = str.trim();
  parent = stripDisallowedTagsFromHtmlNode(parent, allowedTags);

  return parent.innerHTML;
};

/**
 * @param {Element} parent An html element that will be introspected
 * @param {Array} allowedTags Array of strings representing the tagName property of each allowed html tag
 * @return {Element}
 */
const stripDisallowedTagsFromHtmlNode = (parent, allowedTags) => {
  if (parent.childElementCount > 0) {
    for (const child of parent.children) {
      if (_.includes(allowedTags, child.tagName)) {
        // keep outer tags
        child.replaceWith(stripDisallowedTagsFromHtmlNode(child));
      } else {
        //strip outer tags
        child.replaceWith(stripDisallowedTagsFromHtmlNode(child.innerHTML));
      }
    }
  }

  return parent;
};

/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
const addTargetAttrToAnchorTags = (str) => {
  const template = document.createElement('template');
  template.innerHTML = str.trim();
  template.content.childNodes.forEach((node) => {
    if (node.nodeName === 'A') {
      node.setAttribute('target', '_blank');
      node.setAttribute('style', `color:${BLUE}`);
    }
  });

  return template.innerHTML;
};
