import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@rebass/emotion';
import { MEDIUM_GREY } from '../../lib/colors';
import { MONTSERRAT } from '../../lib/fonts';

const SubmitButton = (props) => (
  <Button
    bg={props.isActive ? 'rgb(13, 130, 223)' : MEDIUM_GREY}
    borderRadius={4}
    border={'none'}
    fontWeight={700}
    fontFamily={MONTSERRAT}
    onClick={(e) => {
      e.preventDefault();
      props.onClick();
    }}
    style={{ cursor:  props.isActive ? 'pointer' : 'auto' }}
    width={1}
  >
    Submit
  </Button>
);

SubmitButton.prototypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SubmitButton.defaultProps = { isActive: false };

export default SubmitButton;
