import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { MOBILE, DESKTOP } from '../lib/constants';
import { GALLERY_GREY } from '../lib/colors';
import { ViewportContext } from '../viewportContext';
import { SubmitButton } from './SubmitButton';
import { MobileQuestionLayout } from './MobileQuestionLayout';
import { DesktopQuestionLayout } from './DesktopQuestionLayout';
import { Highlight as Highlighter } from './highlight/component';
import { LocationQuestionInput } from '../inputs/location/component';
import { EmailQuestionInput } from '../inputs/email/component';
import { OptionsQuestionContainer as OptionsQuestionInput } from '../inputs/options/stateContainer';
import { BirthYearQuestionContainer as BirthYearQuestionInput } from '../inputs/birthYear/stateContainer';
import { ZipcodeQuestionContainer as ZipcodeQuestionInput } from '../inputs/zipcode/stateContainer';
import { TextQuestionContainer as TextQuestionInput } from '../inputs/text/stateContainer';
import { NumericQuestionContainer as NumericQuestionInput } from '../inputs/numeric/stateContainer';
import { MultipleOptionsQuestionContainer as MultipleOptionsQuestionInput } from '../inputs/multipleOptions/stateContainer';

const styles = StyleSheet.create({
  pollContainer: {
    backgroundColor: '#FFF',
    border: `1px solid ${GALLERY_GREY}`,
    boxShadow: `0px 7px 5px 0px rgba(218,218,218,1)`,
    marginTop: '4em',
    position: 'relative',
    width: 900,
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  pollContainerMobile: {
    border: 'none',
    marginTop: 0,
  },
  noQuestionsWarning: {
    color:'red', 
    textAlign: 'center',
    padding: '2%'
  }
});

const Input = ({ renderType, ...rest }) => {
  return {
    options: () => <OptionsQuestionInput {...rest} />,
    location: () => <LocationQuestionInput {...rest} />,
    email: () => <EmailQuestionInput {...rest} />,
    text: () => <TextQuestionInput {...rest} />,
    zipcode: () => <ZipcodeQuestionInput {...rest} />,
    birth_year: () => <BirthYearQuestionInput {...rest} />,
    numeric: () => <NumericQuestionInput {...rest} />,
    image: () => <OptionsQuestionInput {...rest} />,
    multi_options: () => <MultipleOptionsQuestionInput {...rest} />,
  }[renderType]();
};

const Poll = ({
  onceBeforeRender,
  onSubmitCompletedPoll,
  questions,
}) => {
  const viewport = useContext(ViewportContext);

  useEffect(() => {
    onceBeforeRender();
  }, []);

  return (
    <div
      className={css(
        styles.pollContainer,
        viewport === MOBILE && styles.pollContainerMobile
      )}
    >
      {questions.length === 0 ?
        <p className={css(styles.noQuestionsWarning)}>poll does not have questions</p>
      : viewport === MOBILE ? (
        <div>
          {questions.map((question, index) => {
            return (
              <MobileQuestionLayout key={index} {...question}>
                <Input viewport={MOBILE} {...question} />
              </MobileQuestionLayout>
            );
          })}
        </div>
      ) : (
        <div>
          <Highlighter />
          {questions.map((question, index) => {
            return (
              <DesktopQuestionLayout key={index} {...question}>
                <Input viewport={DESKTOP} {...question} />
              </DesktopQuestionLayout>
            );
          })}
        </div>
      )}
      <SubmitButton
        onClick={onSubmitCompletedPoll}
        viewport={viewport}
      />
    </div>
  );
};

Poll.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Poll;
export { Poll };
