import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { Box } from '@rebass/emotion';
import Select from 'react-select';
import { DARK_GREY, LIGHT_GREY } from '../../lib/colors';

const styles = StyleSheet.create({
  label: {
    color: DARK_GREY,
    display: 'block',
    marginBottom: 10,
  },
  labelHidden: {
    position: 'absolute',
    visibility: 'hidden',
  },
});

const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? DARK_GREY : LIGHT_GREY,
    borderWidth: state.isFocused ? 2 : 1,
    boxShadow: 'none',
    height: 38,
    marginBottom: 12,
  }),
};

class Dropdown extends Component {
  state = {
    selectedOption: this.props.defaultValue || null,
  };

  handleChange = (
    { label: answer, value: choiceId },
    { action, name: questionId }
  ) => {

    if (action === 'select-option') {
      if (this.props.onSave) {
        this.props.onSave({
          answer,
          choiceId,
          questionId,
        });
      }
    }

    if (this.props.onChange) {
      this.props.onChange(answer);
    }

    this.setState({
      selectedOption: {
        label: answer,
        value: choiceId,
      },
    });
  };

  handleBlur = () => {
    if (this.props.onBlur) this.props.onBlur();
  }

  handleFocus = () => {
    if (this.props.onFocus) this.props.onFocus();
  }

  render() {
    const { selectedOption } = this.state;
    const { id, label, labelIsHidden, name, options } = this.props;

    return (
      <Box width={1}>
        <label
          className={css(
            styles.label,
            labelIsHidden && styles.labelHidden
          )}
          htmlFor={id}>
          {label}
        </label>
        <Select
          defaultValue='DEFAULT'
          id={id}
          name={name}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          options={options}
          placeholder={labelIsHidden ? label : ''}
          styles={reactSelectStyles}
          value={selectedOption}
        />
      </Box>
    );
  }
}

Dropdown.propTypes = {
  defaultValue: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelIsHidden: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default Dropdown;
