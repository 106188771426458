import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { max } from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { DARK_GREY, LIGHT_GREY, RED } from '../../lib/colors';
import { MONTSERRAT } from '../../lib/fonts';

const styles = StyleSheet.create({
  textFieldContainer: {
    position: 'relative',
    width: '100%',
  },
  textField: {
    borderColor: LIGHT_GREY,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    fontFamily: MONTSERRAT,
    fontSize: '1em',
    fontWeight: 200,
    maxLength: 20,
    marginBottom: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
    resize: 'none',
    width: '100%',
  },
  textFieldInFocus: {
    borderColor: DARK_GREY,
    borderWidth: 2,
  },
  textFieldIsInvalid: {
    borderColor: RED,
    borderWidth: 2,
  },
  textFieldResizable: {
    resize: 'vertical',
  },
  label: {
    color: DARK_GREY,
    display: 'block',
    marginBottom: 10,
  },
  labelHidden: {
    position: 'absolute',
    visibility: 'hidden',
  },
  modal: {
    backgroundColor: '#f6f6ff',
    borderRadius: 2,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    fontSize: '1em',
    fontWeight: 300,
    bottom: '3.05em',
    left: '20%',
    padding: '0.75em',
    position: 'absolute',
    zIndex: 10,
  },
  modalDownArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    marginLeft: '-1em',
    bottom: '-2em',
    left: '50%',
    boxSizing: 'border-box',
    border: '1em solid red',
    borderColor: 'transparent transparent #f6f6ff #f6f6ff',
    transformOrigin: '0 0',
    transform: 'rotate(-45deg)',
    boxShadow: '-4px 4px 4px 0 rgba(0, 0, 0, 0.15)',
  },
});

const Modal = ({ children }) => (
  <div className={css(styles.modal)}>
    {children}
    <div className={css(styles.modalDownArrow)} />
  </div>
);

class TextInput extends Component {
  state = {
    isFocus: false,
    showModal: true,
  };

  render() {
    const {
      invalidInputMessage,
      isValid,
      label,
      labelIsHidden,
      maxLength,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      resizable,
      rows,
      showModal,
      ...restProps
    } = this.props;

    return (
      <div className={css(styles.textFieldContainer)}>
        {this.props.showModal ? <Modal>{invalidInputMessage}</Modal> : null}
        <label
          className={css(
            styles.label,
            labelIsHidden && styles.labelHidden
          )}
          htmlFor={restProps.id}
        >
          {label}
        </label>
        <textarea
          {...restProps}
          className={css(
            styles.textField,
            this.state.isFocus && styles.textFieldInFocus,
            !isValid && styles.textFieldIsInvalid,
            resizable && styles.textFieldResizable
          )}
          onBlur={(e) => {
            this.setState({ isFocus: false });
            if (onBlur) onBlur(e);
          }}
          onChange={(e) => {
            onChange(e);
          }}
          onFocus={(e) => {
            this.setState({ isFocus: true });
            if (onFocus) onFocus(e);
          }}
          onKeyDown={(e) => {
            if (onKeyDown) onKeyDown(e);
          }}
          maxLength={maxLength}
          placeholder={labelIsHidden ? label : ''}
          style={{ height: 38 * rows }}
        />
      </div>
    );
  }
}

TextInput.prototypes = {
  id: PropTypes.number.isRequired,
  invalidInputMessage: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelIsHidden: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  resizable: PropTypes.bool,
  rows: PropTypes.number,
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  isValid: false,
  labelIsHidden: false,
  maxLength: 524288,
  resizable: false,
  rows: 1,
  showModal: false,
};

export default TextInput;
