import * as actionTypes from './actionTypes';

export const highlightStateReducer =  (state = { top: 0, bottom: 0 }, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHT_QUESTION:
      return { ...state, top: action.top, bottom: action.bottom };
    case actionTypes.HIDE_HIGHLIGHT:
      return { ...state, top: 0, bottom: 0 };
    default:
      return state;
  }
};
