import * as actionTypes from './actionTypes';

export const showModal = (content, { closeable = true } = {}) => {
  return {
    type: actionTypes.SHOW_MODAL,
    content: content,
    closeable: closeable
  };
};

export const hideModal = () => {
  return {
    type: actionTypes.HIDE_MODAL
  };
};
