import { connect } from 'react-redux';
import { AccountEditForm as _AccountEditForm } from './component';
import * as actions from './actions';
import * as appActions from '../appActions';

const mapStateToProps = ({ poll, viewport }) => {
  return {
    questions: poll.questions,
    viewport
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allowMainContentOverflow: () => {
      dispatch(appActions.allowMainContentOverflow());
    },
    onSubmitCompletedPoll: () => {
      dispatch(actions.completeAccountEdit());
    },
    onSaveTextAnswer: ({ answer, questionId }) => {
      dispatch({
        answer: answer,
        questionId: questionId,
        type: "UPDATE_TEXT_FIELD",
      });
    },
    onSaveOptionsQuestion: ({
      answer,
      choiceId,
      questionId,
    }) => {
      dispatch({ type: "UPDATE_CHOICE_FIELD", questionId, choiceId, answer });
    },
    onSaveAddress: ({ address, questionId }) => {
      dispatch({
        component: { 'address': address },
        questionId: questionId,
        type: "UPDATE_ADDRESS",
      });
    },
    onSaveCity: ({ city, questionId }) => {
      dispatch({
        component: { 'city': city },
        questionId: questionId,
        type: "UPDATE_ADDRESS",
      });
    },
    onSaveZipcode: ({ zipcode, questionId }) => {
      dispatch({
        component: { 'zipcode': zipcode },
        questionId: questionId,
        type: "UPDATE_ADDRESS",
      });
    },
    onSaveState: ({
      answer,
      choiceId,
      questionId,
    }) => {
      dispatch({
        component: { 'state': answer },
        questionId: questionId,
        type: "UPDATE_ADDRESS",
      });
    },
  }
};

const AccountEditForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(_AccountEditForm);

export { AccountEditForm };
