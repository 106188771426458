import React from 'react';
import { Flex as RebassFlex } from '@rebass/emotion';
import { TISA } from './lib/fonts';
import {
  GridContainer,
  Title,
} from './sharedComponents/layoutHelpers';
import LandingPage from './sharedComponents/LandingPage'

const ClosedPagePara = ({ children }) => {
  return (
    <RebassFlex py={3}>
      <p
        style={{
          color: 'white',
          textAlign: 'left',
          fontFamily: TISA,
          fontSize: '1.2em',
        }}
      >
        {children}
      </p>
    </RebassFlex>
  );
};

const Closed = () => {
  return (
    <LandingPage
      bannerContent={
        <div>
          <Title>This survey has closed.</Title>
          <GridContainer>
            <ClosedPagePara>
              If you are a member of the Civiqs research panel, you will have a
              new survey every few weeks. We will email you the next time you
              <span style={{ fontFamily: TISA }}>'</span>re chosen.
            </ClosedPagePara>
            <ClosedPagePara>
              If you were forwarded this survey link, we are glad that you are
              interested in taking polls! To join the Civiqs panel,{' '}
              <a style={{ color: 'white' }} href={'https://civiqs.com/join?utm_source=civiqs_closed'}>
                please take this short survey
              </a>
              .
            </ClosedPagePara>
            <ClosedPagePara>
              {' '}
              Your opinion matters to us! We look forward to your participation in
              future polls.
            </ClosedPagePara>
          </GridContainer>
        </div>
       }
    />
  );
};

export { Closed };
