
import { tabbableElements } from './tabbableElements';

const { TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN } = tabbableElements;

export function buildTabOrder({ activeElement, responseIsOptional, showMap }) {
  const tabOrder = {
    [TEXT_INPUT]: {
      prev: (responseIsOptional) ? SKIP_BTN : SUBMIT_BTN,
      next: SUBMIT_BTN,
    },
    [SUBMIT_BTN]: {
      prev: TEXT_INPUT,
      next: (responseIsOptional) ? SKIP_BTN : (showMap) ? YES_BTN : TEXT_INPUT,
    },
    [SKIP_BTN]: {
      prev: SUBMIT_BTN,
      next: TEXT_INPUT,
    },
    [YES_BTN]: {
      prev: NO_BTN,
      next: NO_BTN,
    },
    [NO_BTN]: {
      prev: YES_BTN,
      next: YES_BTN,
    },
  };

  return tabOrder[activeElement];
};
