import { browserHistory } from 'react-router';
import _ from 'lodash';
import { api } from '../lib/api';
import { questionTypes } from './questionTypes';

const shouldSubmitAddress = (addressDictionary) => {
  if (addressDictionary['zipcode']) {
    return true
  }
  if (_.size(addressDictionary) > 2) {
    return true
  }
  return false
}

export const makeResponse = (stateValue) => {
  switch (stateValue.type) {
    case questionTypes.choice:
      return { answer: stateValue.answer, choiceId: stateValue.choiceId };

    case questionTypes.text:
      return { answer: stateValue.answer };

    case questionTypes.address:
      if (!shouldSubmitAddress(stateValue)) return;

      const getCityText = () => (stateValue['city']) ? `${stateValue['city']}, ` : '';
      const fullAddress =
        `${stateValue['address'] || ''} ${getCityText()}${stateValue['state'] || ''} ${stateValue['zipcode'] || ''}`.trim();

      return { answer: fullAddress };

    default:
      return;
  }
}

export const completeAccountEdit = () => {
  return (dispatch, getState) => {
    const promises = _.map(getState().accountEdit, function(value, key){
      const response = makeResponse(value)
      if (response) {
        return api.postResponse({
          questionId: key,
          response: response,
          pollId: getState().poll.id
        });
      }
    })
    const bigPromise = Promise.all(_.compact(promises));
    bigPromise.then(function(a) {
      browserHistory.push('/thankyou');
    })
  };
};
