import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import {
  SurveyCompletedLink,
  SurveyCompletedText,
  Title,
} from './sharedComponents/layoutHelpers';
import LandingPage from './sharedComponents/LandingPage'
import { TISA } from './lib/fonts';
import { goToSupplementalPoll } from './lib/goToSupplementalPoll';

export const _ThankYou = ({ canUpdateAddress, pollImpressionId }) => {
  return (
    <LandingPage
      bannerContent={
        <div>
          <Title>YOU<span style={{ fontFamily: TISA }}>'</span>RE ALL DONE.</Title>
          <Title>THANKS!</Title>
        </div>
      }
      instructionalContent={
        <React.Fragment>
          <SurveyCompletedText>
            Did you experience an issue with the survey? Please report it <SurveyCompletedLink href="/report-an-issue">here</SurveyCompletedLink>.
          </SurveyCompletedText>
          <div style={{padding: "10px 0"}} />
          {
            canUpdateAddress
              ? <React.Fragment>
                <SurveyCompletedText>Have you moved recently?</SurveyCompletedText>
                <SurveyCompletedLink
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    goToSupplementalPoll(pollImpressionId);
                  }}
                >
                  Update your address
                </SurveyCompletedLink>
              </React.Fragment>
              : null
          }
        </React.Fragment>
      }
    />
  );
};

export const mapStateToProps = (s) => {
  const addressUpdateablePollTypes = [
    'research',
    'reengagement',
    'targeted'
  ]
  return {
    canUpdateAddress: _.includes(addressUpdateablePollTypes, s.poll.pollType),
    pollImpressionId: s.app.basePath.split("/i/")[1],
  };
};

export const ThankYou = connect(mapStateToProps)(_ThankYou);


