import gMapsAPILoader from 'google-maps-api';
import gMapsGeocder from 'google-maps-api/geocode';
import _ from 'lodash';

class GoogleMaps {
  constructor(api, geocoder) {
    this.gMapsAPI =
      api || gMapsAPILoader(window.civiqs.config.GOOGLE_MAPS_API_KEY);
    this.geocoder = geocoder || gMapsGeocder;
  }

  _getZoomLevel(locationType) {
    const levels = {
      country: 3,
      administrative_area_level_1: 6,
      administrative_area_level_2: 8,
      locality: 12,
      postal_code: 12,
      street_address: 15,
      intersection: 15,
      point_of_interest: 18,
      airport: 18,
    };

    return levels[locationType] || 6;
  }

  showMap(elemId, googleMapsResult, displayOptions) {
    const location = googleMapsResult[0].geometry.location;
    const locationType = googleMapsResult[0].types[0];

    this.gMapsAPI().then((googleMaps) => {
      const options = _.extend(
        {
          center: location,
          zoom: this._getZoomLevel(locationType),
          animation: googleMaps.Animation.DROP,
        },
        displayOptions
      );

      const map = new googleMaps.Map(document.getElementById(elemId), options);

      new googleMaps.Marker({
        map: map,
        position: location,
        animation: googleMaps.Animation.DROP,
      });
      googleMaps.event.trigger(map, 'resize');
    });
  }

  getMapData(query) {
    return this.geocoder({ address: query })
      .then((googleMapsResult) => {
        if (googleMapsResult.length <= 0)
          throw new Error('Your search term did not return any results.');
        return googleMapsResult;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const googleMaps = new GoogleMaps();

export { googleMaps };
