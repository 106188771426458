import _ from 'lodash';
import { getAppBasePath } from './getAppBasePath';

export let pollLoadTime;

export function setPollLoadTime(response) {
  pollLoadTime = Date.now();
  return response;
};

export function buildApiUrlFromRelativeReference(relativeReference) {
  let appBasePath = getAppBasePath();

  return `${appBasePath}${relativeReference}`;
}

export function buildApiUrlFromPollLocationDetails({ hash, prefix, rootUrl, utmParamsArray }) {
  let paramArray = utmParamsArray;
  let path;

  switch (prefix) {
    case 'p':
      path = `polls/${hash}`;
      break;
    case 'i':
    case 'e':
      path = `impressions/${hash}`;
      break;
    case 'preview':
      paramArray.unshift('preview=true');
      path = `polls/${hash}`;
      break;
    default:
      path = `polls`;
  }

  const addressWithoutQuery = `${rootUrl}/api/${path}`;

  return (paramArray.length > 0)
    ? [addressWithoutQuery, paramArray.join('&')].join('?')
    : addressWithoutQuery;
}
