import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { QuestionInputWrapper } from '../sharedComponents/QuestionInputWrapper';
import { TextInput } from '../sharedComponents/TextInput';

export const BirthYearQuestionInput = (props) => {
  const {
    isAnswered,
    isCurrent,
    errorMsg,
    onInputChange,
    onTextFieldFocus,
    onSubmit,
    viewport,
    year
  } = props;

  const dispatch = useDispatch();
  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  return (
    <QuestionInputWrapper
      viewport={viewport}
      answer={year.toString()}
      isCurrent={isCurrent}
      >
      <TextInput
        isActive={isCurrent && year.length > 0}
        isAnswered={isAnswered}
        errorMsg={errorMsg}
        label={'submit'}
        onFocus={onTextFieldFocus}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        response={year.toString()}
        screenReaderLabel={'year'}
        useAutoComplete={false}
        viewport={viewport}
        visible={isCurrent}
        />
    </QuestionInputWrapper>
  );
};

BirthYearQuestionInput.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  useAutoComplete: PropTypes.bool,
  viewport: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

BirthYearQuestionInput.defaultProps = {
  year: ''
};

