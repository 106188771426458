/*
This module wraps zenscroll with:
 - A promise-based interface
 - Standardized durations
 - Last-second (important!) configuration of top offset.

Its interface is a mirror of zenscroll, which allows you to use the different
provided functions with clarity of intent, for example .to() vs .center().
*/
import zenscroll from 'zenscroll';
import { NAV_HEIGHT } from './constants';
import { mobileDeviceIsSmall } from './mobileDeviceIsSmall';

const DELAY_DURATION = 400;
const SCROLL_DURATION = 900;

export const setup = () => {
    zenscroll.setup(SCROLL_DURATION, mobileDeviceIsSmall() ? 0 : NAV_HEIGHT);
}

export const delay = (duration) => new Promise(resolve => setTimeout(resolve, duration))
export const halt = () => {
    if (zenscroll.moving()) zenscroll.stop();
}

// These could probably be DRYed for clarity at some point.
export const to = (element) => delay(DELAY_DURATION).then(_ => {
    setup();
    return new Promise(resolve => zenscroll.to(element, undefined, resolve))
})

export const toY = (y) => delay(DELAY_DURATION).then(_ => {
    setup();
    return new Promise(resolve => zenscroll.toY(y, undefined, resolve))
})

export const intoView = (element) => delay(DELAY_DURATION).then(_ => {
    setup();
    return new Promise(resolve => zenscroll.intoView(element, undefined, resolve))
})

export const center = (element) => delay(DELAY_DURATION).then(_ => {
    setup();
    return new Promise(resolve => zenscroll.center(element, undefined, undefined, resolve))
})