import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { MAP_MARKER } from '../../lib/constants';
import { Instruction } from '../sharedComponents/Instruction';
import { Button } from '../sharedComponents/Button';
import { TextAnswer } from '../sharedComponents/TextAnswer';
import { tabbableElements } from './tabbableElements';
import { DesktopTextField } from './DesktopTextField';
import { DesktopMap } from './DesktopMap';
import { DesktopSubmitBtn } from './DesktopSubmitBtn';
import { buildTabOrder } from './buildTabOrder';

const styles = StyleSheet.create({
  constainer: {
    position: 'relative',
  },
  homeAddressMsgContainer: {
    position: 'relative',
  },
  homeAddressMsg: {
    position: 'absolute',
    top: -40,
    left: 0,
    paddingBottom: 20,
  },
  confirmationCopy: {
    marginTop: 10,
    marginBottom: 10,
  },
  confirmationButtonsContainer: {
    width: '50%',
  },
  confirmationButtonsContainerMobile: {
    width: '100%',
  },
  confirmationButton: {
    width: '49%',
  },
});

const { TEXT_INPUT, SUBMIT_BTN, SKIP_BTN, YES_BTN, NO_BTN } = tabbableElements;

export const DesktopLocationInput = (props) => {
  const {
    answer,
    cityState,
    errorMsg,
    forwardedInputRef,
    geoCoordinates,
    googleMapsResult,
    handleAcceptMappedLocation,
    handleRejectMappedLocation,
    handleInputSubmit,
    handleTextFieldFocus,
    isAnswered,
    isCurrent,
    mapElemId,
    onSkip,
    responseIsOptional,
    showHomeAddressMsg,
    showMap,
  } = props;

  const yesBtnRef = useRef(null);
  const noBtnRef = useRef(null);
  const [elementInFocus, setElementInFocus] = useState(showMap ? YES_BTN : TEXT_INPUT);
  const [inputValue, setInputValue] = useState('');
  const [userIsActive, setUserIsActive] = useState(false);

  useEffect(() => {
    switch (elementInFocus) {
      case YES_BTN:
        yesBtnRef.current.focus();
        break;
      case NO_BTN:
        noBtnRef.current.focus();
        break;
      default:
        break;
      }
  }, [elementInFocus]);

  useEffect(() => {
    if (showMap) setElementInFocus(YES_BTN);
  }, [showMap]);

  const focusOnNextElem = () => {
    setElementInFocus(buildTabOrder({ activeElement: elementInFocus, responseIsOptional, showMap }).next);
  };

  const focusOnPrevElem = () => {
    setElementInFocus(buildTabOrder({ activeElement: elementInFocus, responseIsOptional, showMap }).prev);
  };

  const shouldShowTextInput = () => {
    if (showMap) {
      return false
    } else {
      return userClickedToChangeAnswer() || userHasBlockedOrRejectedGeolocation();
    }
  };

  const userClickedToChangeAnswer = () => {
    return isCurrent && isAnswered;
  };

  const userHasBlockedOrRejectedGeolocation = () => {
    return isCurrent && !geoCoordinates;
  };

  const handleSubmit = () => {
    handleInputSubmit(inputValue);
  };

  const handleYesBtnKeyDown = (event) => {
    event.preventDefault();

    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          focusOnPrevElem();
        } else {
          focusOnNextElem();
        }
        break;
      case 'ArrowDown':
        focusOnNextElem();
        break;
      case 'ArrowUp':
        focusOnPrevElem();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        handleAcceptMappedLocation(inputValue);
        break;
      default:
        break;
    }
  };

  const handleNoBtnKeyDown = (event) => {
    event.preventDefault();

    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          focusOnPrevElem();
        } else {
          focusOnNextElem();
        }
        break;
      case 'ArrowDown':
        focusOnNextElem();
        break;
      case 'ArrowUp':
        focusOnPrevElem();
        break;
      case 'Enter':
      case 'Spacebar':
      case ' ':
        handleRejectMappedLocation();
        setElementInFocus(TEXT_INPUT);
        break;
      default:
        break;
    }
  };

  return (
    <div className={css(styles.container)} ref={forwardedInputRef}>
      <div className={css(styles.homeAddressMsgContainer)}>
        {showHomeAddressMsg ? (
          <div className={css(styles.homeAddressMsgDesktop)}>
            <Instruction>
              For better results, try entering your home address instead.
            </Instruction>
          </div>
        ) : null}
      </div>

      {shouldShowTextInput() ? (
        <div>
          <DesktopTextField
            elementInFocus={elementInFocus}
            errorMsg={errorMsg}
            focusOnNextElem={focusOnNextElem}
            focusOnPrevElem={focusOnPrevElem}
            iconCode={MAP_MARKER}
            isAnswered={isAnswered}
            isChildOfCurrentQuestion={!!isCurrent}
            inputValue={inputValue}
            onFocus={handleTextFieldFocus}
            onSkip={onSkip}
            onSubmit={handleSubmit}
            response={cityState || ''}
            responseIsOptional={responseIsOptional}
            screenReaderLabel={'city and state'}
            setInputValue={setInputValue}
            setUserIsActive={setUserIsActive}
            userIsActive={userIsActive}
            visible={isCurrent}
            />
          <DesktopSubmitBtn
            elementInFocus={elementInFocus}
            errorMsg={errorMsg}
            focusOnNextElem={focusOnNextElem}
            focusOnPrevElem={focusOnPrevElem}
            isActive={userIsActive}
            isAnswered={isAnswered}
            label={'FIND'}
            onFocus={handleTextFieldFocus}
            onSkip={onSkip}
            onSubmit={handleSubmit}
            response={cityState || ''}
            responseIsOptional={responseIsOptional}
            visible={isCurrent}
          />
        </div>
      ) : null}
      {showMap ? (
        <DesktopMap
          googleMapsResult={googleMapsResult}
          mapElemId={mapElemId}
        >
          <div className={css(styles.confirmationButton)}>
            <Button
              isActive={true}
              id="yes-button"
              label="YES"
              onClick={() => {
                handleAcceptMappedLocation(inputValue);
              }}
              onKeyDown={handleYesBtnKeyDown}
              buttonRef={yesBtnRef}
              style={styles.confirmationButton}
              tabIndex={0}
            />
          </div>
          <div className={css(styles.confirmationButton)}>
            <Button
              isActive={true}
              id="no-button"
              label="NO"
              onClick={handleRejectMappedLocation}
              onKeyDown={handleNoBtnKeyDown}
              buttonRef={noBtnRef}
              style={styles.confirmationButton}
              tabIndex={0}
            />
          </div>
        </DesktopMap>
      ) : null}
      {isAnswered && !isCurrent ? <TextAnswer>{answer}</TextAnswer> : null}
    </div>
  );
};

DesktopLocationInput.propTypes = {
  answer: PropTypes.string,
  cityState: PropTypes.string,
  isAnswered: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  geoCoordinates: PropTypes.string,
  googleMapsResult: PropTypes.array,
  mapElemId: PropTypes.string.isRequired,
  handleAcceptMappedLocation: PropTypes.func.isRequired,
  handleInputSubmit: PropTypes.func.isRequired,
  handleRejectMappedLocation: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  responseIsOptional: PropTypes.bool,
  showHomeAddressMsg: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
};

DesktopLocationInput.defaultProps = {
  answer: '',
  isAnswered: false,
  googleMapsResult: [],
  mapElemId: 'gmap',
  responseIsOptional: false,
  showMap: false,
  showHomeAddressMsg: false,
};
