import * as actionTypes from "./appActionTypes";

const appStateReducer = (
  state = {
    allowMainContentOverflow: false,
    basePath: "/",
    showErrorPage: false,
    showMaintenancePage: false,
    showLoadingSpinner: true,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.ALLOW_MAIN_CONTENT_OVERFLOW:
      return Object.assign(state, { allowMainContentOverflow: true });
    case actionTypes.HIDE_LOADING_SPINNER:
      return Object.assign(state, { showLoadingSpinner: false });
    case actionTypes.SHOW_ERROR_PAGE:
      return Object.assign(state, {
        showLoadingSpinner: false,
        showMaintenancePage: false,
        showErrorPage: true,
      });
    case actionTypes.SHOW_MAINTENANCE_PAGE:
      return Object.assign(state, {
        showLoadingSpinner: false,
        showErrorPage: false,
        showMaintenancePage: true,
      });
    case actionTypes.SET_BASE_PATH:
      return Object.assign(state, { basePath: action.path });
    default:
      return state;
  }
};

export { appStateReducer };
