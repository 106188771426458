import * as actionTypes from './actionTypes';
import mixpanel from '../../lib/mixpanel';
import * as pollActions from '../../poll/actions';

export const updateResponse = (questionId, response) => {
  return {
    id: questionId,
    response: response,
    type: actionTypes.UPDATE_RESPONSE,
  };
};

export const saveResponse = (questionId, questionBody, response) => {
  return (dispatch) => {
    dispatch({
      id: questionId,
      response: response,
      question: questionBody,
      type: actionTypes.SAVE_RESPONSE,
    });
    dispatch(
      pollActions.saveResponse(questionId, questionBody, { answer: response })
    );

    mixpanel.track('responded', {
      question: questionBody,
      answer: response,
    });
  };
};
