import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Flex } from '../../sharedComponents/layoutHelpers';
import { OptionButton } from './OptionButton';
import { OptionBody } from './OptionBody';

const styles = StyleSheet.create({
  radioButtonContainer: {
    marginBottom: -10,
    marginRight: 20,
    outline: 'none',
  },
});

class DesktopOption extends Component {
  constructor(props) {
    super(props);

    this.radioBtnFocusProxy = React.createRef();
  }

  state = { active: false };

  componentDidMount() {
    if (this.props.isFocus) this.radioBtnFocusProxy.current.focus();
  }
  componentDidUpdate() {
    if (this.props.isFocus) this.radioBtnFocusProxy.current.focus();
  }

  handleMouseOver = () => {
    this.setState({ active: true });
  };
  handleMouseOut = () => {
    this.setState({ active: false });
  };

  render() {
    const {
      body,
      displayBottomMargin,
      isFocus,
      isGreyedOut,
      onClick,
      onFocus,
      onKeyDown,
      selected,
      shape,
    } = this.props;

    return (
      <div style={{ marginBottom: displayBottomMargin ? 20 : 0 }}>
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          onClick={onClick}
          onMouseEnter={onFocus}
        >
          <div
            className={css(styles.radioButtonContainer)}
            onKeyDown={onKeyDown}
            ref={this.radioBtnFocusProxy}
            tabIndex="0"
          >
            <OptionButton
              isActive={isFocus}
              isChecked={selected}
              shape={shape}
            />
          </div>
          <OptionBody isGreyedOut={isGreyedOut} text={body} />
        </Flex>
      </div>
    );
  }
}

DesktopOption.propTypes = {
  body: PropTypes.string.isRequired,
  displayBottomMargin: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  isGreyedOut: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  shape: PropTypes.string,
};

DesktopOption.defaultProps = {
  isFocus: false,
  selected: false,
  shape: 'circle',
};

export { DesktopOption };
