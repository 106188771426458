import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DESKTOP, MOBILE, TABLET } from '../../lib/constants';
import * as scroll from '../../lib/scroll';
import { ViewportContext } from '../../viewportContext';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { MobileLocationInput } from './MobileLocationInput';
import { DesktopLocationInput } from './DesktopLocationInput';
import { useActions } from './actions';
import { businessLogic, geoCoordinateModeName } from './businessLogic';

export const LocationQuestionInput = (props) => {
  const geoCoordinates = useSelector((state) => state.poll.geoCoordinates);
  const dispatch = useDispatch();
  const actions = useActions(dispatch, props);
  const _businessLogic = businessLogic(actions, props);
  const viewport = useContext(ViewportContext);
  const locationQuestionInputForwardedRef = useRef(null);

  useEffect(() => {
  }, [props.showMap]);

  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  useEffect(() => {
    const geoCoordinateStrategy = _businessLogic.deriveGeoCoordinateStrategy(
      viewport,
      geoCoordinates,
      props.hasProcessedGeoCoordinates
    );

    switch (geoCoordinateStrategy) {
      case geoCoordinateModeName.GET_GEO_COORDINATES:
        _businessLogic.getGeoCoordinates();
        break;
      case geoCoordinateModeName.PROCESS_GEO_COORDINATES:
        _businessLogic.processGeoCoordinates(geoCoordinates);
        _businessLogic.queryGoogleMaps(geoCoordinates)
          .then(
            (googleMapsResult) => {
              actions.showMap(googleMapsResult);
            }, actions.hideMap);
        break;
      case geoCoordinateModeName.NO_OP:
        break;
      default:
        throw new Error('Invalid geoCoordinateStrategy');
    }
  }, [geoCoordinates]);

  const handleAcceptMappedLocation = (location) => {
    _businessLogic.processAcceptanceOfMappedLocation(location);
  };

  const handleRejectMappedLocation = () => {
    _businessLogic.processRejectionOfMappedLocation();
    scroll.to(locationQuestionInputForwardedRef.current);
  };

  const handleInputSubmit = (location) => {
    if (location) {
      _businessLogic.queryGoogleMaps(location)
        .then(
          (googleMapsResult) => {
            actions.showMap(googleMapsResult);
          }, actions.hideMap
        )
        .then(actions.hideModal);
    }
  };

  const handleTextFieldFocus = () => {
    if (!props.isCurrent) {
      actions.setThisQuestionAsTheCurrentQuestion();
    }
  };

  const fnOnInputChangeBuilder = () => {
    return (cityState) => {
      _businessLogic.processUserTypingInTextField(cityState);
    };
  };

  const fnOnClickToEditLocationBuilder = () => {
    return () => {
      _businessLogic.processClickToEditLocation();
    };
  };

  const fnOnSkipBuilder = () => {
    return () => {
      _businessLogic.processSkip();
    };
  };

  const inputProps = {
    handleAcceptMappedLocation,
    handleRejectMappedLocation,
    handleInputSubmit,
    handleTextFieldFocus,
    onCityStateInputChange: fnOnInputChangeBuilder(),
    onClickToEditLocation: fnOnClickToEditLocationBuilder(),
    onSkip: fnOnSkipBuilder(),
    ...props,
  };

  if (viewport === MOBILE) {
    return (
      <MobileLocationInput
        forwardedInputRef={locationQuestionInputForwardedRef}
        {...inputProps}
      />
    );
  } else if (viewport === TABLET || viewport === DESKTOP) {
    return (
      <DesktopLocationInput
        forwardedInputRef={locationQuestionInputForwardedRef}
        {...inputProps}
      />
    );
  }
};
