import * as actionTypes from './actionTypes';

export const locationQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch(action.type) {
      case actionTypes.UPDATE_CITY_STATE:
        return {
          ...q,
          isAnswered: false,
          cityState: action.cityState,
          errorMsg: ''
        };
      case actionTypes.CLEAR_CITY_STATE:
        return {
          ...q,
          cityState: ''
        };
      case actionTypes.SHOW_MAP:
        return ({
          ...q,
          isAnswered: false,
          errorMsg: '',
          googleMapsResult: action.googleMapsResult,
          showMap: true,
          showHomeAddressMsg: false
        });
      case actionTypes.HIDE_MAP:
        return {
          ...q,
          showMap: false
        };
      case actionTypes.SET_ANSWER:
        return ({
          ...q,
          answer: action.answer,
          isAnswered: true,
          showHomeAddressMsg: false
        });
      case actionTypes.UNSET_ANSWER:
        return ({
          ...q,
          answer: '',
          isAnswered: false
        });
      case actionTypes.HAS_PROCESSED_GEO_COORDINATES:
        return {
          ...q,
          hasProcessedGeoCoordinates: true,
        };
      case actionTypes.SHOW_ERROR_MESSAGE:
        return ({
          ...q,
          isAnswered: false,
          errorMsg: action.errorMsg,
          googleMapsResult: null,
          showMap: false
        });
      case actionTypes.SHOW_HOME_ADDRESS_MESSAGE:
        return ({
          ...q,
          showHomeAddressMsg: true,
          showMap: false
        });
      default:
        return q;
    }
  });
};
