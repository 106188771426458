import React, { useState } from 'react';
import { browserHistory, withRouter } from 'react-router';
import _ from 'lodash';
import { api } from './lib/api';
import { Box, Button, Flex, Text } from '@rebass/emotion';
import { Transition } from 'react-transition-group';
import { MEDIUM_GREY, WHITE } from './lib/colors';
import { MONTSERRAT } from './lib/fonts';
import { useMainContentOverflow } from './hooks/useMainContentOverflow';
import {
  Container,
  GridContainer,
  Hero,
  LongTitle,
  Row,
} from './sharedComponents/layoutHelpers';

const defaultStyle = {
  opacity: 0,
  background: 'none',
};

const transitionStyles = {
  entering: {
    opacity: 1,
    background: '#FDFD96',
    transition: `all 1500ms ease-in-out`,
  },
  entered: {
    opacity: 1,
    transition: `background 1500ms ease-in-out`,
    background: 'none',
  },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ErrorView = ({ errorMessage }) => (
  <Container >
    <Hero>
      <Transition in={true} timeout={1000}>
        {(transitionState) => (
          <div>
            <Text
              color={WHITE}
              fontFamily={MONTSERRAT}
              fontSize={[2, 3]}
              fontWeight={300}
              key={1}
              style={{
                ...defaultStyle,
                ...transitionStyles[transitionState],
              }}
            >{errorMessage}</Text>
          </div>
        )}
      </Transition>
      <Box my={3} color={WHITE}>
        Check your network connection and<span> </span>
        <a href='/confirm-unsubscribe' style={{ color: WHITE}}>
          try again.
        </a>
      </Box>
    </Hero>
  </Container>
);

const SubmitButton = (props) => (
  <Button
    bg={props.isActive ? 'rgb(13, 130, 223)' : MEDIUM_GREY}
    borderRadius={4}
    border={'none'}
    fontWeight={700}
    fontFamily={MONTSERRAT}
    onClick={(e) => {
      e.preventDefault();
      props.onClick();
    }}
    style={{ cursor: 'pointer' }}
    width={1}
  >
    Confirm Unsubscribe
  </Button>
);

const ConfirmUnsubscribeWithoutRouter = ({ history, params }) => {
  const [state, setState] = useState({
    errorMessage: '',
    showReloadLink: false,
  });

  useMainContentOverflow();
  const { secure_token } = params;

  const parseResponseJsonPromise = (response, defaultErrorMessage="Something went wrong. Please try again later.") => {
    if (response.json) {
      return response.json()
        .then((data) => Promise.resolve({ response, data }))
        .catch(() => Promise.resolve({ response, data: { error: defaultErrorMessage } }));
    } else {
      return Promise.resolve({ response, data: { error: defaultErrorMessage } });
    }
  }

  const handleSubmit = () => {
    api.confirmUnsubscribe(secure_token)
      .then((response) => parseResponseJsonPromise(response))
      .then(({ response, data }) => {
        if (response.ok) {
          browserHistory.push('/unsubscribe-complete');
          window.history.pushState({ path: '/unsubscribe-complete' });
        } else {
          setState({ ...state, errorMessage: data.error });
        }
        return;
      })
      // In case of network error, show the error message
      .catch((error) => {
        setState({ ...state, errorMessage: error.message, showReloadLink: true });
      });
  }

  if (state.errorMessage) {
    return <ErrorView errorMessage={state.errorMessage} showReloadLink={state.showReloadLink} />
  } else {
    return (
      <Container>
        <Hero>
          <LongTitle>Civiqs Survey Unsubscribe Confirmation</LongTitle>
        </Hero>
        <GridContainer>
          <Box mt={4}>
            <form name='confirm-unsubscribe'>
              <Flex
                alignItems={'flex-start'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Row>
                  <Text
                    color={MEDIUM_GREY}
                    fontFamily={MONTSERRAT}
                    fontSize={[1, 2]}
                    fontWeight={300}
                    mb={14}
                  > Confirm your unsubscribe request by clicking the button below.</Text>
                </Row>
                <Row>
                  <SubmitButton isActive={true} onClick={handleSubmit} />
                </Row>
              </Flex>
            </form>
          </Box>
        </GridContainer>
      </Container>
    )
  }
};

const ConfirmUnsubscribe = withRouter(ConfirmUnsubscribeWithoutRouter);

export { ConfirmUnsubscribe }
