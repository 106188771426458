import isValidZip from 'is-valid-zip';
import * as actionTypes from './actionTypes';
import * as pollActions from '../../poll/actions';
import mixpanel from '../../lib/mixpanel';

export const updateZipcode = (questionId, zipcode) => {
  return {
    id: questionId,
    zipcode: zipcode,
    type: actionTypes.UPDATE_ZIPCODE,
  };
};

export const saveZipcode = (questionId, zipcode) => {
  return {
    id: questionId,
    zipcode: zipcode,
    type: actionTypes.SAVE_ZIPCODE,
  };
};

export const displayError = (questionId) => {
  return {
    id: questionId,
    errorMsg: 'Sorry, this does not seem to be a valid zipcode.',
    type: actionTypes.DISPLAY_ERROR,
  };
};

export const submitZipcode = (questionId, zipcode, questionBody) => {
  return (dispatch) => {
    const valid = isValidZip(zipcode);
    if (valid) {
      dispatch(saveZipcode(questionId, zipcode));
      dispatch(pollActions.setCurrentQuestionAutomatically());
      dispatch(
        pollActions.saveResponse(questionId, questionBody, { answer: zipcode })
      );

      mixpanel.track('provided_zipcode', {
        question: questionBody,
        zipcode: zipcode,
        answer: zipcode,
      });
    } else {
      dispatch(displayError(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};

// Auto submit allows clicks away from the zipcode question to trigger the display of a new isCurrent question
// in the case that there is no response given in the text input or in the case that the isCurrent response is validated and saved
export const autoSubmitZipcode = (questionId, zipcode, questionBody) => {
  return (dispatch) => {
    const valid = isValidZip(zipcode);
    if (valid) {
      dispatch(saveZipcode(questionId, zipcode));
      dispatch(
        pollActions.saveResponse(questionId, questionBody, { answer: zipcode })
      );

      mixpanel.track('provided_zipcode', {
        question: questionBody,
        zipcode: zipcode,
        answer: zipcode,
      });
    } else {
      dispatch(displayError(questionId));
      dispatch(pollActions.setCurrentQuestionById(questionId));
    }
  };
};
