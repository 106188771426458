import * as actionTypes from './actionTypes';
import { MOBILE, TABLET, DESKTOP } from '../lib/constants';
import { mobileMaxWidth, tabletMaxWidth } from '../lib/breakpoints';

export const updateViewportType = (width) => {
  let viewportType = '';

  if (window.screenX != 0 && !('ontouchstart' in window || 'onmsgesturechange' in window)) {
    viewportType = DESKTOP;
  } else if ( width <= mobileMaxWidth ){
    viewportType = MOBILE;
  } else if ( width <= tabletMaxWidth ) {
    viewportType = TABLET;
  } else if( width > tabletMaxWidth ) {
    viewportType = DESKTOP;
  }

  return {
    viewportType: viewportType,
    type: actionTypes.UPDATE_VIEW_PORT_TYPE
  }
}