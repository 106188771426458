import * as actionTypes from './actionTypes';

export const textQuestionStateReducer = (state = [], action) => {
  return state.map((q) => {
    if (q.id !== action.id) return q;
    switch (action.type) {
      case actionTypes.UPDATE_RESPONSE:
        return { ...q, response: action.response, isAnswered: false };
      case actionTypes.SAVE_RESPONSE:
        return { ...q, response: action.response, isAnswered: true };
      default:
        return q;
    }
  });
};
