import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import * as colors from '../lib/colors';
import { Flex } from '../sharedComponents/layoutHelpers';

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(0, 0, 0, 0.3)',
    transform: 'translateZ(0)'
  },
  container: {
    backgroundColor: colors.WHITE,
    zIndex: 10,
    borderRadius: 5,
    overflow: 'hidden',
    maxWidth: '100%',
    marginLeft: 10,
    marginRight: 10
  },
  inner: {
    padding: 15,
    wordWrap: 'break-word'
  },
  closeBtn: {
    width: '100%',
    height: 30,
    backgroundColor: colors.GALLERY_GREY,
    position: 'relative'
  },
  icon: {
    color: colors.MANATEE_GREY,
    fontSize: 20,
    fontWeight: 100,
    position: 'absolute',
    right: 6,
    top: 5
  }
});

const CloseBtn = ({ onClose, show }) => {
  return (show)
    ? <div className={css(styles.closeBtn)} onClick={onClose}>
        <span
          data-icon='c'
          aria-hidden='true'
          className={css(styles.icon)}
          />
      </div>
    :null;
};

const Modal = ({
  children,
  closeable,
  onClose,
  showing,
  viewport
}) => {
  return (showing)
    ? <div
        className={css(styles.overlay)}
        onClick={() => {
          if (closeable) onClose();
        }}
        >
        <Flex
          alignItems='center'
          justifyContent='center'
          >
          <div className={css(styles.container)}>
            <CloseBtn show={closeable} onClose={onClose} />
            <div className={css(styles.inner)}>{children}</div>
          </div>
        </Flex>
      </div>
    :null;
};

Modal.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  viewport: PropTypes.string.isRequired
};

Modal.defaultProps = {
  closeable: true,
  showing: false
};

export { Modal, CloseBtn };