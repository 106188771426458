import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { TISA } from './lib/fonts';
import { Flex } from './sharedComponents/layoutHelpers';

const styles = StyleSheet.create({
  maintenanceMsgContainer: {
    position: 'relative',
    marginTop: '4em',
    fontFamily: TISA
  },
  maintenanceMsg: {
    fontSize: 36,
    textAlign: 'center'
  }
});

export const MaintenancePage = () =>
  <Flex
    flexFlow='column nowrap'
    justifyContent='center'
    alignItems='center'
  >
    <div className={css(styles.maintenanceMsgContainer)}>
      <h2 className={css(styles.maintenanceMsg)}>We're down for maintenance.</h2>
      <h2 className={css(styles.maintenanceMsg)}>Be right back!</h2>
    </div>
  </Flex>;
