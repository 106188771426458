import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { Flex } from './sharedComponents/layoutHelpers';
import { FlexList } from './sharedComponents/layoutHelpers';
import * as colors from './lib/colors';
import { MONTSERRAT } from './lib/fonts';
import { NAV_HEIGHT } from './lib/constants';
import { mobileDeviceIsSmall } from './lib/mobileDeviceIsSmall';
import { Logo } from './Logo';

const styles = StyleSheet.create({
  container: {
    height: 56,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 10,
    fontFamily: MONTSERRAT,
  },
  fixed: {
    height: NAV_HEIGHT,
    position: 'fixed',
    left: 0,
    top: 0,
  },
  menuControl: {
    flex: '1 1 50%',
  },
  menuIcon: {
    color: colors.BLUE,
    fontSize: '1em',
    cursor: 'pointer',
    marginBottom: '-0.20em',
    marginRight: '2em',
  },
  menuIconLabel: {
    color: colors.DARK_BLUE,
    fontSize: '0.6em',
    marginRight: '1em',
    letterSpaceing: '0.05em',
    cursor: 'pointer',
  },
  menuItem: {
    height: '25%',
    width: '100%',
    userSelect: 'none',
  },
  menuItemPressedState: {
    backgroundColor: colors.BLUE,
  },
  menuItemAnchor: {
    display: 'block',
    height: '100%',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  menuItemLabel: {
    paddingTop: 10,
    borderTop: `3px solid ${colors.TUNDORA_GREY}`,
    color: colors.WHITE,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '0.875em',
  },
  menuItemLabelPressedState: {
    borderTop: `3px solid ${colors.WHITE}`,
  },
  menuItemLabelActiveState: {
    borderTop: `3px solid ${colors.BLUE}`,
  },
  menuItemLabelContainer: {
    height: '100%',
  },
});

const NavItemAnchor = ({ path, children, email }) => {
  return path ? (
    <a href={path} target={'_blank'} style={styles.menuItemAnchor.style}>
      {children}
    </a>
  ) : (
    <a href={`mailto:${email}`} style={styles.menuItemAnchor.style}>
      {children}
    </a>
  );
};

NavItemAnchor.propTypes = {
  path: PropTypes.string,
  email: PropTypes.string,
};

class NavItem extends Component {
  constructor(props) {
    super(props);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      pressed: false,
    };
  }

  componentDidMount() {
    window.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }

  handleMouseEnter() {
    this.setState({ pressed: true });
  }

  handleMouseLeave() {
    this.setState({ pressed: false });
  }

  handleTouchStart() {
    this.setState({ pressed: true });
  }

  handleTouchEnd() {
    this.setState({ pressed: false });
  }

  render() {
    const { onClick, path, email, active, label } = this.props;

    return (
      <li
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onClick={onClick}
        className={css(
          styles.menuItem,
          this.state.pressed && styles.menuItemPressedState
        )}
      >
        <NavItemAnchor path={path} email={email}>
          <div className={css(styles.menuItemLabelContainer)}>
            <Flex
              flexFlow={'row nowrap'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <span
                className={css(
                  styles.menuItemLabel,
                  active && styles.menuItemLabelActiveState,
                  this.state.pressed && styles.menuItemLabelPressedState
                )}
              >
                {label}
              </span>
            </Flex>
          </div>
        </NavItemAnchor>
      </li>
    );
  }
}

NavItem.defaultProps = {
  active: false,
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

const MenuControl = ({ onMenuIconClick }) => {
  return (
    <div className={css(styles.menuControl)}>
      <Flex
        justifyContent={'flex-end'}
        alignItems={'center'}
        onClick={onMenuIconClick}
      >
        <span className={css(styles.menuIconLabel)}>MENU</span>
        <span
          data-icon={'a'}
          aria-hidden="true"
          className={css(styles.menuIcon)}
        />
      </Flex>
    </div>
  );
};

const Menu = ({ onNavItemClick, showing }) => {
  const computedStyles = (() => {
    return StyleSheet.create({
      menu: {
        backgroundColor: colors.TUNDORA_GREY,
        position: 'absolute',
        width: '100%',
        top: '100%',
        left: 0,
        zIndex: 100,
        height: window.innerHeight - NAV_HEIGHT,
        display: showing ? 'block' : 'none',
      },
    });
  })();

  return (
    <div className={css(computedStyles.menu)}>
      <FlexList flexFlow={'column nowrap'} alignItems={'flex-start'}>
        <NavItem
          path={'/join-in'}
          label="ABOUT THIS SURVEY"
          onClick={onNavItemClick}
        />
        <NavItem
          path={'/survey-terms-of-service'}
          label="TERMS OF USE"
          onClick={onNavItemClick}
        />
        <NavItem
          path={'/privacy'}
          label="PRIVACY POLICY"
          onClick={onNavItemClick}
        />
        <NavItem
          path="/what-we-offer#contact"
          label="CONTACT US"
          onClick={onNavItemClick}
        />
      </FlexList>
    </div>
  );
};

export class DropdownNav extends Component {
  constructor(props) {
    super(props);

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleNavItemClick = this.handleNavItemClick.bind(this);

    this.state = {
      showing: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.showing !== this.state.showing;
  }

  handleMenuClick() {
    this.setState({ showing: !this.state.showing });
  }

  handleResize() {
    this.setState({ showing: false });
  }

  handleNavItemClick(e) {
    this.setState({ showing: false });
  }

  render() {
    return (
      <div
        className={css(
          styles.container,
          !mobileDeviceIsSmall() && styles.fixed
        )}
      >
        <Flex
          flexFlow={'row nowrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Logo />
          <MenuControl onMenuIconClick={this.handleMenuClick} />
          <Menu
            showing={this.state.showing}
            onNavItemClick={this.handleNavItemClick}
          />
        </Flex>
      </div>
    );
  }
}
