let watchID = null;

export const tryWatchingPosition = ({ onWait, onSuccess, onFailure, onlyIfGranted }) => {
  if (watchID !== null) return; // we've already started the process

  if ('geolocation' in navigator && 'permissions' in navigator) {
    navigator.permissions.query({ name:'geolocation' })
    .then(({ state: permissionStatus }) => {
      if (onlyIfGranted && permissionStatus !== 'granted') return;
      if (permissionStatus === 'denied') return;

      if (onWait) onWait();
      watchPosition(onSuccess, onFailure);
    });
  } else if ('geolocation' in navigator) { // for Webkit etc.
    watchPosition(onSuccess, onFailure);
  }
};

const watchPosition = (onSuccess, onFailure) => {
  watchID = navigator.geolocation.watchPosition(
    (pos) => {
      if (onSuccess) onSuccess(pos);
    },
    (err) => {
      if (onFailure) onFailure();
    },
    {
      enableHighAccuracy: true,
      timeout: 10000, // 5 second time limt on query
      maximumAge: 86400000 // break cache after a day
    }
  );
}

export const stopWatchingPosition = () => {
  navigator.geolocation.clearWatch(watchID);
}