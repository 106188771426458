import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  DESKTOP,
  EMAIL_ENVELOPE,
  MOBILE,
  TABLET,
} from '../../lib/constants';
import * as modalActions from '../../modal/actions';
import { ViewportContext } from '../../viewportContext';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { QuestionInputWrapper } from '../sharedComponents/QuestionInputWrapper';
import { Suggestion } from './Suggestion';
import { useActions } from './actions';
import { businessLogic } from './businessLogic';
import { MobileEmailTextInput } from './MobileEmailTextInput';
import { DesktopEmailTextInput } from './DesktopEmailTextInput';

export const EmailQuestionInput = (props) => {
  const {
    isAnswered,
    isCurrent,
    email,
    errorMsg,
    responseIsOptional,
    suggestedEmail,
  } = props;

  const dispatch = useDispatch();
  const actions = useActions(dispatch, props);
  const _businessLogic = businessLogic(actions, props);
  const viewport = useContext(ViewportContext);

  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  const fnOnSubmitBuilder = () => {
    return () => {
      _businessLogic.decideEmailProcess();
    };
  };

  const fnOnSkipBuilder = () => {
    return () => {
      _businessLogic.processQuestionSkip();
    };
  };

  const fnOnFocusBuilder = () => {
    return () => {
      if (!props.isCurrent) {
        actions.handleFocus();
        // actions.updateListOfSkippedQuestions();
      }
    };
  };

  const onSuggestionAcceptance = () => {
    _businessLogic.processEmailSuggestionAccept();
  };

  const onSuggestionReject = () => {
    _businessLogic.processEmailSuggestionReject();
  };

  let renderSuggestion;

  if (viewport === MOBILE) {
    renderSuggestion = () => null;

    useEffect(() => {
      if (suggestedEmail) {
        dispatch(
          modalActions.showModal(
            <Suggestion
              currentEmail={email}
              onAccept={onSuggestionAcceptance}
              onReject={onSuggestionReject}
              suggestedEmail={suggestedEmail}
            />
          )
        );
      } else {
        dispatch(modalActions.hideModal());
      }
    }, [suggestedEmail]);
  } else if (viewport === DESKTOP || viewport === TABLET) {
    renderSuggestion = () => {
      return (
        <Suggestion
          currentEmail={email}
          onAccept={onSuggestionAcceptance}
          onReject={onSuggestionReject}
          suggestedEmail={suggestedEmail}
        />
      );
    };
  }

  const textInputProps = {
    errorMsg: errorMsg,
    iconCode: EMAIL_ENVELOPE,
    isAnswered: isAnswered,
    onInputChange: actions.handleInputChange,
    onFocus: fnOnFocusBuilder(actions, props),
    onSkip: fnOnSkipBuilder(actions, props),
    onSubmit: fnOnSubmitBuilder(actions, props),
    renderSuggestion: renderSuggestion,
    response: email,
    responseIsOptional: responseIsOptional,
    screenReaderLabel: 'email address',
    showCcpaDisclosure: true,
    type: 'email',
    visible: isCurrent,
  };

  return (
    <QuestionInputWrapper answer={email} isCurrent={isCurrent}>
      {viewport === MOBILE ? (
        <MobileEmailTextInput {...textInputProps} />
      ) : (
        <DesktopEmailTextInput {...textInputProps} />
      )}
    </QuestionInputWrapper>
  );
};

EmailQuestionInput.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  responseIsOptional: PropTypes.bool.isRequired,
};

EmailQuestionInput.defaultProps = {
  email: '',
  responseIsOptional: false,
};
