import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateSkippedQuestionList } from '../../poll/useUpdateSkippedQuestionList';
import { QuestionInputWrapper } from '../sharedComponents/QuestionInputWrapper';
import { TextInput } from '../sharedComponents/TextInput';

const ZipcodeQuestionInput = (props) => {
  const {
    isAnswered,
    errorMsg,
    isCurrent,
    onInputChange,
    onTextFieldFocus,
    onSubmit,
    viewport,
    zipcode
  } = props;

  const dispatch = useDispatch();
  useUpdateSkippedQuestionList(dispatch, props.id, props.isCurrent);

  return (
    <QuestionInputWrapper
      answer={zipcode}
      isCurrent={isCurrent}
      viewport={viewport}
      >
      <TextInput
        isActive={isCurrent && zipcode.length > 0}
        isAnswered={isAnswered}
        errorMsg={errorMsg}
        label={'zipcode'}
        onFocus={onTextFieldFocus}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        response={zipcode}
        screenReaderLabel={'zipcode'}
        viewport={viewport}
        visible={isCurrent}
        />
    </QuestionInputWrapper>
  );
};

ZipcodeQuestionInput.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  zipcode: PropTypes.string.isRequired
};

ZipcodeQuestionInput.defaultProps = {
  zipcode: ''
};

export { ZipcodeQuestionInput };