import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { parseAllowedHtml } from '../../lib/parseAllowedHtml';
import * as fonts from '../../lib/fonts';
import * as colors from '../../lib/colors';

const styles = StyleSheet.create({
  optionBody: {
    fontFamily: fonts.TISA,
    color: colors.DARK_BLUE,
    cursor: 'pointer',
  },
  greyedOut: {
    color: colors.LIGHT_GREY,
  },
});

const OptionBody = ({ isGreyedOut, text }) => {
  return (
    <span className={css(styles.optionBody, isGreyedOut && styles.greyedOut)}>
      {parseAllowedHtml(text)}
    </span>
  );
};

OptionBody.propTypes = {
  className: PropTypes.string,
  isGreyedOut: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

OptionBody.defaultProps = {
  isGreyedOut: false,
};

export { OptionBody };
